<template>
	<div>
		<Template>
			<section class="row m-0 px-4 py-3 g-4">
				<!-- Modal -->
				<div
					class="modal fade"
					id="setNameModal"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="setNameModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content rounded-0">
							<div class="modal-body bg-white-">
								<h6 class="text-center text-red-">
									{{ $t('setting.update_your_name') }}
								</h6>
								<hr />
								<form @submit.prevent="setName()">
									<div>
										<InputFC
											:error="errors.lastname_"
											:placeholder="$t('global.lastname')"
											:type="'text'"
											v-model="lastname_"
										/>
									</div>
									<div class="mt-3">
										<InputFC
											:error="errors.firstname_"
											:placeholder="$t('global.firstname')"
											:type="'text'"
											v-model="firstname_"
										/>
									</div>
									<div class="mt-3">
										<InputFC
											:error="errors.gender_"
											:options="genders"
											:type="'select'"
											v-model="gender_"
											:placeholder="'Sexe'"
										/>
									</div>
									<div class="mt-3">
										<InputFC
											:error="errors.birthDate_"
											:placeholder="$t('global.birthDate')"
											:type="'date'"
											v-model="birthDate_"
										/>
									</div>
									<div class="mt-3">
										<InputFC
											:error="errors.birthPlace_"
											:placeholder="$t('global.birthPlace')"
											:type="'text'"
											v-model="birthPlace_"
										/>
									</div>
									<div class="row mt-3">
										<div class="col text-center">
											<button
												type="button"
												class="border bg-light px-3 text-5 rounded py-1"
												data-mdb-dismiss="modal"
											>
												{{ $t('global.close') }}
											</button>
										</div>
										<div class="col text-center">
											<button class="button py-1">
												{{ $t('global.save') }}
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>

				<!-- Edit Email Modal -->
				<div
					class="modal fade"
					id="updateEmailModal"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="updateEmailModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content rounded-0">
							<div class="modal-body bg-white-">
								<h6 class="text-center text-red-">
									{{ $t('global.update_your_email') }}
								</h6>
								<hr />
								<form
									v-if="updateEmailStep === 1"
									@submit.prevent="requestEmailUpdate()"
								>
									<div>
										<InputFC
											:error="errors.email_"
											:placeholder="$t('global.new_email')"
											:type="'email'"
											v-model="email_"
										/>
									</div>
									<div class="row mt-3">
										<div class="col text-center">
											<button
												type="button"
												class="border bg-light px-3 text-5 rounded py-1"
												data-mdb-dismiss="modal"
											>
												{{ $t('global.close') }}
											</button>
										</div>
										<div class="col text-center">
											<button class="button py-1">
												{{ $t('global.send') }}
											</button>
										</div>
									</div>
								</form>
								<form
									v-if="updateEmailStep === 2"
									@submit.prevent="updateEmail()"
								>
									<div>
										<InputFC
											:error="errors.code_"
											:placeholder="$t('setting.verification_code')"
											:type="'number'"
											v-model="code_"
										/>
									</div>
									<div class="row mt-3">
										<div class="col text-center">
											<button
												type="button"
												class="border bg-light px-3 text-5 rounded py-1"
												data-mdb-dismiss="modal"
											>
												{{ $t('global.close') }}
											</button>
										</div>
										<div class="col text-center">
											<button class="button py-1" data-mdb-dismiss="modal">
												{{ $t('setting.verify') }}
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>

				<div class="col-xl-9">
					<div class="row px-4 p-3 rounded shadow-sm border bg-white-">
						<div class="col-3 vstack">
							<input
								type="file"
								class="d-none"
								accept=".png, .jpg, jpeg"
								@change="onFileChange"
								id="photo"
								ref="photo"
								name="photo"
							/>
							<img
								role="button"
								:src="photo"
								@click="$filters.viewer([photo], this)"
								class="setting_user_image bg-white- border"
								alt=""
							/>
							<i
								class="uil uil-camera text-1 text-primary-"
								@click="$refs.photo.click()"
								type="button"
								style="margin-top: -40px; margin-left: 92px"
							></i>
						</div>
						<div class="col-9">
							<div class="hstack pb-2 m-0 border-bottom">
								<h4
									class="text-black- pt-1"
									v-if="lastname != '' && firstname != ''"
								>
									{{ lastname + ' ' + firstname }}
								</h4>
								<h4 class="text-black- pt-1" v-else>
									{{ $t('setting.user_society') }}
								</h4>
								<button
									class="button text-6 p-1 ms-auto"
									data-mdb-toggle="modal"
									data-mdb-target="#setNameModal"
									@click="getName()"
								>
									<i class="uil uil-pen me-2 text-white"></i>
									{{ $t('setting.update_your_infos') }}
								</button>
							</div>
							<div class="hstack text-5 mt-2">
								<span class="fs-6">IFU</span>
								<span class="ms-2 fs-6 text-black-">: {{ company.ifu }}</span>
							</div>
							<div class="hstack text-5 mt-2">
								<span class="fs-6">RCCM</span>
								<span class="ms-2 fs-6 text-black-">: {{ company.rccm }}</span>
							</div>
						</div>
					</div>

					<form
						@submit.prevent="updateGeneral"
						v-show="step === 1"
						class="row px-4 p-3 rounded shadow-sm border bg-white- mt-2"
					>
						<div class="text-end">
							<span
								v-if="store.user.isMainAdmin"
								type="button"
								class="button text-6 p-1"
								:class="step_edit ? 'bg-red-' : ''"
								@click="step_edit = !step_edit"
								><i class="uil uil-pen me-2 text-white"></i>
								{{
									!step_edit
										? $t('global.edit_mode')
										: $t('global.leave_edit_mode')
								}}
							</span>
							<span v-else class="p-1"></span>
						</div>
						<div v-if="step_edit">
							<div
								class="text-4 text-primary- mb-3"
								v-show="message_general != ''"
							>
								{{ message_general }}
							</div>
							<div v-if="store.user.isMainAdmin">
								<InputFC
									:error="errors.companyName"
									:placeholder="$t('setting.company_name')"
									v-model="companyName"
								/>
							</div>
							<div class="mt-3" v-if="store.user.isMainAdmin">
								<InputFC
									:error="errors.juridicStatus"
									:placeholder="$t('setting.legal_status')"
									:options="juridicStatusList"
									:type="'select'"
									v-model="juridicStatus"
								/>
							</div>
							<div class="mt-3">
								<InputFC
									:error="errors.phoneNumber"
									:placeholder="$t('setting.phone')"
									:type="'tel'"
									v-model="phoneNumber"
								/>
							</div>
							<div class="mt-3">
								<InputFC
									:error="errors.mobile"
									:placeholder="'Mobile'"
									:type="'tel'"
									v-model="mobile"
								/>
							</div>
							<!-- <div class="mt-3">
                                <InputFC :error="errors.emailAddress" :placeholder="'Email'" :type="'email'" v-model="emailAddress" />
                            </div> -->
							<div class="mt-3">
								<InputFC
									:error="errors.centerOfInterest"
									:placeholder="$t('setting.center_of_interest')"
									:type="'text'"
									v-model="centerOfInterest"
								/>
							</div>
							<div class="mt-3">
								<InputFC
									:error="errors.mainActivity"
									:placeholder="$t('setting.main_activity')"
									:type="'text'"
									v-model="mainActivity"
								/>
							</div>
							<div class="mt-3">
								<InputFC
									:error="errors.secondaryActivity"
									:placeholder="$t('setting.secondary_activity')"
									:type="'text'"
									v-model="secondaryActivity"
								/>
							</div>
							<div class="mt-3">
								<InputFC
									:error="errors.address"
									:placeholder="$t('setting.address')"
									:type="'text'"
									v-model="address"
								/>
							</div>
							<div class="mt-3">
								<InputFC
									:error="errors.reference"
									:disabled="true"
									:placeholder="$t('setting.account_id')"
									:type="'number'"
									v-model="reference"
								/>
							</div>

							<div class="row">
								<div class="col-3 mt-4" v-if="isMainAdmin">
									<SpinnerMe :elementId="'spinner1'" :className="'mt-1'" />
									<button class="button py-1" id="submit1">
										<i class="uil uil-save text-white me-3"></i
										>{{ $t('global.save') }}
									</button>
								</div>
								<div class="col-4 mt-4">
									<button
										class="button bg-success py-1"
										data-mdb-toggle="modal"
										data-mdb-target="#updateEmailModal"
										@click.prevent=""
									>
										<i class="uil uil-pen text-white me-3"></i
										>{{ $t('global.update_your_email') }}
									</button>
								</div>
							</div>
						</div>
						<div v-else>
							<TitleContent
								:title="$t('setting.company_name')"
								:content="companyName"
							/>
							<TitleContent
								class="mt-3"
								:title="$t('setting.legal_status')"
								:content="juridicStatus"
								:options="juridicStatusList"
							/>
							<TitleContent
								class="mt-3"
								:title="$t('setting.phone')"
								:content="phoneNumber"
							/>
							<TitleContent class="mt-3" :title="'Mobile'" :content="mobile" />
							<TitleContent
								class="mt-3"
								:title="'Email'"
								:content="emailAddress"
							/>
							<TitleContent
								class="mt-3"
								:title="$t('setting.center_of_interest')"
								:content="centerOfInterest"
							/>
							<TitleContent
								class="mt-3"
								:title="$t('setting.main_activity')"
								:content="mainActivity"
							/>
							<TitleContent
								class="mt-3"
								:title="$t('setting.secondary_activity')"
								:content="secondaryActivity"
							/>
							<TitleContent
								class="mt-3"
								:title="$t('setting.address')"
								:content="address"
							/>
							<TitleContent
								class="mt-3"
								:title="$t('setting.your_gender')"
								:content="
									this.store.user.gender === 'F'
										? $t('global.feminine')
										: $t('global.male')
								"
							/>
							<TitleContent
								class="mt-3"
								:title="$t('setting.birthday')"
								:content="
									$filters.convertDate(this.store.user.birthDate, false)
								"
							/>
							<TitleContent
								class="mt-3"
								:title="$t('setting.birth_place')"
								:content="this.store.user.birthPlace"
							/>
							<TitleContent
								class="mt-3"
								:title="$t('setting.account_id')"
								:content="reference"
							/>
							<dl>
								<dt>{{ $t('setting.logo') }}</dt>
								<div class="mt-2 vstack">
									<input
										type="file"
										class="d-none"
										accept=".png, .jpg, jpeg"
										@change="onLogoChange"
										id="logo"
										ref="logo"
										name="logo"
									/>
									<img
										role="button"
										:src="logo"
										@click="$filters.viewer([logo], this)"
										class="setting_user_image bg-white- border"
										style="height: 100px; max-width: 100px"
										alt=""
									/>
									<i
										class="uil uil-camera text-1 text-primary-"
										v-if="isMainAdmin"
										@click="$refs.logo.click()"
										type="button"
										style="margin-top: -45px; margin-left: 87px"
									></i>
								</div>
							</dl>
						</div>
					</form>

					<div
						v-show="step === 2"
						class="row px-4 p-3 rounded shadow-sm mt-4 border bg-white-"
					>
						<div class="border rounded mt-3 p-3">
							<div
								class="text-4 text-primary- mb-3"
								v-show="message_2fauth != ''"
							>
								{{ message_2fauth }}
							</div>
							<div>
								<label for="" class="mb-1">{{
									$t('setting.double_factor')
								}}</label>
								<br />
								<SpinnerMe :elementId="'spinner2'" />
								<button
									@click="toggle_2fauth"
									:class="wants2FAuth ? 'button-default bg-red-' : 'button'"
									class="py-1"
									id="submit2"
								>
									{{
										wants2FAuth ? $t('global.desactive') : $t('global.active')
									}}
								</button>
							</div>
						</div>

						<form
							@submit.prevent="updatePassword"
							class="border rounded mt-3 p-3"
						>
							<div
								class="text-4 text-primary- mb-3"
								v-show="message_password != ''"
							>
								{{ message_password }}
							</div>
							<div
								class="text-4 text-red- mb-3"
								v-show="errors.message_password != ''"
							>
								{{ errors.message_password }}
							</div>
							<div>
								<InputFC
									:error="errors.oldPassword"
									:type="'password'"
									:placeholder="$t('setting.password_current')"
									v-model="oldPassword"
								/>
							</div>
							<div class="mt-3">
								<InputFC
									:error="errors.newPassword"
									:type="'password'"
									:placeholder="$t('setting.new_password')"
									v-model="newPassword"
								/>
							</div>
							<div class="mt-3">
								<InputFC
									:error="errors.confirmPassword"
									:type="'password'"
									:placeholder="$t('setting.confirm_password')"
									v-model="confirmPassword"
								/>
							</div>
							<div class="mt-3">
								<SpinnerMe :elementId="'spinner3'" />
								<button class="button py-1" id="submit3">
									{{ $t('global.save') }}
								</button>
							</div>
						</form>

						<div class="border d-none rounded mt-3 p-3">
							<div>
								<label for="" class="mb-1">{{
									$t('setting.desactivate_account_until')
								}}</label>
								<input type="date" class="form-control" />
								<button
									class="bg-red- text-white- px-3 rounded py-1 text-5 border-0 set-opacity mt-3"
								>
									{{ $t('setting.desactivate') }}
								</button>
							</div>
						</div>

						<div class="border rounded mt-3 p-3" v-if="isMainAdmin">
							<div>
								<label for="" class="mb-1">{{
									$t('setting.delete_account_permanently')
								}}</label>
								<br />
								<button
									class="bg-red- text-white px-3 rounded py-1 text-5 border-0 set-opacity"
									@click="deleteAccountRequest"
								>
									{{ $t('setting.delete_account') }}
								</button>
							</div>
						</div>
					</div>

					<div
						v-show="step === 3"
						class="row px-4 p-3 rounded shadow-sm mt-4 border bg-white-"
					>
						<form
							v-if="store.user.isMainAdmin"
							@submit.prevent="updateDocument('ifu')"
							class="border rounded mt-3 p-3"
						>
							<div>
								<label for="" class="mb-1">{{ $t('setting.ifu') }}</label>

								<InputFC :error="errors.ifu" :type="'number'" v-model="ifu" />
							</div>
							<div class="mt-3">
								<label for="ifuFile"
									>{{ $t('setting.download_piece') }}
									<a
										role="button"
										@click.prevent="downloadFile(ifuFile?.url, `IFU-${ifu}`)"
										target="_blank"
										v-if="ifuFile != null"
										><small class="text-alt-"
											>(<i class="uil uil-download-alt text-alt-"></i>
											{{ $t('setting.download_current') }})</small
										></a
									></label
								>
								<input
									type="file"
									id="ifuFile"
									accept=".pdf"
									class="form-control"
								/>
								<span class="error" v-text="errors.ifuFile"></span>
							</div>
							<div class="mt-3" v-if="isMainAdmin">
								<SpinnerMe :elementId="'spinnerIfu'" />
								<button class="button py-1" id="submitIfu">
									{{ $t('global.save') }}
								</button>
							</div>
						</form>

						<form
							v-if="store.user.isMainAdmin"
							@submit.prevent="updateDocument('rccm')"
							class="border rounded mt-3 p-3"
						>
							<div>
								<label for="" class="mb-1">RCCM</label>

								<InputFC :error="errors.rccm" :type="'text'" v-model="rccm" />
							</div>
							<div class="mt-3">
								<label for="rccmFile"
									>{{ $t('setting.download_piece') }}
									<a
										role="button"
										@click.prevent="
											downloadFile(rccmFile?.url, `RCCM-${rccmFile}`)
										"
										target="_blank"
										v-if="rccmFile != null"
										><small class="text-alt-"
											>(<i class="uil uil-download-alt text-alt-"></i>
											{{ $t('setting.download_current') }})</small
										></a
									></label
								>
								<input
									type="file"
									id="rccmFile"
									accept=".pdf"
									class="form-control"
								/>
								<span class="error" v-text="errors.rccmFile"></span>
							</div>
							<div class="mt-3" v-if="isMainAdmin">
								<SpinnerMe :elementId="'spinnerRccm'" />
								<button class="button py-1" id="submitRccm">
									{{ $t('global.save') }}
								</button>
							</div>
						</form>

						<form
							v-if="store.user.isMainAdmin"
							@submit.prevent="updateDocument('card')"
							class="border rounded mt-3 p-3"
						>
							<div>
								<label for="" class="mb-1">{{
									$t('setting.identity_type')
								}}</label>

								<InputFC
									:error="errors.identityType"
									:options="cards"
									:type="'select'"
									v-model="identityType"
								/>
							</div>
							<div class="mt-3">
								<label for="" class="mb-1">{{ $t('setting.identity') }}</label>
								<InputFC
									:error="errors.identity"
									:type="'number'"
									v-model="identity"
								/>
							</div>
							<div class="mt-3">
								<label for="identityFile"
									>{{ $t('setting.download_piece') }}
									<a
										role="button"
										@click.prevent="
											downloadFile(
												managerIdCardFile?.url,
												`ID-${managerIdCardFile}`
											)
										"
										target="_blank"
										v-if="managerIdCardFile != null"
										><small class="text-alt-"
											>(<i class="uil uil-download-alt text-alt-"></i>
											{{ $t('setting.download_current') }})</small
										></a
									></label
								>
								<input
									type="file"
									id="identityFile"
									accept=".pdf"
									class="form-control"
								/>
								<span class="error" v-text="errors.identityFile"></span>
							</div>
							<div class="mt-3" v-if="isMainAdmin">
								<SpinnerMe :elementId="'spinnerCard'" />
								<button class="button py-1" id="submitCard">
									{{ $t('global.save') }}
								</button>
							</div>
						</form>

						<p
							v-if="!store.user.isMainAdmin"
							class="mt-4 mb-0 text-center text-red- text-5"
						>
							{{ $t('setting.only_admin') }}
						</p>

						<p class="text-8 mt-2 text-red-">{{ errors.docError }}</p>
					</div>
				</div>

				<div class="col-xl-3">
					<div class="rounded bg-second- text-white text-4 text-center p-1">
						{{ $t('global.advice') }}
					</div>
					<div
						class="mt-2 bg-light-hight- rounded text-6 text-center p-2"
						v-html="$t('global.advice_action')"
					></div>

					<div
						type="button"
						@click="step = 1"
						:class="step === 1 ? 'bg-white-' : 'bg-light-hight-'"
						class="text-uppercase shadow-2 mt-3 text-black- rounded py-5 text-center"
					>
						{{ $t('global.general') }}
					</div>
					<div
						type="button"
						@click="step = 3"
						:class="step === 3 ? 'bg-white-' : 'bg-light-hight-'"
						class="text-uppercase shadow-2 mt-3 text-black- rounded py-5 text-center"
					>
						{{ $t('global.document') }}s
					</div>
					<div
						type="button"
						@click="step = 2"
						:class="step === 2 ? 'bg-white-' : 'bg-light-hight-'"
						class="border text-uppercase shadow-2 mt-3 text-black- rounded py-5 text-center"
					>
						{{ $t('global.security') }}
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>

<style>
.setting_user_image {
	height: 120px;
	width: 120px !important;
	object-fit: cover;
	border-radius: 50% !important;
}
</style>

<script>
import Template from '@/components/Template.vue';
import Helper from '@/helpers/helper';
import InputFC from '@/components/InputFC.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
import TitleContent from '@/components/TitleContent.vue';

import { useUserStore } from '@/store/user';
import { da } from 'date-fns/locale';

export default {
	setup() {
		const store = useUserStore();
		return { store };
	},
	name: 'SettingPage',
	components: { Template, InputFC, SpinnerMe, TitleContent },
	data() {
		return {
			step: 1,
			step_edit: 0,
			errors: [],
			admin: {},
			company: {},
			lastname: '',
			firstname: '',
			lastname_: '',
			firstname_: '',
			gender_: '',
			birthDate_: '',
			birthPlace_: '',
			juridicStatus: '',
			mobile: '',
			phoneNumber: '',
			emailAddress: '',
			centerOfInterest: '',
			mainActivity: '',
			secondaryActivity: '',
			address: '',
			reference: '',
			message_general: '',
			message_2fauth: '',
			message_password: '',
			newPassword: '',
			confirmPassword: '',
			oldPassword: '',
			identity: '',
			identityType: '',
			isMainAdmin: false,
			user: this.store.user,
			photo: {},
			logo: {},
			cards: [
				{
					id: 'CNI',
					name: 'CNI',
				},
				{
					id: 'Passport',
					name: 'Passport',
				},
				{
					id: 'CIP',
					name: 'CIP',
				},
			],
			genders: Helper.genders,
			ifu: '',
			ifuFile: '',
			rccm: '',
			rccmFile: '',
			managerIdCard: {},
			managerIdCardFile: '',
			ok: true,
			wants2FAuth: false,
			juridicStatusList: [],
			email_: '',
			code_: '',
			updateEmailStep: 1,
			consent: false,
		};
	},
	methods: {
		downloadFile(l, n) {
			Helper.downloadFile(l, n, this);
		},
		onFileChange(e) {
			let photo = e.target.files[0];
			//this.url = URL.createObjectURL(this.photo.url);

			if (photo != null) {
				this.$isLoading(true);
				this.ok = true;
				let registerFormData = new FormData();
				registerFormData.append('photo', photo);

				fetch(
					Helper.route('admin/profile/update-photo'),
					Helper.requestOptions('PUT', registerFormData)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						if (response.status != 200) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						this.$isLoading(false);

						console.log(data);

						if (data.problems) {
							Helper.notification('error', data.message);
						} else {
							if (data.status) {
								this.photo = data.data.url;
								this.store.user.photo.url = this.photo.url;
								Helper.notification('success', data.message);
							}
						}
					})
					.catch((error) => {
						console.log(error);
						this.$isLoading(false);
					});
			}
		},
		onLogoChange(e) {
			let logo = e.target.files[0];
			//this.url = URL.createObjectURL(this.photo.url);

			if (logo != null) {
				this.$isLoading(true);
				this.ok = true;
				let registerFormData = new FormData();
				registerFormData.append('logo', logo);

				fetch(
					Helper.route('admin/profile/update-logo'),
					Helper.requestOptions('PUT', registerFormData)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						if (response.status != 200) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						this.$isLoading(false);

						console.log(data);

						if (data.problems) {
							Helper.notification('error', data.message);
						} else {
							if (data.status) {
								this.logo = data.data.url;
								this.store.user.company.logo.url = this.logo;
								Helper.notification('success', data.message);
							}
						}
					})
					.catch((error) => {
						console.log(error);
						this.$isLoading(false);
					});
			}
		},
		updateGeneral() {
			if (this.beforeUpdateGeneral()) {
				this.ok = true;
				Helper.loading('submit1', 'spinner1', true);
				let registerFormData = new FormData();
				registerFormData.append('companyName', this.companyName);
				registerFormData.append('juridicStatus', this.juridicStatus);
				registerFormData.append('phoneNumber', this.phoneNumber);
				registerFormData.append('emailAddress', this.emailAddress);
				registerFormData.append('mobile', this.mobile);
				registerFormData.append('centerOfInterest', this.centerOfInterest);
				registerFormData.append('mainActivity', this.mainActivity);
				registerFormData.append('secondaryActivity', this.secondaryActivity);
				registerFormData.append('address', this.address);
				registerFormData.append('reference', this.reference);

				fetch(
					Helper.route('admin/profile/update-general'),
					Helper.requestOptions('PUT', registerFormData)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						if (response.status != 200) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						Helper.loading('submit1', 'spinner1', false);

						console.log(data)

						if (data.problems) {
							this.errors.companyName = Helper.getError(
								data.problems,
								'companyName'
							);
							this.errors.ifu = Helper.getError(
								data.problems,
								'ifu'
							);
							this.errors.rccm = Helper.getError(
								data.problems,
								'rccm'
							);
							this.errors.identity = Helper.getError(
								data.problems,
								'identity'
							);
							this.errors.emailAddress = Helper.getError(
								data.problems,
								'emailAddress'
							);
							this.errors.phoneNumber = Helper.getError(
								data.problems,
								'phoneNumber'
							);
							this.errors.reference = Helper.getError(
								data.problems,
								'reference'
							);
							this.errors.juridicStatus = Helper.getError(
								data.problems,
								'juridicStatus'
							);
						} else {
							if (data.status) {
								this.message_general = data.message;
								Helper.notification('success', data.message);
							}
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit1', 'spinner1', false);
					});
			}
		},
		getName() {
			this.lastname_ = this.lastname;
			this.firstname_ = this.firstname;
			this.gender_ = this.store.user.gender;
			this.birthDate_ = Helper.convertDateToForm(this.store.user.birthDate);
			this.birthPlace_ = this.store.user.birthPlace;
		},
		setName() {
			this.errors = [];

			if (this.lastname_ != '' && this.firstname_ != '') {
				this.$isLoading(true);
				this.ok = true;
				let registerFormData = new FormData();
				registerFormData.append('lastname', this.lastname_);
				registerFormData.append('firstname', this.firstname_);
				registerFormData.append('gender', this.gender_);
				registerFormData.append('birthDate', this.birthDate_);
				registerFormData.append('birthPlace', this.birthPlace_);

				fetch(
					Helper.route('admin/profile/update-info'),
					Helper.requestOptions('PUT', registerFormData)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						if (response.status != 200) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						this.$isLoading(false);

						if (data.problems) {
							this.errors.lastname = Helper.getError(data.problems, 'lastname');
							this.errors.firstname = Helper.getError(
								data.problems,
								'firstname'
							);
						} else {
							if (data.status) {
								this.lastname = this.lastname_;
								this.firstname = this.firstname_;
								this.store.user.firstname = this.firstname_;
								this.store.user.lastname = this.lastname_;
								this.store.user.gender = this.gender_;
								this.store.user.birthDate = this.birthDate_;
								this.store.user.birthPlace = this.birthPlace_;

								this.getData();

								Helper.notification('success', data.message);
							}
						}
					})
					.catch((error) => {
						console.log(error);
						this.$isLoading(false);
					});
			} else {
				Helper.notification('error', 'Le nom et le prénom sont obligatoires.');
			}
		},
		requestEmailUpdate() {
			this.errors = [];

			if (this.email_ != '') {
				this.$isLoading(true);
				this.ok = true;

				let registerFormData = new FormData();
				registerFormData.append('newEmailAddress', this.email_);

				fetch(
					Helper.route('admin/profile/request-update-email'),
					Helper.requestOptions('POST', registerFormData)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						if (response.status != 200) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						this.$isLoading(false);

						console.log(data);

						if (data.problems) {
							this.errors.email_ = Helper.getError(
								data.problems,
								'emailAddress'
							);
						} else {
							if (data.status) {
								this.updateEmailStep = 2;
								Helper.notification('success', data.message);
							}
						}
					})
					.catch((error) => {
						console.log(error);
						this.$isLoading(false);
					});
			} else {
				this.errors.email_ = 'Ce champs est requis';
			}
		},
		updateEmail() {
			this.errors = [];

			if (this.code_ != '') {
				this.$isLoading(true);
				this.ok = true;

				let registerFormData = new FormData();
				registerFormData.append('code', this.code_);

				fetch(
					Helper.route('admin/profile/update-email'),
					Helper.requestOptions('PUT', registerFormData)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						if (response.status != 200) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						this.$isLoading(false);

						if (data.problems) {
							this.errors.code_ = Helper.getError(data.problems, 'message');

							Helper.notification('error', data.message);
						} else {
							if (data.status) {
								this.updateEmailStep = 1;
								this.store.user.emailAddress = this.email_;
								Helper.notification('success', data.message);
							}
						}
					})
					.catch((error) => {
						console.log(error);
						this.$isLoading(false);
					});
			}
		},
		toggle_2fauth() {
			Helper.loading('submit2', 'spinner2', true);
			let registerFormData = new FormData();

			fetch(
				Helper.route('admin/profile/toggle-2fauth'),
				Helper.requestOptions('PUT', registerFormData)
			)
				.then((response) => {
					if (response.status === 401) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					Helper.loading('submit2', 'spinner2', false);

					if (data.status) {
						this.wants2FAuth = !this.wants2FAuth;
						this.message_2fauth = data.message;
					}
				})
				.catch((error) => {
					console.log(error);
					Helper.loading('submit1', 'spinner1', false);
				});
		},
		updatePassword() {
			delete this.errors.message_password;
			this.message_password = '';
			if (this.beforeUpdatePassword()) {
				this.ok = true;
				Helper.loading('submit3', 'spinner3', true);
				let registerFormData = new FormData();
				registerFormData.append('oldPassword', this.oldPassword);
				registerFormData.append('newPassword', this.newPassword);
				registerFormData.append('confirmPassword', this.confirmPassword);

				fetch(
					Helper.route('admin/profile/update-password'),
					Helper.requestOptions('PUT', registerFormData)
				)
					.then((response) => {
						if (response.status === 401) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						Helper.loading('submit3', 'spinner3', false);

						console.log(data);

						if (data.problems) {
							this.errors.oldPassword = Helper.getError(
								data.problems,
								'oldPassword'
							);
							this.errors.newPassword = Helper.getError(
								data.problems,
								'newPassword'
							);
							this.errors.confirmPassword = Helper.getError(
								data.problems,
								'confirmPassword'
							);
						} else {
							if (!this.ok) {
								this.errors.message_password = data.message;
							} else {
								if (data.status) {
									this.oldPassword = '';
									this.newPassword = '';
									this.confirmPassword = '';
									this.message_password = data.message;
								}
							}
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit3', 'spinner3', false);
					});
			}
		},
		updateDocument(type) {
			if (type == 'ifu') {
				if (
					this.ifu != '' &&
					document.getElementById('ifuFile')?.files[0] != null
				) {
					this.ok = true;
					this.$isLoading(true);
					let registerFormData = new FormData();
					registerFormData.append('ifu', this.ifu);
					registerFormData.append(
						'ifuFile',
						document.getElementById('ifuFile').files[0]
					);

					fetch(
						Helper.route('admin/profile/update-ifu'),
						Helper.requestOptions('PUT', registerFormData)
					)
						.then((response) => {
							Helper.tokenChecker(response.status);
							if (response.status != 200) {
								this.ok = false;
							}
							return response.json();
						})
						.then((data) => {
							this.$isLoading(false);

							console.log(data);

							if (data.problems) {
								this.errors.ifu = Helper.getError(data.problems, 'ifu');
								this.errors.ifuFile = Helper.getError(data.problems, 'ifuFile');

								this.errors.docError = Helper.getError(data.problems, 'error');

								if (this.errors.docError && this.errors.docError.length > 0) {
									Helper.notification('error', this.errors.docError);
								}
							} else {
								if (!this.ok) {
									Helper.notification('error', data.message);
								} else {
									if (data.status) {
										this.company.ifu = this.ifu;
										this.store.user.company.ifu = this.ifu;
										this.ifuFile = data.data;
										Helper.notification('success', data.message);
									}
								}
							}
						})
						.catch((error) => {
							console.log(error);
							this.$isLoading(false);
						});
				}
			} else if (type == 'rccm') {
				if (
					this.rccm != '' &&
					document.getElementById('rccmFile')?.files[0] != null
				) {
					this.ok = true;
					this.$isLoading(true);
					let registerFormData = new FormData();
					registerFormData.append('rccm', this.rccm);
					registerFormData.append(
						'rccmFile',
						document.getElementById('rccmFile').files[0]
					);

					fetch(
						Helper.route('admin/profile/update-rccm'),
						Helper.requestOptions('PUT', registerFormData)
					)
						.then((response) => {
							Helper.tokenChecker(response.status);
							if (response.status != 200) {
								this.ok = false;
							}
							return response.json();
						})
						.then((data) => {
							this.$isLoading(false);

							if (data.problems) {
								this.errors.rccm = Helper.getError(data.problems, 'rccm');
								this.errors.rccmFile = Helper.getError(
									data.problems,
									'rccmFile'
								);
								this.errors.docError = Helper.getError(data.problems, 'error');

								if (this.errors.docError && this.errors.docError.length > 0) {
									Helper.notification('error', this.errors.docError);
								}
							} else {
								if (!this.ok) {
									Helper.notification('error', data.message);
								} else {
									if (data.status) {
										this.rccmFile = data.data;
										this.company.rccm = this.rccm;
										this.store.user.company.rccm = this.rccm;
										Helper.notification('success', data.message);
									}
								}
							}
						})
						.catch((error) => {
							console.log(error);
							this.$isLoading(false);
						});
				}
			} else if (type == 'card') {
				if (
					this.identity != '' &&
					this.identityType != '' &&
					document.getElementById('identityFile')?.files[0] != null
				) {
					this.ok = true;
					this.$isLoading(true);
					let registerFormData = new FormData();
					registerFormData.append('identity', this.identity);
					registerFormData.append('identityType', this.identityType);
					registerFormData.append(
						'identityFile',
						document.getElementById('identityFile').files[0]
					);

					fetch(
						Helper.route('admin/profile/update-idcard'),
						Helper.requestOptions('PUT', registerFormData)
					)
						.then((response) => {
							Helper.tokenChecker(response.status);
							if (response.status != 200) {
								this.ok = false;
							}
							return response.json();
						})
						.then((data) => {
							this.$isLoading(false);

							console.log(data);

							if (data.problems) {
								this.errors.identity = Helper.getError(
									data.problems,
									'identity'
								);
								this.errors.identityType = Helper.getError(
									data.problems,
									'identityType'
								);
								this.errors.identityFile = Helper.getError(
									data.problems,
									'identityFile'
								);

								this.errors.docError = Helper.getError(
									data.problems,
									'idError'
								);

								if (this.errors.docError && this.errors.docError.length > 0) {
									Helper.notification('error', this.errors.docError);
								}
							} else {
								if (!this.ok) {
									Helper.notification('error', data.message);
								} else {
									if (data.status) {
										this.managerIdCardFile = data.data;
										Helper.notification('success', data.message);
									}
								}
							}
						})
						.catch((error) => {
							console.log(error);
							this.$isLoading(false);
						});
				}
			}
		},
		beforeUpdateGeneral() {
			if (Helper.required(this.emailAddress) === '') {
				delete this.errors.emailAddress;
			} else {
				this.errors.emailAddress = Helper.required(this.emailAddress);
			}
			if (Helper.required(this.phoneNumber) === '') {
				delete this.errors.phoneNumber;
			} else {
				this.errors.phoneNumber = Helper.required(this.phoneNumber);
			}
			if (Helper.required(this.juridicStatus) === '') {
				delete this.errors.juridicStatus;
			} else {
				this.errors.juridicStatus = Helper.required(this.juridicStatus);
			}
			return Helper.objectNumberKey(this.errors) == 0 ? true : false;
		},
		beforeUpdatePassword() {
			if (Helper.required(this.newPassword) === '') {
				delete this.errors.newPassword;
			} else {
				this.errors.newPassword = Helper.required(this.newPassword);
			}
			if (Helper.required(this.oldPassword) === '') {
				delete this.errors.oldPassword;
			} else {
				this.errors.oldPassword = Helper.required(this.oldPassword);
			}
			if (Helper.required(this.confirmPassword) === '') {
				if (this.newPassword == this.confirmPassword) {
					delete this.errors.confirmPassword;
				} else {
					this.errors.confirmPassword = this.$t('setting.password_isnot_same');
				}
			} else {
				this.errors.confirmPassword = Helper.required(this.confirmPassword);
			}
			return Helper.objectNumberKey(this.errors) == 0 ? true : false;
		},
		initData(data) {
			if (data.company) {
				this.company = data.company;
				this.juridicStatus = this.company.juridicStatus.id;
				this.phoneNumber = data.phoneNumber;
				this.emailAddress = data.emailAddress;
				this.mobile = this.checkNull(this.company.phoneNumber);
				this.wants2FAuth = data.wants2FAuth;
				this.isMainAdmin = data.isMainAdmin;

				this.companyName = this.checkNull(this.company.name);

				this.logo = this.company.logo.url;
				this.centerOfInterest = this.checkNull(this.company.centerOfInterest);
				this.mainActivity = this.checkNull(this.company.mainActivity);
				this.secondaryActivity = this.checkNull(this.company.secondaryActivity);
				this.address = this.checkNull(this.company.address);
				this.reference = this.checkNull(this.company.reference);
				this.ifu = this.checkNull(this.company.ifu);
				this.ifuFile = this.company.ifuFile;
				this.rccm = this.checkNull(this.company.rccm);
				this.rccmFile = this.company.rccmFile;
				this.managerIdCard = this.checkNull(this.company.managerIdCard);
				this.managerIdCardFile = this.company.managerIdCardFile;
				this.lastname = this.checkNull(data.lastname);
				this.firstname = this.checkNull(data.firstname);
				this.photo = data.photo.url;

				if (this.managerIdCard != null) {
					this.identityType = this.checkNull(this.managerIdCard.type);
					this.identity = this.checkNull(this.managerIdCard.number);
				}
			}
		},
		loadJuridicstatus() {
			fetch(Helper.route('juridicstatus/all'))
				.then((response) => response.json())
				.then((data) => {
					if (data.data) {
						data.data.forEach((status) => {
							this.juridicStatusList.push({
								id: status.id,
								name: status.code,
							});
						});
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getData() {
			fetch(Helper.route('admin/profile/get-data'), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					if (data.status === true) {
						data = data.data;
						console.log(data);
						this.store.setUser(data);
						this.initData(data);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		deleteAccount(password) {
			const body = { password: password }			

			fetch(Helper.route('admin/profile/delete-account'), Helper.requestOptionsJSON('DELETE', body))
				.then((response) => {
					// Helper.tokenChecker(response.status);
					if (response.status == 401) {
						this.ok = false;
					} else {
						this.ok = true
					}					
					return response.json();
				})
				.then((data) => {
					console.log(data);

					if (this.ok) {
						Helper.notification('success', data.message);
						localStorage.removeItem(Helper.CLIENT_TOKEN);
						Helper.redirect(Helper.route('login', null, false));
					} else {
						this.deleteAccountSwal(data.message)
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		checkNull(input) {
			return input != 'null' && input != null ? input : '';
		},
		deletionConsent() {
			const checkboxes = document.querySelectorAll(
				'input[name="confirmation"]'
			);

			const allChecked = Array.from(checkboxes).every(
				(checkbox) => checkbox.checked
			);

			this.consent = allChecked;
		},
		deleteAccountRequest() {
			this.$swal({
				title: `${this.$t('global.attention')}!`,
				html: `
				<p>${this.$t('setting.account_delete_infos')} :</p>
					<ul>
						<li>- ${this.$t('setting.contact_info')}</li>
						<li>- ${this.$t('setting.financial_doc')}</li>
						<li>- ${this.$t('setting.pay_slips')}</li>
						<li>- ${this.$t('setting.saved_tasks')}</li>
						<li>- ${this.$t('setting.registered_applications')}</li>
						<li>- etc...</li>
					</ul>
				<p>${this.$t('setting.make_sure')}.</p>
				`,
				customClass: { htmlContainer: 'htmlContainer' },
				icon: 'error',
				showCancelButton: false,
				cancelButtonText: `${this.$t('global.cancel')}`,
				confirmButtonText: `${this.$t('global.next')}`,
			}).then((result) => {
				if (result.isConfirmed) {
					this.$swal({
						title: `${this.$t('global.attention')}!`,
						html: `
						<p>${this.$t('setting.you_want_continue')}.</p>

						<form id="deleteAccountForm">
							<label>
							<input type="checkbox" name="confirmation" value="true"> ${this.$t(
								'setting.confirmation1'
							)}.
							</label>
							<br> <br> 
							<label>
							<input type="checkbox" name="confirmation" value="true"> ${this.$t(
								'setting.confirmation2'
							)}.
							</label>
							<br><br> 
							<label>
							<input type="checkbox" name="confirmation" value="true"> ${this.$t(
								'setting.confirmation3'
							)}.
							</label>
							<br><br> 
						</form>
						<p>${this.$t('setting.would_you_like_continue')}</p>

						`,
						customClass: { htmlContainer: 'htmlContainer' },
						icon: 'error',
						showCancelButton: true,
						cancelButtonText: `${this.$t('global.cancel')}`,
						confirmButtonText: `${this.$t('setting.yes_continue')}`,
					}).then((result) => {
						if (result.isConfirmed) {
							this.deletionConsent();
							if (this.consent) {
								this.deleteAccountSwal()
							} else {
								Helper.notification('error', `${this.$t('setting.you_must')}.`);
							}
						}
					});
				}
			});
		},
		deleteAccountSwal(message = "") {
			this.$swal({
				title: `${this.$t('global.attention')}!`,
				html: `
				<p>${this.$t('setting.confirm_account_delete')}.</p>

				<p class="error">`+message+`</p>

				<div class="row m-0 w-100">
					<label class="mb-2"> ${this.$t('auth.pwd')}: 
						<input type="password" name="password" min="6" required  class="form-control" id="deletePassword" value=""> 
					</label>
					<br>
				</div>
				`,
				customClass: { htmlContainer: 'htmlContainer' },
				icon: 'error',
				showCancelButton: true,
				cancelButtonText: `${this.$t('global.cancel')}`,
				confirmButtonText: `${this.$t('setting.yes_delete')}`,
			}).then((result) => {
				if (result.isConfirmed) {
					this.deleteAccount(document.getElementById('deletePassword').value);
				}
			});
		},
		isHigherThan30Days(timestamp) {
			var isHigherThan = false;

			const date = new Date(timestamp);
			const today = new Date();
			const difference = today.getTime() - date.getTime();
			const differenceInDays = Math.floor(difference / (1000 * 60 * 60 * 24));

			if (differenceInDays > 30) {
				isHigherThan = true;
			} else {
				isHigherThan = false;
			}

			return isHigherThan;
		},
	},
	watch: {
		emailAddress: function () {
			if (Helper.required(this.emailAddress) === '') {
				delete this.errors.emailAddress;
			} else {
				this.errors.emailAddress = Helper.required(this.emailAddress);
			}
		},
		phoneNumber: function () {
			if (Helper.required(this.phoneNumber) === '') {
				delete this.errors.phoneNumber;
			} else {
				this.errors.phoneNumber = Helper.required(this.phoneNumber);
			}
		},
		juridicStatus: function () {
			if (Helper.required(this.juridicStatus) === '') {
				delete this.errors.juridicStatus;
			} else {
				this.errors.juridicStatus = Helper.required(this.juridicStatus);
			}
		},
		oldPassword: function () {
			if (Helper.required(this.oldPassword) === '') {
				delete this.errors.oldPassword;
			} else {
				this.errors.oldPassword = Helper.required(this.oldPassword);
			}
		},
		newPassword: function () {
			if (Helper.required(this.newPassword) === '') {
				delete this.errors.newPassword;
				if (this.newPassword != this.confirmPassword) {
					delete this.errors.confirmPassword;
				}
			} else {
				this.errors.newPassword = Helper.required(this.newPassword);
			}
		},
		confirmPassword: function () {
			if (Helper.required(this.confirmPassword) === '') {
				if (this.newPassword == this.confirmPassword) {
					delete this.errors.confirmPassword;
				} else {
					this.errors.confirmPassword =
						'Les deux mots de passe ne sont pas identiques';
				}
			} else {
				this.errors.confirmPassword = Helper.required(this.confirmPassword);
			}
		},
	},
	created() {
		this.user = this.store.user;
		this.initData(this.store.user);
		this.getData();
		this.loadJuridicstatus();
	},
};
</script>
<style>
.htmlContainer {
	font-size: 0.9em !important;
	text-align: left !important;
}
</style>
