<template>
	<div class="text-center d-none" :class="className" :id="elementId">
		<div class="spinner-border text-primary-" role="status">
			<span class="visually-hidden">Loading...</span>
		</div>
	</div>
</template>
<script>
export default {
	name: 'SpinnerMe',
	props: {
		elementId: {
			default: '',
		},
		className: {
			default: 'mt-4',
		},
	},
};
</script>
