<template>
	<div>
		<Template :title="$t('admin.new_admin')">
			<section class="p-5 py-4">
				<Back />

				<div class="row mt-2">
					<div class="col-xl-9">
						<div
							class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 py-1 mt-0"
						>
							{{ $t('admin.invite_to_admin') }}
						</div>
						<div class="bg-light-hight- rounded mb-4 py-4">
							<form
								@submit.prevent="adminNew"
								class="col-lg-10 col-md-11 mx-auto"
							>
								<div class="row">
									<div class="col">
										<InputFC
											:error="errors.firstname"
											:type="'text'"
											v-model="firstname"
											:placeholder="$t('global.firstname')"
										/>
									</div>
									<div class="col">
										<InputFC
											:error="errors.lastname"
											:type="'text'"
											v-model="lastname"
											:placeholder="$t('global.lastname')"
										/>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.phoneNumber"
											:type="'tel'"
											v-model="phoneNumber"
											:placeholder="$t('global.tel')"
										/>
									</div>
									<div class="col-lg-6 mt-4">
										<InputFC
											:error="errors.emailAddress"
											:type="'email'"
											v-model="emailAddress"
											:placeholder="$t('global.email')"
										/>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-lg-12 mt-4">
										<InputFC
											:error="errors.expiresAt"
											:type="'datetime-local'"
											v-model="expiresAt"
											:placeholder="$t('admin.expire_link_date')"
										/>
									</div>
								</div>
								<hr />

								<div class="hstack">
									<div class="">
										<SpinnerMe :elementId="'spinner'" />
										<button id="submit" class="button px-4 py-1 mt-4">
											{{ $t('global.send') }}
										</button>
									</div>
									<div class="ms-3">
										<button
											class="button-default bg-light- text-color- fw-500 shadow-sm px-4 py-1 mt-4"
											@click="$router.go(-1)"
										>
											{{ $t('global.cancel') }}
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div class="col-xl-3">
						<div class="rounded bg-second- text-white text-4 text-center p-1">
							{{ $t('global.advice') }}
						</div>
						<div
							class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
							v-html="$t('global.advice_action')"
						></div>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import InputFC from '@/components/InputFC.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
import Helper from '@/helpers/helper';
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: 'Create',
	components: { Template, Back, InputFC, SpinnerMe },
	data() {
		return {
			errors: [],
			ok: false,
			check_form: false,
			lastname: '',
			firstname: '',
			phoneNumber: '',
			emailAddress: '',
			expiresAt: '',
		};
	},
	methods: {
		adminNew() {
			this.check_form = true;
			if (this.beforeCreateItem()) {
				this.ok = true;
				this.error_message = '';
				Helper.loading('submit', 'spinner', true);
				let registerFormData = new FormData();
				registerFormData.append('lastname', this.lastname);
				registerFormData.append('firstname', this.firstname);
				registerFormData.append('phoneNumber', this.phoneNumber);
				registerFormData.append('emailAddress', this.emailAddress);
				registerFormData.append('expiresAt', this.expiresAt);

				fetch(
					Helper.route('admin/management/coadmin/invite'),
					Helper.requestOptions('POST', registerFormData)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						if (response.status != 200) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						console.log(data);
						this.check_form = true;
						Helper.loading('submit', 'spinner', false);

						if (data.problems) {
							this.errors.lastname = Helper.getError(data.problems, 'lastname');
							this.errors.firstname = Helper.getError(
								data.problems,
								'firstname'
							);
							this.errors.phoneNumber = Helper.getError(
								data.problems,
								'phoneNumber'
							);
							this.errors.emailAddress = Helper.getError(
								data.problems,
								'emailAddress'
							);
							this.errors.expiresAt = Helper.getError(
								data.problems,
								'expiresAt'
							);
						} else {
							if (!this.ok) {
								this.error_message = data.message;
								this.$swal.fire('Oops...', data.message, 'error');
							} else {
								if (data.status) {
									Helper.notification('success', data.message);
									this.message = data.message;
									this.$router.push({ path: '/admin/invitation' });
								}
							}
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit', 'spinner', false);
					});
			}
		},
		beforeCreateItem() {
			if (Helper.required(this.lastname) !== '') {
				this.errors.lastname = Helper.required(this.lastname);
			} else {
				delete this.errors.lastname;
			}
			if (Helper.required(this.firstname) !== '') {
				this.errors.firstname = Helper.required(this.firstname);
			} else {
				delete this.errors.firstname;
			}
			if (Helper.required(this.phoneNumber) !== '') {
				this.errors.phoneNumber = Helper.required(this.phoneNumber);
			} else {
				delete this.errors.phoneNumber;
			}
			if (Helper.required(this.emailAddress) !== '') {
				this.errors.emailAddress = Helper.required(this.emailAddress);
			} else {
				delete this.errors.emailAddress;
			}
			if (Helper.required(this.expiresAt) !== '') {
				this.errors.expiresAt = Helper.required(this.expiresAt);
			} else {
				delete this.errors.expiresAt;
			}
			console.log(this.errors);
			return Helper.objectNumberKey(this.errors) == 0 ? true : false;
		},
	},
	watch: {
		lastname: function () {
			if (this.check_form) {
				if (Helper.required(this.lastname) === '') {
					delete this.errors.lastname;
				} else {
					this.errors.lastname = Helper.required(this.lastname);
				}
			}
		},
		firstname: function () {
			if (this.check_form) {
				if (Helper.required(this.firstname) === '') {
					delete this.errors.firstname;
				} else {
					this.errors.firstname = Helper.required(this.firstname);
				}
			}
		},
		emailAddress: function () {
			if (this.check_form) {
				if (Helper.checkEmail(this.emailAddress) === '') {
					delete this.errors.emailAddress;
				} else {
					this.errors.emailAddress = Helper.checkEmail(this.emailAddress);
				}
			}
		},
		phoneNumber: function () {
			if (this.check_form) {
				if (Helper.required(this.phoneNumber) === '') {
					delete this.errors.phoneNumber;
				} else {
					this.errors.phoneNumber = Helper.required(this.phoneNumber);
				}
			}
		},
		expiresAt: function () {
			if (this.check_form) {
				if (Helper.required(this.expiresAt) === '') {
					delete this.errors.expiresAt;
				} else {
					this.errors.expiresAt = Helper.required(this.expiresAt);
				}
			}
		},
	},
};
</script>
<style></style>
