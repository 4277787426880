<template>
	<div>
		<Template>
			<div class="row p-3 px-4 m-0 g-5">
				<div class="col-lg-4 col-md-6 d-none">
					<div class="bg-primary- text-center dash_item p-5 text-white text-4">
						{{ $t('home.first_title') }} <br />
						<br />
						{{ $t('home.second_title') }}
					</div>
				</div>
				<div class="col-lg-4 col-md-6">
					<div class="px-2 py-1 bg-light-">
						<p class="text-6 mb-0">{{ $t('dashboard.express_management') }}</p>
						<h6 class="fw-800 my-2 text-4 text-black-">
							{{ $t('dashboard.at_post') }}
						</h6>
					</div>
					<canvas id="presence" height="300" width="280"></canvas>
				</div>
				<div class="col-lg-4 col-md-6">
					<div class="px-2 py-1 bg-light-">
						<p class="text-6 mb-0">{{ $t('dashboard.express_management') }}</p>
						<h6 class="fw-800 my-2 text-4 text-black-">
							{{ $t('dashboard.wage_margin') }}
						</h6>
					</div>
					<canvas id="salary" height="300" width="250"></canvas>
				</div>
				<div class="col-lg-4 col-md-6">
					<div class="px-2 py-1 bg-light-">
						<p class="text-6 mb-0">{{ $t('dashboard.training') }}</p>
						<h6 class="fw-800 my-2 text-4 text-black-">
							{{ $t('dashboard.nature') }}
						</h6>
					</div>
					<canvas id="training" height="300" width="250"></canvas>
				</div>
				<div class="col-lg-4 col-md-6">
					<div class="px-2 py-1 bg-light-">
						<p class="text-6 mb-0">{{ $t('dashboard.document') }}</p>
						<h6 class="fw-800 my-2 text-4 text-black-">
							{{ $t('dashboard.library') }}
						</h6>
					</div>
					<canvas id="document" height="300" width="250"></canvas>
				</div>
				<div class="col-lg-4 col-md-6">
					<div class="px-2 py-1 bg-light-">
						<p class="text-6 mb-0">{{ $t('dashboard.job_offer') }}</p>
						<h6 class="fw-800 my-2 text-4 text-black-">
							{{ $t('dashboard.type') }}
						</h6>
					</div>
					<canvas id="job_contract" height="300" width="280"></canvas>
				</div>
				<div class="col-lg-4 col-md-6">
					<div class="px-2 py-1 bg-light-">
						<p class="text-6 mb-0">{{ $t('dashboard.job_offer') }}</p>
						<h6 class="fw-800 my-2 text-4 text-black-">
							{{ $t('global.status') }}
						</h6>
					</div>
					<canvas id="job_status" height="300" width="300"></canvas>
				</div>
				<div class="col-lg-4 col-md-6">
					<div class="px-2 py-1 bg-light-">
						<p class="text-6 mb-0">{{ $t('dashboard.candidat_profils') }}</p>
						<h6 class="fw-800 my-2 text-4 text-black-">
							{{ $t('global.sex') }}
						</h6>
					</div>
					<canvas id="application_gender" height="300" width="280"></canvas>
				</div>
				<div class="col-lg-4 col-md-6">
					<div class="px-2 py-1 bg-light-">
						<p class="text-6 mb-0">{{ $t('dashboard.candidat_profils') }}</p>
						<h6 class="fw-800 my-2 text-4 text-black-">
							{{ $t('global.status') }}
						</h6>
					</div>
					<canvas id="application_status" height="300" width="280"></canvas>
				</div>
				<div class="col-lg-4 col-md-6">
					<div class="px-2 py-1 bg-light-">
						<p class="text-6 mb-0">{{ $t('dashboard.sales') }}</p>
						<h6 class="fw-800 my-2 text-4 text-black-">
							{{ $t('dashboard.amounts') }}
						</h6>
					</div>
					<canvas id="sales" height="300"></canvas>
				</div>
				<div class="col-lg-4 col-md-6">
					<div class="px-2 py-1 bg-light-">
						<p class="text-6 mb-0">{{ $t('dashboard.buyings') }}</p>
						<h6 class="fw-800 my-2 text-4 text-black-">
							{{ $t('dashboard.amounts') }}
						</h6>
					</div>
					<canvas id="buyings" height="300"></canvas>
				</div>
			</div>
		</Template>
	</div>
</template>
<script>
import Chart from 'chart.js/auto';
import Template from '@/components/Template.vue';
import Helper from '@/helpers/helper';

export default {
	name: 'DashboardPage',
	components: { Template },
	data() {
		return {
			presence: {},
			salary: [],
			training: {},
			sales: {},
			buyings: {},
			document_labels: [],
			document_numbers: [],
			job_contract_labels: [],
			job_contract_numbers: [],
			job_status_labels: [],
			job_status_numbers: [],
			application_gender_labels: [],
			application_gender_numbers: [],
			application_status_labels: [],
			application_status_numbers: [],
		};
	},
	methods: {
		loadList() {
			this.loading = true;
			fetch(Helper.route('admin/dashboard/stats'), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					console.log(data);
					this.presence = data.data.presence;
					this.training = data.data.training;
					this.sales = data.data.sale;
					this.buyings = data.data.buying;

					let salary = data.data.salary;
					salary.forEach((item) => {
						this.salary.push(item.number);
					});

					let document = data.data.document;
					document.forEach((item) => {
						this.document_numbers.push(item.number);
					});
					document.forEach((item) => {
						this.document_labels.push(
							Helper.capitalize(Helper.truncate(item.folder, 10))
						);
					});

					let job_contract = data.data.jobContract;
					job_contract.forEach((item) => {
						this.job_contract_labels.push(Helper.capitalize(item.contract));
					});
					job_contract.forEach((item) => {
						this.job_contract_numbers.push(item.number);
					});

					let job_status = data.data.jobStatus;
					job_status.forEach((item) => {
						this.job_status_labels.push(Helper.capitalize(item.status));
					});
					job_status.forEach((item) => {
						this.job_status_numbers.push(item.number);
					});

					let application_gender = data.data.applicantGender;
					application_gender.forEach((item) => {
						this.application_gender_labels.push(Helper.capitalize(item.gender));
					});
					application_gender.forEach((item) => {
						this.application_gender_numbers.push(item.number);
					});

					let application_status = data.data.applicationStatus;
					application_status.forEach((item) => {
						this.application_status_labels.push(Helper.capitalize(item.status));
					});
					application_status.forEach((item) => {
						this.application_status_numbers.push(item.number);
					});

					this.presence_fn();
					this.salary_fn();
					this.training_fn();
					this.document_fn();
					this.job_contract_fn();
					this.job_status_fn();
					this.application_gender_fn();
					this.application_status_fn();
					this.sales_fn();
					this.buyings_fn();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		presence_fn() {
			const ctx2 = document.getElementById('presence').getContext('2d');
			const presence = new Chart(ctx2, {
				type: 'doughnut',
				data: {
					labels: ['Au poste', 'En congé', 'En vacances'],
					datasets: [
						{
							data: [
								this.presence.present,
								this.presence.conges,
								this.presence.vacances,
							],
							backgroundColor: [
								'#EF5905',
								'rgba(54, 162, 235, 0.2)',
								'rgb(0, 0, 0, .2)',
								'rgb(0, 228, 118, .2)',
							],
							borderColor: [
								'rgba(255, 99, 132, 1)',
								'rgba(54, 162, 235, 1)',
								'rgb(0, 0, 0, 1)',
								'rgb(0, 228, 118, 1)',
							],
							hoverOffset: 4,
							borderWidth: 1,
						},
					],
				},
			});
			presence;
		},
		salary_fn() {
			const ctx1 = document.getElementById('salary').getContext('2d');
			const salary = new Chart(ctx1, {
				type: 'bar',
				data: {
					labels: [
						'0 à 80k',
						'80k à 150k',
						'150k à 250k',
						'250k à 500k',
						'500k à ∞',
					],
					datasets: [
						{
							label: 'Salaire',
							data: [
								this.salary[0],
								this.salary[1],
								this.salary[2],
								this.salary[3],
								this.salary[4],
							],
							backgroundColor: [
								'rgba(255, 99, 132, 1)',
								'rgba(54, 162, 235, 1)',
								'#0071FC',
								'#023385',
								'rgba(153, 102, 255, 1)',
							],
							borderColor: [
								'rgba(255, 99, 132, 1)',
								'rgba(54, 162, 235, 1)',
								'#0071FC',
								'#023385',
								'rgba(153, 102, 255, 1)',
							],
							borderWidth: 1,
						},
					],
				},
				options: {
					scales: {
						y: {
							beginAtZero: true,
						},
					},
				},
			});
			salary;
		},
		training_fn() {
			const ctx2 = document.getElementById('training').getContext('2d');
			const training = new Chart(ctx2, {
				type: 'pie',
				data: {
					labels: ['En ligne', 'Présentiel'],
					datasets: [
						{
							data: [this.training.online, this.training.faceToFace],
							backgroundColor: ['#F30B0B', '#00FD83'],
							borderColor: ['#F30B0B', '#00FD83'],
							hoverOffset: 4,
							borderWidth: 1,
						},
					],
				},
			});
			training;
		},
		document_fn() {
			const ctx1 = document.getElementById('document').getContext('2d');
			const document_ = new Chart(ctx1, {
				type: 'bar',
				data: {
					labels: this.document_labels,
					datasets: [
						{
							label: 'Document',
							data: this.document_numbers,
							backgroundColor: [
								'#0071FC',
								'#023385',
								'rgba(153, 102, 255, 1)',
								'rgba(255, 99, 132, 0.2)',
								'rgba(255, 159, 64, 0.2)',
								'rgba(255, 205, 86, 0.2)',
								'rgba(75, 192, 192, 0.2)',
								'rgba(54, 162, 235, 0.2)',
								'rgba(153, 102, 255, 0.2)',
								'rgba(201, 203, 207, 0.2)',
								'rgba(255, 205, 86, 0.2)',
								'rgba(75, 192, 192, 0.2)',
								'rgba(54, 162, 235, 0.2)',
								'rgba(153, 102, 255, 0.2)',
								'rgba(201, 203, 207, 0.2)',
							],
							borderColor: [
								'#0071FC',
								'#023385',
								'rgba(153, 102, 255, 1)',
								'rgba(255, 159, 64, 0.2)',
								'rgba(255, 205, 86, 0.2)',
								'rgba(75, 192, 192, 0.2)',
								'rgba(54, 162, 235, 0.2)',
								'rgba(153, 102, 255, 0.2)',
								'rgba(201, 203, 207, 0.2)',
								'rgba(255, 205, 86, 0.2)',
								'rgba(75, 192, 192, 0.2)',
								'rgba(54, 162, 235, 0.2)',
								'rgba(153, 102, 255, 0.2)',
								'rgba(201, 203, 207, 0.2)',
							],
							borderWidth: 1,
						},
					],
				},
			});
			document_;
		},
		job_contract_fn() {
			const ctx2 = document.getElementById('job_contract').getContext('2d');
			const job_contract = new Chart(ctx2, {
				type: 'pie',
				data: {
					labels: this.job_contract_labels,
					datasets: [
						{
							data: this.job_contract_numbers,
							backgroundColor: [
								'#0071FC',
								'#023385',
								'rgba(153, 102, 255, 1)',
								'rgba(255, 99, 132, 0.2)',
								'rgba(255, 159, 64, 0.2)',
								'rgba(255, 205, 86, 0.2)',
								'rgba(75, 192, 192, 0.2)',
								'rgba(54, 162, 235, 0.2)',
								'rgba(153, 102, 255, 0.2)',
								'rgba(201, 203, 207, 0.2)',
								'rgba(255, 205, 86, 0.2)',
								'rgba(75, 192, 192, 0.2)',
								'rgba(54, 162, 235, 0.2)',
								'rgba(153, 102, 255, 0.2)',
								'rgba(201, 203, 207, 0.2)',
							],
							borderColor: [
								'#0071FC',
								'#023385',
								'rgba(153, 102, 255, 1)',
								'rgba(255, 159, 64, 0.2)',
								'rgba(255, 205, 86, 0.2)',
								'rgba(75, 192, 192, 0.2)',
								'rgba(54, 162, 235, 0.2)',
								'rgba(153, 102, 255, 0.2)',
								'rgba(201, 203, 207, 0.2)',
								'rgba(255, 205, 86, 0.2)',
								'rgba(75, 192, 192, 0.2)',
								'rgba(54, 162, 235, 0.2)',
								'rgba(153, 102, 255, 0.2)',
								'rgba(201, 203, 207, 0.2)',
							],
							hoverOffset: 4,
							borderWidth: 1,
						},
					],
				},
			});
			job_contract;
		},
		job_status_fn() {
			const ctx2 = document.getElementById('job_status').getContext('2d');
			const job_status = new Chart(ctx2, {
				type: 'doughnut',
				data: {
					labels: this.job_status_labels,
					datasets: [
						{
							data: this.job_status_numbers,
							backgroundColor: [
								'#0071FC',
								'#023385',
								'rgba(153, 102, 255, 1)',
								'rgba(255, 99, 132, 0.2)',
								'rgba(255, 159, 64, 0.2)',
								'rgba(255, 205, 86, 0.2)',
								'rgba(75, 192, 192, 0.2)',
								'rgba(54, 162, 235, 0.2)',
								'rgba(153, 102, 255, 0.2)',
								'rgba(201, 203, 207, 0.2)',
								'rgba(255, 205, 86, 0.2)',
								'rgba(75, 192, 192, 0.2)',
								'rgba(54, 162, 235, 0.2)',
								'rgba(153, 102, 255, 0.2)',
								'rgba(201, 203, 207, 0.2)',
							],
							borderColor: [
								'#0071FC',
								'#023385',
								'rgba(153, 102, 255, 1)',
								'rgba(255, 159, 64, 0.2)',
								'rgba(255, 205, 86, 0.2)',
								'rgba(75, 192, 192, 0.2)',
								'rgba(54, 162, 235, 0.2)',
								'rgba(153, 102, 255, 0.2)',
								'rgba(201, 203, 207, 0.2)',
								'rgba(255, 205, 86, 0.2)',
								'rgba(75, 192, 192, 0.2)',
								'rgba(54, 162, 235, 0.2)',
								'rgba(153, 102, 255, 0.2)',
								'rgba(201, 203, 207, 0.2)',
							],
							hoverOffset: 4,
							borderWidth: 1,
						},
					],
				},
			});
			job_status;
		},
		application_gender_fn() {
			const ctx2 = document
				.getElementById('application_gender')
				.getContext('2d');
			const application_gender = new Chart(ctx2, {
				type: 'pie',
				data: {
					labels: this.application_gender_labels,
					datasets: [
						{
							data: this.application_gender_numbers,
							backgroundColor: ['#EF5905', 'rgba(54, 162, 235, 0.2)'],
							borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
							hoverOffset: 4,
							borderWidth: 1,
						},
					],
				},
			});
			application_gender;
		},
		application_status_fn() {
			const ctx2 = document
				.getElementById('application_status')
				.getContext('2d');
			const application_status = new Chart(ctx2, {
				type: 'doughnut',
				data: {
					labels: this.application_status_labels,
					datasets: [
						{
							data: this.application_status_numbers,
							backgroundColor: [
								'#EF5905',
								'rgba(54, 162, 235, 0.2)',
								'rgba(54, 0, 235, 0.2)',
							],
							borderColor: [
								'rgba(255, 99, 132, 1)',
								'rgba(54, 162, 235, 1)',
								'rgba(54, 0, 235, 1)',
							],
							hoverOffset: 4,
							borderWidth: 1,
						},
					],
				},
			});
			application_status;
		},
		sales_fn() {
			const ctx2 = document.getElementById('sales').getContext('2d');
			const job_status = new Chart(ctx2, {
				type: 'bubble',
				data: {
					datasets: [
						{
							label: 'HT',
							data: [
								{
									x: 20,
									y: this.sales.amountHT,
									r: 8,
								},
							],
							backgroundColor: 'rgb(0, 102, 227)',
							borderColor: 'rgb(0, 102, 227)',
							hoverOffset: 4,
							borderWidth: 1,
						},
						{
							label: 'TVA',
							data: [
								{
									x: 40,
									y: this.sales.tva,
									r: 8,
								},
							],
							backgroundColor: 'rgb(0, 228, 118)',
							borderColor: 'rgb(0, 228, 118)',
							hoverOffset: 4,
							borderWidth: 1,
						},
						{
							label: 'TTC',
							data: [
								{
									x: 60,
									y: this.sales.amountTTC,
									r: 8,
								},
							],
							backgroundColor: 'rgb(0, 0, 0, 0.9)',
							borderColor: 'rgb(0, 0, 0)',
							hoverOffset: 4,
							borderWidth: 1,
						},
					],
				},
			});
			job_status;
		},
		buyings_fn() {
			const ctx2 = document.getElementById('buyings').getContext('2d');
			const job_status = new Chart(ctx2, {
				type: 'bubble',
				data: {
					datasets: [
						{
							label: 'HT',
							data: [
								{
									x: 20,
									y: this.buyings.amountHT,
									r: 8,
								},
							],
							backgroundColor: 'rgb(0, 102, 227)',
							borderColor: 'rgb(0, 102, 227)',
							hoverOffset: 4,
							borderWidth: 1,
						},
						{
							label: 'TVA',
							data: [
								{
									x: 40,
									y: this.buyings.tva,
									r: 8,
								},
							],
							backgroundColor: 'rgb(0, 228, 118)',
							borderColor: 'rgb(0, 228, 118)',
							hoverOffset: 4,
							borderWidth: 1,
						},
						{
							label: 'TTC',
							data: [
								{
									x: 60,
									y: this.buyings.amountTTC,
									r: 8,
								},
							],
							backgroundColor: 'rgb(0, 0, 0, 0.9)',
							borderColor: 'rgb(0, 0, 0)',
							hoverOffset: 4,
							borderWidth: 1,
						},
					],
				},
			});
			job_status;
		},
	},
	created() {
		this.loadList();
	},
};
</script>
<style>
.dash_item {
	height: 380px;
}
.dash_header {
	background-color: #ebeaea;
}
</style>
