<template>
	<div v-if="type === 'toggle-password'">
		<div class="input-group mt-4">
			<input
				:type="password_visible ? 'password' : 'text'"
				autocomplete="off"
				class="form-control form-control--"
				:value="modelValue"
				@input="updateValue"
				:placeholder="placeholder"
				aria-describedby="basic-addon1"
			/>
			<span
				type="button"
				class="input-group-text form-control--"
				id="basic-addon1"
				@click="password_visible = !password_visible"
				><i
					class="px-2 fs-5"
					:class="password_visible ? 'uil uil-eye' : 'uil uil-eye-slash'"
				></i
			></span>
		</div>
		<span v-show="error != ''" v-text="error" class="error"></span>
	</div>
	<div v-else>
		<input
			:disabled="disabled"
			autocomplete="off"
			v-if="type != 'select' && type != 'textarea' && !suffixIcon"
			:type="type"
			:value="modelValue"
			@input="updateValue"
			:placeholder="placeholder"
			:class="type === 'file' ? 'form-control' : 'form-control-'"
		/>

		<div class="input-container" v-if="suffixIcon">
			<input
				:disabled="disabled"
				autocomplete="off"
				v-if="type != 'select' && type != 'textarea'"
				:type="type"
				:value="modelValue"
				@input="updateValue"
				:placeholder="placeholder"
				:class="type === 'file' ? 'form-control' : 'form-control-'"
			/>

			<div v-if="suffixIcon" class="suffix-icon">
				<i :class="suffixIcon"></i>
			</div>
		</div>

		<textarea
			v-else-if="type === 'textarea'"
			class="form-control-"
			:value="modelValue"
			@input="updateValue"
			:placeholder="placeholder"
		></textarea>

		<select
			class="form-control-"
			v-else-if="type === 'select'"
			:value="modelValue"
			@input="updateValue"
		>
			<option value="" v-text="placeholder"></option>
			<option
				v-for="option in options"
				v-bind:value="option.id"
				:key="option.id"
			>
				{{ option.name }}
			</option>
		</select>

		<span v-show="error != ''" v-text="error" class="error"></span>
	</div>
</template>

<script>
export default {
	name: 'InputMe',
	data() {
		return {
			password_visible: true,
		};
	},
	props: {
		error: {
			default: '',
		},
		placeholder: {
			default: '',
		},
		modelValue: {
			default: '',
		},
		type: {
			default: 'text',
		},
		suffixIcon: {
			default: undefined,
		},
		options: {
			default: [],
		},
		value: {
			default: 'Valider',
		},
		disabled: {
			default: false,
		},
	},
	methods: {
		updateValue(event) {
			this.$emit('update:modelValue', event.target.value);
		},
	},
};
</script>
<style>
.input-container {
	position: relative;
}

.suffix-icon {
	position: absolute;
	right: 10px;
	top: 5px;
}
</style>
