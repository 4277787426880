const messages = {
	fr: {
		topbar: {
			about: 'À propos',
			legal_mention: 'Mentions Légales',
			terms: "Conditions et Règles d'utilisation",
			protection_policy: 'Politiques de Protection des Données',
			assistance: "Centre d'assistance",
			faq: 'FAQ',
			support_us: 'Nous Soutenir',
			comments: 'Commentaires',
			set_language: 'Changer la langue',
			french: 'Français',
			english: 'Anglais',
			co_admin: 'Co-administrateur',
			my_subscriptions: 'Mes abonnements',
			subscription: 'Subscription',
			set_theme: 'Changer le thème',
			light: 'Clair',
			dark: 'Sombre',
			develop: 'Développer',
			search_placeholder: 'Que recherchez-vous ?',
		},
		admin: {
			invitations: 'Invitations',
			rightOf: 'Droits de',
			assign: 'Attribuer',
			myRights: 'Mes Droits',
			rightAssign: 'Attribution de droits',
			invite_to_admin: 'Inviter un co-administrateur',
			suspend: 'Suspendre',
			reactivate: 'Réactiver',
			expire_link_date: "Date d'expiration du lien d'invitation",
			new_admin: 'Nouvel administrateur',
		},
		global: {
			myself: 'Moi même',
			address: 'Adresse',
			download: 'Télécharger',
			contact: 'Contact',
			notification: 'Notification',
			title: 'Titre',
			content: 'Contenu',
			date: 'Date',
			active: 'Activer',
			expired: 'Expiré',
			none_element: 'Aucun element trouvé',
			lastname: 'Nom',
			firstname: 'Prénom',
			date_add: "Date d'ajout",
			tel: 'Numéro de téléphone',
			email: 'Adresse e-mail',
			send: 'Envoyer',
			save: 'Enregistrer',
			save_as_draft: 'Enregistrer comme brouillon',
			cancel: 'Annuler',
			canceled: 'Annulé',
			suspended: 'Suspendue',
			advice: 'Conseil',
			advice_action:
				'Un profil à jour inspire beaucoup confiance et vous mets en sécurité. <br> Assurez-vous d’avoir ajouté votre photo de profil, vos documents, un coadministrateur.',
			status: 'Statut',
			activate: 'Activer',
			desactivate: 'Désctiver',
			in_progress: 'En cours',
			response_s: 'Réponse (s)',
			response: 'Réponse',
			resolved: 'Résolu',
			insert_piece: 'Insérer une pièce',
			sex: 'Sexe',
			close: 'Fermer',
			comments: 'Commentaires',
			write_at: 'Écrit le',
			edit_mode: 'Passer en mode édition',
			leave_edit_mode: 'Quitter mode édition',
			not_right_description:
				"Désolé cher co-administrateur, les droits d'accès à vous octroyés par votre administrateur principal, ne vous permettent malheureusement pas d'accéder à la ressource demandée ou d'effectuer l'action désirée. Vous pouvez toutefois contacter l'administrateur principal pour une éventuelle réclamation.",
			back: 'Retour',
			unauthorized: 'Non autorisé',
			company_name: "Nom de l'entreprise",
			general: 'Général',
			security: 'Sécurité',
			document: 'Document',
			ifu: 'Identité Fiscale Unique',
			amount: 'Montant',
			start_date: 'Date debut',
			end_date: 'Date de Fin',
			valid: 'Valide',
			type: 'Type',
			annual: 'Annuel',
			monthly: 'Mensuel',
			filter: 'Filtrer',
			folder: 'Dossier',
			favorite: 'Favoris',
			post: 'Poste',
			profile_search: 'Profil recherché',
			nomber: 'Nombre',
			fenced: 'Clôturé',
			details: 'Détails',
			detail: 'Détails',
			pending: 'En cours',
			order_of_addition: "Ordre d'ajout",
			reference: 'Référence',
			object: 'Objet',
			priority: 'Priorité',
			no_data: 'Aucun élément trouvé',
			deletion_warning: 'Avertissement de suppression',
			warning_text:
				'Etes-vous sûr de vouloir supprimer cet élément ? <br />' +
				'Avant de confirmer cette opération, sachez que la suppression' +
				'est irréversible.',
			deleted_successfully: 'Suppression effectuée avec succès',
			delete: 'Supprimer',
			order: 'Ordre',
			accepted: 'Accepté',
			signup_order: "Ordre d'inscription",
			note: 'Note',
			signup: 'Inscription',
			male: 'Masculin',
			feminine: 'Féminin',
			denied: 'Refusé',
			withdraw: 'Retirer',
			edit: 'Editer',
			overview: 'Aperçu',
			view_list: "Voir la liste",
			hide_list: "Cacher la liste",
			profile: 'Profil',
			state: 'Etat',
			dates: 'Dates',
			on_line: 'Mise en ligne',
			fence: 'Clôture',
			update: 'Modifier',
			suspend: 'Suspendre',
			burn_date: 'Date de naissance',
			birth_place: 'Lieu de naissance',
			nationality: 'Nationalité',
			gender: 'Genre',
			phone: 'Téléphone',
			country: 'Pays',
			department: 'Département',
			municipal: 'Commune',
			city: 'Ville',
			next: 'Suivant',
			preview: 'Apperçu',
			previous: 'Précédent',
			action: 'Action',
			validate: 'Valider',
			ask: 'Demander',
			accept: 'Accepter',
			restoration_warning: 'Avertissement restauration',
			restorated_successfully: 'Restauration effectué avec succès',
			restore: 'Restaurer',
			recents: 'Récents',
			size: 'Taille',
			add_now: 'Ajouter maintenant',
			archiving_warning: "Avertissement d'archivage",
			archiving_successfully: 'Archivage effectué avec succès',
			archive: 'Archives',
			unarchive: 'Désarchiver',
			sent_by: 'Envoyé par',
			add_the: 'Ajouté le',
			drag_and_drop: 'Glisser et deposer vos fichiers ici.',
			update_your_email: 'Modifier votre email',
			new_email: 'Nouvel email',
			attention: 'Attention',
			number: 'Numéro',
			no_data_found: 'Aucune donnée trouvée',
			add: 'Ajouter',
			today_summary: "Résumé d'aujourd'hui",
			summary: "Résumé",
			import_now: 'Importer maintenant',
			yes: 'Oui',
			no: 'Non',
			month: 'mois',
			time: 'Heure',
			marital_status: 'Situation matrimoniale',
			child_number: "Nombre d'enfant",
			alphabetical: 'Alphabétique',
			description: 'Description',
			logout_warning:
				'Vous êtes sur le point de vous déconnecter. Voulez-vous continuer ?',
			logout_ok: "OUI, j'en suis sûr",
			logout_cancel: "NON, j'abandonne",
		},

		sidebar: {
			home: 'Accueil',
			dashboard: 'Tableau de bord',
			courriels: 'Courriers',
			ressource_management: 'Gestion des Ressources Humaines',
			ressource_management___: 'Gestion des Res..',
			documents: 'Documents',
			candidate: 'Candidature',
			tools: 'Outils',
			program_managements: 'Programme de Gestion intégré',
			program_managements___: 'Programme de Ges...',
		},

		subscription: {
			total_spent:
				"Le total de vos dépenses dans l'abonnement sur notre plateforme est",
			suscribe_plan: "Plan d'abonnement",
		},

		home: {
			courriels: 'Courriels',
			courriel_action:
				"Rédiger un nouveau message pour informer vos contacts d'une mise à jour.",
			courriel_new: 'Nouveau message',
			courriel_receive: 'Message reçus',
			courriel_send: 'Message envoyés',
			human_ressource_management: 'Gestion des Ressources Humaines',
			employee_list: 'Voir liste des employés',
			see_tranings: 'Voir les formations',
			add_employee: 'Ajouter un employé',
			add_training: 'Ajouter une formation',
			documents: 'Documents',
			document_action:
				"Exploiter vos fichiers n'importe où et/ou quand depuis votre espace professionnel.",
			import_file: 'Importer un fichier',
			consult_library: 'Consulter bibliothèque',
			see_favorites: 'Voir les favoris',
			candidate: 'Candidature',
			offer_list: 'Voir listes des offres',
			candidat_list: 'Voir liste des candidats',
			candidate_test_action:
				'Lancer un test pour permettre à vos candidats de composer directement en ligne.',
			candidat_test: 'Lancer un test brainstorming',
			tools: 'Outils',
			go_to_calendar: 'Aller au calendrier',
			add_task: 'Ajouter une Tâche',
			offer_action:
				'Sachez qui et qui se sont inscrits à vos offres d’emploi à travers vos formulaires.',
			offer_forms: 'Voir les formulaires',
			integreted_management_program: 'Programme de Gestion Intégrée',
			consult_sell: 'Consulter les ventes',
			view_purchases: 'Consulter les achats',
			issue_invoice: 'Délivrer une facture',
			save_invoice: 'Enregistrer une facture',
			first_title:
				'Certains éléments ne peuvent pas être traduits en graphique.',
			second_title:
				'Vous pouvez si possible patientez 24 heures pour voir actualiser votre tableau de bord.',
			post_offer: 'Publier une offre',
		},

		assistance: {
			new_ticket: 'Nouveau ticket',
			create_new_ticket: 'Créer un nouveau Ticket',
			subject: 'Sujet',
			object: 'Objet',
			message:
				"Un ticket est déjà en cours pour un problème et va prochainement être répondu par notre service technique. Si votre demande concerne le même problème, nous vous invitons à ne pas ouvrir un nouveau ticket. <br> Cette ouverture peut entrainer l'action de plusieurs techniciens simultanément qui pourrait compliquer votre problème. <br>",
			message_not_send:
				'Nous vous invitons à patienter pour obtenir la réponse du premier technicien.',
		},

		dashboard: {
			express_management: 'Gestion Expresse',
			at_post: 'Au poste',
			wage_margin: 'Marge salariale',
			training: 'Formation',
			nature: 'Nature',
			document: 'Document',
			library: 'Bibliothèque',
			job_offer: "Offres d'emplois",
			type: 'Type',
			candidat_profils: 'Profils candidats',
			sales: 'Ventes',
			buyings: 'Achats',
			amounts: 'Montants',
		},

		setting: {
			logo: "Logo de l'entreprise",
			update_your_name: 'Modifier votre nom',
			close: 'Fermer',
			company_name: "Nom de l'entreprise",
			legal_status: 'Statut Juridique',
			phone: 'Téléphone',
			center_of_interest: "Centre d'intérêts",
			main_activity: 'Activité principale',
			secondary_activity: 'Activité secondaire',
			address: 'Adresse',
			double_factor: 'Two-factor authentication',
			password_current: 'Mot de passe actuel',
			new_password: 'Nouveau mot de passe',
			confirm_password: 'Confirmer mot de passe',
			delete_account_permanently: 'Supprimer definitivement votre compte',
			delete_account: 'Supprimer votre compte',
			download_piece: 'Télécharger de la pièce',
			identity: 'Nº de la pièce',
			identity_type: "Pièce d'identité",
			ifu: 'Identité Fiscale Unique',
			download_current: "Télécharger l'actuel",
			user_society: 'Utilisateur Société',

			//when new translate satrt
			verification_code: 'Code de vérificaion',
			verify: 'Vérifier',
			update_your_infos: 'Modifier vos infos',
			your_gender: 'Genre',
			birthday: 'Date de naissance',
			birth_place: 'Lieu de naissance',
			account_id: 'ID du compte',
			desactivate_account_until: "Desactiver votre compte jusqu'au",
			desactivate: 'Desactiver',
			only_admin:
				"Seul l'administrateur principal peut avoir accès aux documents",
			password_isnot_same: 'Les deux mots de passe ne sont pas identiques',
			account_delete_infos:
				'La suppression de votre compte est irréversible. Les données suivantes seront supprimées',
			contact_info: 'vos informations de contact',
			financial_doc: 'vos documents financiers',
			pay_slips: 'vos fiches de paie',
			saved_tasks: 'vos tâches enregistrées',
			registered_applications: 'vos candidatures enregistrées',
			make_sure:
				"Assurez-vous de disposer d'une copie de ces données avant de supprimer définitivement votre compte",
			you_want_continue:
				"Si vous souhaitez continuer le process de suppression de votre compte, cochez toutes les cases ci-dessous puis cliquez sur 'Oui, Continuer'",
			confirmation1:
				'Je comprends que la suppression de mon compte est définitive et irréversible',
			confirmation2:
				'Je comprends que la totalité de mes données seront supprimées et que je n’aurai aucune possibilité de les récupérer',
			confirmation3:
				"Je comprends que si je supprime mon compte, je perdrai l'accès à toutes les fonctionnalités de la plateforme",
			would_you_like_continue: 'Souhaitez vous continuer le processus ?',
			yes_continue: 'Oui, Continuer',
			confirm_account_delete:
				"Si vous souhaitez confirmer la suppression de votre compte, entrez votre mot de passe puis cliquez sur 'Oui, Supprimer'",
			yes_delete: 'Oui, Supprimer',
			you_must:
				'Vous devez cocher toutes les cases avant de pouvoir procéder à la suppression de votre compte',
			// end
		},

		candidate: {
			candidate: 'Candidat',
			offers: {
				job_offers: "Offres d'emploi",
				employee_profile: 'Profils candidats',
				test: 'Test brainstorming',
				accounting_assistant: 'Assistant comptable',
				fenced: 'Clôturé',
				pending: 'En cours',
				post_offer: 'Publier une offre',
				in_news: 'A la une',
				file_name: 'Nom du fichier',
				brainstorming_advice:
					'Un profil à jour inspire beaucoup confiance et vous mets en sécurité. <br>' +
					'Assurez-vous d’avoir ajouté votre photo de profil, vos documents, un coadministrateur.',
				new_offer: 'Nouvelle offre',
				post_new_offer: "Publier une offre d'emploi",
				profession: 'Métier / Poste',
				position: 'Poste',
				profile: 'Profil',
				work_location: 'Lieu de travail',
				contact: 'Contact',
				contract_type: 'Type de contrat',
				available_place: 'Place disponible',
				place_number: 'Nombre de place',
				closing_date: 'Date de clôture',
				task_to_complete: 'Tâches à réaliser',
				required_skills: 'Compétences requises',
				job_summary: 'Résumé du poste',
				required_docs: 'Documents exigés',
				abilities: 'Aptitudes',
				update_offer: "Modifier l'offre",
				offer_no_found: "Nous n'arrivons pas à trouver cette offre",
				canceled: 'Annulée',
				suspended: 'Suspendue',
				index_advice:
					'Recruter directement depuis votre espace et sélectionnez les' +
					'meilleurs profils disponibles.<br /> Un bon candidat est synonyme d’un bon rendement.',
				post_offer_now: 'Publier une offre maintenant',
				today_summary: "Résumé d'aujourd'hui",
				offer_nomber: "Nombre d'offre",
				offer_detail: "Détail de l'offre",
				accepted_position: 'Poste retenus',
				intern: 'Stagiaire',
				offers_candidate: "Candidatures de l'offre",
				candidacy_deletion_warning:
					'Etes-vous sûr de vouloir supprimer les candidatures' +
					' sélectionnées ? <br />Avant de confirmer cette opération, notez que la suppression' +
					' est irréversible.',
				green_talents: 'Talents verts',
				offer_applicant_advice:
					'Un profil à jour inspire beaucoup confiance et vous mets en' +
					'sécurité. <br />Assurez-vous d’avoir ajouté votre photo de profil, vos documents, un' +
					'coadministrateur.',
				quiz_deletion_text:
					'Etes-vous sûr de vouloir supprimer ce questionnaire ? <br />' +
					'Avant de confirmer cette opération, notez que la suppression' +
					'de questionnaire est irréversible.',
				edit_quiz: 'Editer la question',
				of_quiz: 'du questionnaire',
				add_quiz: 'Ajouter une question au questionnaire',
				question_wording: 'Libellé de la question',
				points_awarded: 'Nombre de points octroyés',
				point_number: 'Nombre de points',
				awarding_method: "Le mode d'attribution des points pour cette question",
				suggested_response: 'Proposition de réponse',
				is_correct_proposiion: 'Cette proposition est-elle correcte ?',
				delete_proposition: 'Supprimer la proposition de réponse',
				add_suggestion: 'Ajouter une proposition de réponse',
				add_question: 'Ajouter la question',
				quiz_overview: 'Aperçu du Questionnaire',
				points_can_awarded: 'Total de points attribuables',
				all_points_awarded: 'Total de points attribués',
				all_points: 'Total de points',
				remaining: 'restants',
				rating: 'notation',
				save_quiz: 'Enregister le questionnaire',
				questionnaire: 'Questionnaire',
				tips: 'Astuces',
				points_awarded_method: "Mode d'attribution des points",
				tips_strict:
					"le candidat obtient les points uniquement s'il choisi toutes et uniquement les bonnes réponses.",
				tips_flexible:
					'le candidat obtient un nombre de points proportionnel au nombre de bonnes réponses' +
					'choisi. Toute mauvaise réponse choisie, lui fait perdre automatiquement les points.',
				question_removed: 'Question retirée du questionnaire avec succès',
				can_edit_question: 'Vous pouvez éditer la question',
				question: 'Question',
				edit_success: 'éditée avec succès',
				question_added_successfully:
					'Question ajoutée au questionnaire avec succès',
				cannot_be_worth_more: 'Cette question ne peut pas valoir plus de',
				required_question_notif:
					"Veuillez renseigner la question et vous assurer qu'au moins une bonne réponse figure parmi les réponses proposées.",
				offer_deletion_warning:
					'Etes-vous sûr de vouloir supprimer cette offre ? <br />' +
					' Avant de confirmer cette opération, notez que la suppression est irréversible.',
					warning_text: 'Etes-vous sûr de vouloir supprimer cet élément ? <br />' +
				'Avant de confirmer cette opération, sachez que la suppression' +
				'est irréversible.',
				characters: 'Caractères',
				execution_place: "Lieu d'exécution",
				apply_link: 'Lien pour postuler',
				execution: 'Exécution',
				information: 'Renseignements',
				candidate_profile: 'Profil candidat',
			},
			profile: {
				index_advice_text:
					'Recruter directement depuis votre espace et sélectionnez les' +
					'meilleurs profils disponibles.<br /> Un bon candidat est synonyme d’un bon rendement.',
				post_offer_now: 'Publier une offre maintenant',
				candidacy_infos:
					'Ce formulaire regroupe les informations renseignées par le candidat',
				candidacy_denied: 'Candidature refusée',
				candidacy_pending: 'Candidature en cours',
				candidacy_accepted: 'Candidature acceptée',
				general_infos: 'Informations générales',
				contact_residence: 'Contacts + Résidences',
				marital_status: 'Situation matrimoniale',
				skills_training: 'Formations, Diplômes et Expériences',
				last_diploma: 'Dernier diplôme obtenu',
				graduation_year: "Année d'obtention",
				training_area: 'Domaine de formation',
				tech_and_language: 'Informatique et Langues',
				language: 'Langue',
				read: 'Lecture',
				write: 'Ecrit',
				software: 'Logiciel',
				level: 'Niveau',
				download_doc: 'Documents téléchargés',
				idcard: "Carte nationale d'identité",
				professional_photo: 'Photo professionnelle',
				resume: 'Curriculum Vitae',
				other_docs: 'Autres documents',
				test_result: 'Résultat du Test',
				candidate_grade: 'Ce candidat a obtenu la note de',
				after_test: 'après le test',
				no_test: 'Pas de test',
				candidacy_status: 'Statut de la candidature',
			},
		},

		auth: {
			fp_first_title:
				"Oh, que c'est effrayant de savoir qu'on a perdu son identifiant de connexion. Mais pas de soucis, nous allons vous aider à récupérer votre mot de passe en seulement quelques clics.",
			fp_second_title:
				"Suivez simplement le processus et nous vous enverrons un code de vérification d'identité afin de vous aider à réinitialiser votre mot de passe.",
			request_new_pwd: 'Demander un nouveau mot de passe',
			inter_ifu:
				'Renseigner votre N° IFU ou votre e-mail pour demander un nouveau mot de passe.',
			verification_code: 'Code de vérification',
			verify_code: 'Vérifier le code',
			create_new_pwd: 'Créer un nouveau mot de passe',
			new_pwd: 'Nouveau mot de passe',
			conf_new_pwd: 'Confirmer mot de passe',
			to_login: 'Se connecter',
			login_happy: "Heureux de savoir que vous revenez",
			login_manage: "Gérez plus efficacement votre Business maintenant",


			invite_first_title:
				'Vous êtes invité à rejoindre la liste des co-administrateurs sur la plateforme Pro Gestion Soft.',
			invite_second_title:
				'Pour accepter cette invitation, définissez un mot de passe pour accéder à votre compte. Merci !',
			define_pwd: 'Définir un mot de passe pour activer votre compte.',
			pwd: 'Mot de passe',
			conf_pwd: 'Confirmer mot de passe',
			invitation_link: "Le lien d'invitation est invalide.",

			coming_back: 'VOUS Y ÊTES PRESQUE !',
			manag_effectively: 'VOTRE ADRESSE E-MAIL ',
			your_business: ' A ETE VERIFIEE',
			now: '',
			fogort_pwd: 'Mot de passe oublié ?',
			are_you_new: 'Nouveau ?',
			signup: "S'inscrire",

			new_signup: 'Nouvelle inscription !',
			we_need_you: 'Tellement nous avons hâte que vous nous rejoignez !',
			almost_there: 'Vous y êtes presque !',
			valid_email: 'Validez votre adresse e-mail pour continuer !',
			email_is_verify: 'Votre adresse e-mail a été vérifiée !',

			ifu_number: 'Numéro IFU',
			social_reason: 'Raison sociale',
			legal_status: 'Statut juriqique',
			verify_email: 'Vérifier votre e-mail',
			when_connect: 'En vous inscrivant, vous acceptez nos',
			politicy: 'politiques de règlementation',
			our: 'nos',
			conditions: 'conditions d’utilisation',
			and_our: 'et notre',
			confidenciality: 'déclaration de confidentialité',
		},

		documents: {
			library: 'Bibliothèque',
			favorites: 'Favoris',
			add_favorites: 'Ajouter aux favoris',
			remove_favorites: 'Retirer des favoris',
			archives: 'Archives',
			doc_deletion_text:
				'Etes-vous sûr de vouloir supprimer ce(s) fichier(s) ? <br />' +
				'Avant de confirmer cette opération, notez que la suppression ' +
				'de fichier est irréversible.',
			doc_restoration_text:
				'Etes-vous sûr de vouloir restaurer ce(s) fichier(s) ? <br />' +
				'Notez que cette action supprimera le(s) fichier(s) de la boîte aux archives.',
			import_file: 'Importer un fichier',
			doc_archives_advice:
				'Le saviez-vous, des documents épinglés sont vites accessibles et' +
				'retiennent vite l’attention. <br /> Importés vos courriers et épinglé les pour un meilleur traitement.',
			active_files: 'DOSSIERS ACTIFS',
			new_document: 'Nouveau document',
			file_import: 'Importation de fichier',
			imported: 'Importés',
			file: 'Fichier',
			folder: 'Dossier',
			select_file: 'Selectionner le dossier',
			drag_and_drop: 'Glisser et deposer vos fichiers ici.',
			browse: 'Parcourir',
			doc_archiving_text:
				'Etes-vous sûr de vouloir archiver ceci ? <br />' +
				'Notez que cette action déplacera le message dans la boîte aux ' +
				'archives. Mais toutes personnes ayant accès à ce compte peut ' +
				'toujours le consulter.',
			favorites_advice_text:
				'Le saviez-vous, des documents épinglés sont vites accessibles et ' +
				'retiennent vite l’attention. <br />' +
				'Importés vos courriers et épinglé les pour un meilleur traitement.',
			file_deletion_text:
				'Etes-vous sûr de vouloir supprimer ce(s) fichier(s) ? <br />' +
				'Avant de confirmer cette opération, notez que la suppression ' +
				'de fichier est irréversible.',
			doc_index_advice_text:
				'Un document épinglé est toujours mieux classé. <br />' +
				'Retrouvez plus vite vos documents en les favorisant. ',
			access_now: 'Accéder maintenant',
			file_property: 'Propriété du fichier',
			file_no_found: "Nous n'arrivons pas à trouver cet document",
		},

		mails: {
			visualize_mail: 'Visualiser un courrier',
			all_lettters: 'Tous les mails',
			treat_lettters: 'Mails traités',
			not_treat_lettters: 'Mails non traités',
			mail_sent: 'Courriers envoyés',
			archived_letters: 'Courriers archivés',
			mail_proccessing: 'Traitement du courrier',
			attach: 'Pièce à joindre',
			most_urgent: 'Les plus urgents',
			save_mail: 'Enregistrer un courrier',
			to_treat: 'Traiter',
			issuer: 'Emetteur',
			institution: 'Institution',
			fullname: 'Nom complet',
			description: 'Description',
			attach_file: 'Joindre un fichier(Facultatif)',
			priority: 'Priorité',
			processing_steps: 'Etapes de traitement',
			step: 'Etape',
			assigned_to: 'Assigné à',
			instructions: 'Instructions',
			view_mail: 'Visualiser un courrier',
			hide_list: 'Masquer liste des traitements',
			show_list: 'Voir liste des traitements',
			total: "Couriel total",
			total_pendings: "En attente",
			total_processed: "Traité",
			low_priority: "Priorité élévé",
			medium_priority: "Priorité moyenne",
			high_priority: "Priorité Forte",
		},

		others: {
			assistance_advice_text:
				'Un profil à jour inspire beaucoup confiance et vous mets en sécurité. <br />' +
				'Assurez-vous d’avoir ajouté votre photo de profil, vos documents, un coadministrateur.',
			mark_as_read: 'Marquer comme resolu',
			resolved_subject: 'Sujet resolu',
			ticket: 'Ticket',
			write_by: 'Ecrit par',
			ask_on: 'Question posée le',
			download_file_number: 'Télécharger le fichier numero',
			response_to_ticket: 'Réponses au Ticket',
			technician: 'Technicien',
			answer: 'Réponse',
			the: 'le',
			ticket_no_found: "Nous n'arrivons pas à trouver ce ticket",
			the_responses: 'Les réponses',
			reply: 'Répondre',
			write_your_comment: 'Ecrivez votre commentaire',
		},

		programs: {
			amount_including_tax: 'Montant TTC',
			amount_excluding_tax: 'Montant HT',
			company: {
				company_detail: "Détail entreprise",
				label: {
					rccm: "RCCM",
					ifu: 'IFU',
					email: 'Email',
					contact: 'Contact',
					name: "Entreprise"
				},
				data: {
					rccm: "1234567899874",
					ifu: '789456123987',
					email: "email",
					contact: '+229 98587854',
					name: "Quick and Fast Life"
				}
			},
			buying: {
				buy: 'Achat',
				invoice: 'Facture',
				supplier: 'Fournisseur',
				mode: 'Mode',
				archive_advice_text:
					'Voulez-vous plus de contrôle sur vos documents.<br />' +
					'Vous pouvez les importer ils seront stockés et protégés en toute sécurité.',
				regist_invoice: 'Enregistrer une facture',
				of_invoice: 'de Facture',
				payment_method: 'Mode de Paiement',
				invoice_file: 'Fichier de la facture',
				create_advice_text:
					'Voulez-vous plus de contrôle sur vos documents.<br />' +
					'Vous pouvez les importer ils seront stockés et protégés en toute sécurité.',
				archive_warning_text:
					'Etes-vous sûr de vouloir archiver ceci ? <br />' +
					"Notez que cette action déplacera l'achat dans la boîte aux archives. Mais toutes " +
					'personnes ayant accès à ce compte peut toujours la consulter.',
				invoice_no_found: "Nous n'arrivons pas à trouver cette facture",
				client_detail: 'Detail Client',
				show_advice_text:
					'Une fois que votre facture validée, vous ne pouvez plus la modifier ou la supprimer.' +
					'<br /> Assurez-vous de renseigner les bonnes informations et de ne pas fait d’erreurs.',
			},
			saling: {
				sale: 'Vente',
				archive_warning_text:
					'Etes-vous sûr de vouloir archiver ceci ? <br />' +
					'Notez que cette action déplacera la vente dans la boîte aux archives. Mais toutes ' +
					'personnes ayant accès à ce compte peut toujours la consulter.',
				archive_advice_text:
					'Voulez-vous plus de contrôle sur vos documents.<br />' +
					'Vous pouvez les importer ils seront stockés et protégés en toute sécurité.',
				create_advice_text:
					'Une fois que votre facture validée, vous ne pouvez plus la modifier ' +
					'ou la supprimer.<br /> Assurez-vous de renseigner les bonnes informations et de ne pas fait d’erreurs.',
				add_item: 'Ajouter un article',
				invoice_to: 'Facturé à',
				issue_invoice: 'Délivrer une facture',
				unit_price: 'Prix unitaire',
				quantity: 'Quantité',
				item: 'Article',
				update_item: 'Modifier un article',
				index_advice_text:
					'Voulez-vous plus de contrôle sur vos documents. <br />' +
					'Vous pouvez les importer ils seront stockés et protégés en toute sécurité.',
				show_advice_text:
					'Voulez-vous plus de contrôle sur vos documents. <br />' +
					'Vous pouvez les importer ils seront stockés et protégés en toute sécurité.',
				certification: 'Certification',
				ventillation: 'Ventillation',
				retail_sales: 'Detail de vente',
				delivred_to: 'Livré à',
			},
		},

		suscribe: {
			features: 'FONCTIONNALITÉS',
			co_admin: 'Co-admistrateur',
			test_after_test: 'Test après test',
			brainstorming_test: 'Test Brainstorming',
			technical_assistance: 'Assistance technique',
			storage_espace: 'Espace de stockage',
			monthly_subscription: 'Abonnement mensuel',
			annual_subscription: 'Abonnement annuel',
			subscribe_monthly: 'Souscrire Mensuel',
			year: 'an',
			reduction: 'de réduction',
			subscribe_annualy: 'Souscrire Annuel',
			have_this_default: 'Vous avez ce abonnement par defaut',
			definition: 'DEFINITION',
			warning_text:
				'Vous avez un abonnement valide encours. Tout réabonnement avant expiration de celui-ci entrainera son annulation irréversiblement. Etes-vous sûr de vouloir continuer ?',
			yes_sure: "OUI, j'en suis sûr",
			no_give_up: "NON, j'abandonne",
			cant_suscribe:
				"Vous ne pouvez pas procéder à l'abonnement car vous n'êtes pas l'administarteur principal de ce compte",
			allright: "D'accord",
			subscription_advice_text:
				'Un profil à jour inspire beaucoup confiance et vous mets en sécurité. <br />' +
				'Assurez-vous d’avoir ajouté votre photo de profil, vos documents, un coadministrateur.',
		},

		tools: {
			diary: 'Agenda',
			training: 'Formations',
			deletion_warning_text: 'Etes-vous sûr de vouloir supprimer cette tâche ?',
			archiving_warning_text:
				'Etes-vous sûr de vouloir archiver ceci ? <br> ' +
				'Notez que cette action déplacera le message dans la boîte aux archives.' +
				'Mais toutes personnes ayant accès à ce compte peut toujours le consulter.',
			recommandations: 'Recommandations',
			tools_advice_text:
				'Plus vous avez d’activités, plus vous êtes plus productifs. <br>' +
				'Créer le plus d’événements possibles pour permettre un certain dynamisme dans votre structure.',
			add_task: 'Ajouter une tâche',
			add_training_now: 'Ajouter une formation maintenant',
			calendar: 'Calendrier',
			period: 'Période',
			search: 'Rechercher',
			free_day: 'Journée libre',
			no_task: 'Aucune tâche',
			new_task: 'Nouvelle Tâche',
			// start new translate
			edit_task: 'Modifier cette tâche',
			// end new translate
			add_new_task: 'Ajouter une nouvelle tâche',
			task_name: 'Nom de la tâche',
			task_code: 'Code de la tâche',
			add_participants: 'Ajouter des participants',
			other_participants: 'Autres participants',
			begining: 'Debut',
			nature: 'Nature',
			place: 'Lieu(Adresse ou lien)',
			description: 'Description',
			online: 'En ligne',
			in_person: 'En Présentiel',
		},

		resources: {
			fullname: 'Nom et Prénoms',
			employees: 'Employés',
			employee: 'Employé',
			sections: 'Sections',
			postes: 'Postes',
			poste: 'Poste',
			position_held: 'Poste occupé',
			contracts: 'Contrats',
			contract: 'Contrat',
			training: 'Formations',
			a_contract: 'un contrat',
			create: 'Créer',
			update: 'Modifier',
			contract_file: 'Fichier du contrat',
			start_date: 'Date debut',
			end_date: 'Date Fin',
			leave_blank: "Laisser vide si ce n'est pas defini",
			contract_type: 'Type de contrat',
			remuneration: 'Rémunération',
			add_contract: 'Ajouter un contrat',
			update_at: 'Modifié lé',
			no_contract_found: 'Aucun contrat trouvé',
			contract_advice_text:
				'Un profil à jour inspire beaucoup confiance et vous mets en sécurité. <br />' +
				'Assurez-vous d’avoir ajouté votre photo de profil, vos documents, un coadministrateur.',
			new_employee: 'Nouvel employé',
			add_new_employee: 'Ajouter un nouvel employé',
			registration_number: 'Nº matricule',
			grade: 'Grade',
			intry_into_service: 'Date de prise de service',
			date_first_payment: 'Date du premier paiement',
			star: 'Etoile',
			commitment: 'Engagement',
			qualities: 'Qualités',
			summary: 'Résumé',
			experience_years: "Années d'expérience",
			special_observations: 'Observations particulière',
			post_job_now: 'Publier une offre maintenant',
			employee_advice_text:
				'Recruter directement depuis votre espace et sélectionnez les ' +
				'meilleurs profils disponibles. <br /> Un bon candidat est synonyme d’un bon rendement.',
			edit_employee: 'Modifier un employé',
			employee_no_found: "Nous n'arrivons pas à trouver cet employé",
			edit_item_advice_text:
				'Un profil à jour inspire beaucoup confiance et vous mets en sécurité. <br />' +
				'Assurez-vous d’avoir ajouté votre photo de profil, vos documents, un coadministrateur.',
			performance: 'Performance',
			performances: 'Performances',
			pgs_rating: 'Note PGS',
			average_salary: 'Salaire Moyen',
			at_office: 'Au poste',
			congrat_msg: 'Envoyer une lettre de félicitation',
			employee_index_advice_text:
				'La performance de vos employés contribue au meilleur de votre ' +
				'entreprise. <br /> Continuez à les encourager pour garder le meilleur de leur performance.',
			no_employee_found: 'Aucun employé trouvé',
			salary_cost: 'Charge salariale',
			add_employee: 'Ajouter un employé',
			merit_table: 'Tableau de mérite',
			employees_deletion_warning:
				'Etes-vous sûr de vouloir supprimer ce(s) employé(s) ? <br />' +
				'Avant de confirmer cette opération, notez que la suppression est irréversible.',
			designation: 'Désignation',
			close_editing: "Fermer l'édition",
			edit_file: 'Editer la fiche',
			download_file: 'Télécharger la fiche',
			show_file: 'Visualiser la fiche',
			net_salary: 'Salaire Net',
			reminder: 'Rappel',
			deduction: 'Deduction',
			detention: 'Detention',
			gain: 'Gain',
			element: 'Element',
			code: 'Code',
			nature: 'Nature',
			payslip: 'Fiche de paie',
			cant_find_employee: "Nous n'arrivons pas à trouver cet employé",
			employee_infos: "Information de l'employé",
			with: 'avec',
			dependent_child: 'enfant(s) à charge',
			// eslint-disable-next-line no-dupe-keys
			update: 'Mettre à jour',
			simplified_view: 'Vue simplifiée',
		},

		training: {
			new_training: 'Nouvelle formation',
			add_new_training: 'Ajouter une nouvelle formation',
			training_name: 'Nom de la formation',
			sections: 'Sections',
			add_participants: 'Ajouter des participants',
			start: 'Debut',
			end: 'Fin',
			nature: 'Nature',
			place: 'Lieu(Adresse ou lien)',
			add_task_now: 'Ajouter une tâche maintenant',
			create_advice_text:
				'Vous pouvez aussi organiser des évènements avec des collaborateurs' +
				' externes.<br /> Ajouter une tâche pour ne pas en oublier plus tard.',
			update_training: 'Modifier formation',
			training_no_found: "Nous n'arrivons pas à trouver cette formation",
			edit_advice_text:
				'Un profil à jour inspire beaucoup confiance et vous mets en sécurité. <br>' +
				'Assurez-vous d’avoir ajouté votre photo de profil, vos documents, un coadministrateur.',
			online: 'En ligne',
			in_person: 'En Présentiel',
			training_deletion_text:
				'Etes-vous sûr de vouloir supprimer ces formations ? <br />' +
				'Avant de confirmer cette opération, notez que la suppression est irréversible.',
			recommendations: 'Recommandations',
			add_training: 'Ajouter une formation',
			alphabetical: 'Alphabétique',
			start_date: 'Date début',
			end_date: 'Date fin',
			event: 'Evènement',
			section: 'Section',
			place_or_link: 'Lieu/Lien',
			index_advice_text:
				'Plus vous avez d’activités, plus vous êtes plus productifs.<br />' +
				'Créer le plus d’événements possibles pour permettre un certain dynamisme dans votre structure.',
			post_job_now: 'Publier une formation maintenant',
			all_online: 'Total en ligne',
			total_present: 'Total Présent',
			total_suspended: 'Total Suspendu',
			total_training: 'Formations totales',
			identification_training: 'Identification Formation',
			participants: 'Participants',
			periods: 'Périodes',
			characters: 'Caractères',
			relevance: 'Pertinence',
			update: 'Mettre à jour',
			suspend: 'Suspendre',
			relaunch: 'Relancer',
			sections_concerned: 'Liste des sections concernées',
			no_sections_found: 'Aucune section trouvée',
			employees_concerned: 'Liste des employés concernés',
			fullname: 'Nom et Prénoms',
		},

		poste: {
			create_poste: 'Créer un poste',
			update_poste: 'Modifier un poste',
			job_title: 'Intitulé du poste',
			job_description: 'Description du poste',
			add_a_job: 'Ajouter un poste',
			update_the: 'Modifié le',
			designation: 'Désignation',
			no_job_found: 'Aucun poste trouvé',
			poste_advice_text:
				'Un profil à jour inspire beaucoup confiance et vous mets en sécurité. <br />' +
				'Assurez-vous d’avoir ajouté votre photo de profil, vos documents, un coadministrateur.',
			job_deletion_text:
				'Etes-vous sûr de vouloir supprimer ce poste ? <br />' +
				'Avant de confirmer cette opération, notez que la suppression est irréversible.',
			cant_find_job: "Nous n'arrivons pas à trouver le Poste",
			about_job: 'À propos du Poste',
			post_employees: 'Liste des employés du Poste',
			no_employee: 'Aucun employé',
			post_sections: 'Liste des sections du Poste',
			no_sections: 'Aucune section',
			show_advice_text:
				'Un profil à jour inspire beaucoup confiance et vous mets en sécurité. <br />' +
				'Assurez-vous d’avoir ajouté votre photo de profil, vos documents, un coadministrateur.',
		},

		section: {
			create_section: 'Créer une section',
			update_section: 'Modifier une section',
			section_name: 'Nom de la section',
			section_code: 'Code de la section',
			responsible: 'Responsable',
			delete_section: 'Supprimer une section',
			deletion_text:
				'Voulez-vous vraiment supprimé cette section ? La suppression est irréversible.',
			update_at: 'Modifier le',
			no_employee_find: 'Aucun employé trouvé',
			section_advice_text:
				'Un profil à jour inspire beaucoup confiance et vous mets en sécurité. <br />' +
				'Assurez-vous d’avoir ajouté votre photo de profil, vos documents, un coadministrateur.',
			section_cant_find: "Nous n'arrivons pas à trouver cette section",
			about_section: 'À propos de la section',
			section_manager: 'Responsable de la section',
			section_employees: 'Listes des employés de la section',
			section_postes: 'Listes des postes de la section',
			poste_no_find: 'Aucun poste trouvé',
			show_advice_text:
				'Un profil à jour inspire beaucoup confiance et vous mets en sécurité. <br />' +
				'Assurez-vous d’avoir ajouté votre photo de profil, vos documents, un coadministrateur.',
		},
	},

	en: {
		topbar: {
			about: 'About',
			legal_mention: 'Legal Notice',
			terms: 'Terms and Rules of Use',
			protection_policy: 'Data Protection Policies',
			assistance: 'Support Center',
			support_us: 'Support us',
			faq: 'FAQ',
			comments: 'Comments',
			set_language: 'Change the language',
			french: 'French',
			english: 'English',
			co_admin: 'Co-administrator',
			my_subscriptions: 'My subscriptions',
			subscription: 'Subscription',
			set_theme: 'Change the theme',
			light: 'Light',
			dark: 'Dark',
			develop: 'Develop',
			search_placeholder: 'What are you looking for ?',
		},
		admin: {
			invitations: 'Invitations',
			rightOf: 'Right Of',
			assign: 'Assign',
			myRights: 'My Rights',
			rightAssign: 'Assignment of rights',
			invite_to_admin: 'Invite a co-admin',
			reactivate: 'Reactivate',
			suspend: 'Suspend',
			expire_link_date: 'Invitation link expiration date',
			new_admin: 'New administrator',
		},
		global: {
			download: 'Download',
			myself: 'My self',
			address: 'Address',
			notification: 'Notification',
			contact: 'Contact',
			title: 'Title',
			active: 'Activate',
			content: 'Content',
			date: 'Date',
			expired: 'Expired',
			lastname: 'Last name',
			firstname: 'First name',
			email: 'Email',
			date_add: 'Date add',
			none_element: 'No element found',
			tel: 'Phone number',
			close: 'Close',
			send: 'Send',
			write_at: 'Write the',
			cancel: 'Cancel',
			advice: 'Advice',
			save: 'Save',
			company_name: 'Company name',
			advice_action:
				'An up-to-date profile inspires a lot of confidence and keeps you safe. <br> Make sure you have added your profile photo, your documents, a co-administrator.',
			status: 'Status',
			activate: 'Enable',
			in_progress: 'Pending',
			response_s: 'Answer(s)',
			response: 'Response',
			resolved: 'Resolved',
			insert_piece: 'Insert piece',
			sex: 'Sex',
			comments: 'Comments',
			edit_mode: 'Switch to edit mode',
			leave_edit_mode: 'Exit edit mode',
			not_right_description:
				'Sorry dear co-administrator, the access rights granted to you by your main administrator unfortunately do not allow you to access the requested resource or perform the desired action. However, you can contact the main administrator for a possible complaint.',
			back: 'Return',
			unauthorized: 'Unauthorized',
			general: 'Genaral',
			security: 'Security',
			ifu: 'Single Tax Identity',
			amount: 'Amount',
			valid: 'Valid',
			start_date: 'Start Date',
			end_date: 'End Date',
			type: 'Type',
			annual: 'Annual',
			monthly: 'Monthly',
			save_as_draft: 'Save as Draft',
			filter: 'Filter',
			folder: 'Folder',
			favorite: 'Favorite',
			post: 'Post',
			profile_search: 'Profile search',
			nomber: 'Number',
			fenced: 'Closed',
			details: 'Details',
			detail: 'Details',
			pending: 'Pending',
			order_of_addition: 'Order of addition',
			reference: 'Reference',
			object: 'Object',
			priority: 'Priority',
			no_data: 'No data found',
			deletion_warning: 'Deletion warning',
			warning_text:
				'Are you sure you want to delete this item? <br />' +
				'Before confirming this operation, be aware that deletion' +
				'is irreversible.',
			deleted_successfully: 'Successfully deleted',
			delete: 'Delete',
			order: 'Order',
			accepted: 'Accepted',
			signup_order: 'Signup order',
			note: 'Note',
			signup: 'Signup',
			male: 'Male',
			feminine: 'Feminine',
			denied: 'Denied',
			withdraw: 'Withdraw',
			edit: 'Edit',
			overview: 'Overview',
			view_list: "View List",
			hide_list: "Hide List",
			profile: 'Profile',
			state: 'State',
			dates: 'Dates',
			on_line: 'Created at',
			fence: 'Closing',
			update: 'Update',
			suspend: 'Suspend',
			burn_date: 'Date of birth',
			birth_place: 'Place of birth',
			nationality: 'Nationality',
			gender: 'Gender',
			phone: 'Phone',
			country: 'Country',
			department: 'Department',
			municipal: 'Municipal',
			city: 'City',
			next: 'Next',
			previous: 'Preview',
			preview: 'Preview',
			action: 'Action',
			validate: 'Validate',
			ask: 'Ask',
			accept: 'Accept',
			restoration_warning: 'Restoration warning',
			restorated_successfully: 'Successfully restored',
			restore: 'Restore',
			recents: 'Recents',
			size: 'Size',
			add_now: 'Add now',
			archiving_warning: 'Archiving warning',
			archiving_successfully: 'Successfully archived',
			archive: 'Archive',
			unarchive: 'Unarchive',
			sent_by: 'Sent by',
			add_the: 'Added on',
			drag_and_drop: 'Drag and drop your files here.',
			update_your_email: 'Update your email',
			new_email: 'New email',
			attention: 'Attention',
			number: 'Number',
			no_data_found: 'No data found',
			document: 'Document',
			add: 'Add',
			today_summary: "Today's summary",
			summary: "Summary",
			import_now: 'Import now',
			yes: 'Yes',
			no: 'No',
			monthl: 'Month',
			time: 'Time',
			marital_status: 'Marital status',
			child_number: 'Number of children',
			alphabetical: 'Alphabetical',
			description: 'Description',
			logout_warning: 'You are about to log out. Do you want to continue ?',
			logout_ok: "YES, I'm sure",
			logout_cancel: 'NO, I give up',
		},
		sidebar: {
			home: 'Home',
			dashboard: 'Dashboard',
			courriels: 'Mails',
			ressource_management: 'Ressource management',
			ressource_management___: 'Ressource mana...',
			documents: 'Documents',
			candidate: 'Candidate',
			tools: 'Tools',
			program_managements: 'Integrated management program',
			program_managements___: 'Integrated mana...',
		},
		subscription: {
			total_spent: 'Your total subscription spend on our platform is',
			suscribe_plan: 'Subscription plan',
		},
		home: {
			courriels: 'Mails',
			courriel_action:
				'Compose a new message to notify your contacts of an update.',
			courriel_new: 'New message',
			courriel_receive: 'Messages received',
			courriel_send: 'Messages sent',
			human_ressource_management: 'Human Resource Management',
			employee_list: 'See list of employees',
			see_tranings: 'See the trainings',
			add_employee: 'Add an employee',
			add_training: 'Add training',
			documents: 'Documents',
			document_action:
				'Use your files anywhere and/or when from your professional space.',
			import_file: 'Import a file',
			consult_library: 'Consult library',
			see_favorites: 'See favorites',
			candidate: 'Candidate',
			offer_list: 'See list of offers',
			candidat_list: 'See list of candidates',
			candidate_test_action:
				'Launch a test to allow your candidates to compose directly online.',
			candidat_test: 'Launch a brainstorming test',
			tools: 'Tools',
			go_to_calendar: 'Go to calendar',
			add_task: 'Add Task',
			offer_action:
				'Know who and who signed up for your job postings through your forms.',
			offer_forms: 'View Forms',
			integreted_management_program: 'Integrated Management Program',
			consult_sell: 'View Sales',
			view_purchases: 'View purchases',
			issue_invoice: 'Issue an invoice',
			save_invoice: 'Register an invoice',
			post_offer: 'Publish an offer',
		},
		assistance: {
			new_ticket: 'New ticket',
			create_new_ticket: 'Create a new Ticket',
			subject: 'Subject',
			object: 'Object',
			message:
				'A ticket is already in progress for a problem and will soon be answered by our technical department. If your request concerns the same problem, we invite you not to open a new ticket. <br> This opening can involve the action of several technicians simultaneously which could complicate your problem. <br>',
			message_not_send:
				'We invite you to wait for the response of the first technician.',
		},
		dashboard: {
			express_management: 'Express management',
			at_post: 'At office',
			wage_margin: 'Wage margin',
			training: 'Training',
			nature: 'Nature',
			document: 'Document',
			library: 'Library',
			job_offer: 'Job offer',
			type: 'Type',
			candidat_profils: 'Candidates profil',
			sales: 'Sales',
			buyings: 'Buyings',
			amounts: 'Amounts',
		},
		setting: {
			logo: 'Company logo',
			update_your_name: 'Update your name',
			close: 'Close',
			company_name: 'Company name',
			legal_status: 'Legal status',
			phone: 'Phone number',
			center_of_interest: 'Center of Interest',
			main_activity: 'Main activity',
			secondary_activity: 'Secondary activity',
			address: 'Address',
			account_id: 'Account ID',
			double_factor: 'Authentification à double facteurs',
			password_current: 'Current Password',
			new_password: 'New Password',
			confirm_password: 'Confirm Password',
			delete_account_permanently: 'Permanently delete your account',
			delete_account: 'Delete your account',
			download_piece: 'Download part',
			identity: 'Card ID',
			ifu: 'Single Tax Identity',
			identity_type: 'Identity card',
			download_current: 'Download the current',
			user_society: 'Company User',
			verification_code: 'Verification Code',
			verify: 'Verify',
			update_your_infos: 'Update Your Information',
			your_gender: 'Gender',
			birthday: 'Birthday',
			birth_place: 'Place of Birth',
			desactivate_account_until: 'Deactivate Your Account Until',
			desactivate: 'Deactivate',
			only_admin: 'Only the main administrator can access the documents',
			password_isnot_same: 'The two passwords are not identical',
			account_delete_infos:
				'Deleting your account is irreversible. The following data will be deleted',
			contact_info: 'your contact information',
			financial_doc: 'your financial documents',
			pay_slips: 'your pay slips',
			saved_tasks: 'your saved tasks',
			registered_applications: 'your registered applications',
			make_sure:
				'Make sure you have a copy of this data before permanently deleting your account',
			you_want_continue:
				"If you want to continue the account deletion process, check all the boxes below and then click 'Yes, Continue'",
			confirmation1:
				'I understand that deleting my account is permanent and irreversible',
			confirmation2:
				'I understand that all my data will be deleted and I will have no way to recover it',
			confirmation3:
				'I understand that if I delete my account, I will lose access to all platform functionalities',
			would_you_like_continue: 'Would you like to continue the process?',
			yes_continue: 'Yes, Continue',
			confirm_account_delete:
				"If you wish to confirm the deletion of your account, enter your password and then click 'Yes, Delete'",
			yes_delete: 'Yes, Delete',
			you_must:
				'You must check all the boxes before you can proceed with deleting your account',
		},
		candidate: {
			candidate: 'Candidate',
			offers: {
				job_offers: 'Job Offers',
				employee_profile: 'Candidate Profiles',
				test: 'Brainstorming Test',
				accounting_assistant: 'Accounting Assistant',
				fenced: 'Closed',
				pending: 'Pending',
				post_offer: 'Post an Offer',
				in_news: 'In the News',
				file_name: 'File Name',
				brainstorming_advice:
					'An up-to-date profile inspires a lot of confidence and keeps you secure. <br>' +
					'Make sure you have added your profile picture, your documents, and a co-administrator.',
				new_offer: 'New Offer',
				post_new_offer: 'Post a Job Offer',
				profession: 'Profession / Position',
				position: 'Position',
				profile: 'Profile',
				work_location: 'Work Location',
				contact: 'Contact',
				contract_type: 'Contract Type',
				available_place: 'Available Place',
				place_number: 'Number of Places',
				closing_date: 'Closing Date',
				task_to_complete: 'Tasks to Complete',
				required_skills: 'Required Skills',
				job_summary: 'Job Summary',
				required_docs: 'Required Documents',
				abilities: 'Abilities',
				update_offer: 'Update Offer',
				offer_no_found: "We couldn't find this offer",
				canceled: 'Canceled',
				suspended: 'Suspended',
				index_advice:
					'Recruit directly from your space and select the best available profiles.<br /> A good candidate equals good performance.',
				post_offer_now: 'Post an Offer Now',
				today_summary: "Today's Summary",
				offer_nomber: 'Number of Offers',
				offer_detail: "Offer detail",
				accepted_position: 'Accepted Positions',
				intern: 'Intern',
				offers_candidate: "Offer's Applications",
				candidacy_deletion_warning:
					'Are you sure you want to delete the selected applications? <br />Before confirming this operation, note that the deletion is irreversible.',
				green_talents: 'Green Talents',
				offer_applicant_advice:
					'An up-to-date profile inspires a lot of confidence and keeps you secure. <br />Make sure you have added your profile picture, your documents, and a co-administrator.',
				quiz_deletion_text:
					'Are you sure you want to delete this questionnaire? <br />Before confirming this operation, note that the deletion of the questionnaire is irreversible.',
				edit_quiz: 'Edit Question',
				of_quiz: 'of the Questionnaire',
				add_quiz: 'Add a Question to the Questionnaire',
				question_wording: 'Question Wording',
				points_awarded: 'Points Awarded',
				point_number: 'Point Number',
				awarding_method: 'Awarding Method',
				suggested_response: 'Suggested Response',
				is_correct_proposiion: 'Is this the correct response?',
				delete_proposition: 'Delete the response proposition',
				add_suggestion: 'Add a response proposition',
				add_question: 'Add the question',
				quiz_overview: 'Questionnaire Overview',
				points_can_awarded: 'Total Points Available',
				all_points_awarded: 'Total Points Awarded',
				all_points: 'Total Points',
				remaining: 'remaining',
				rating: 'rating',
				save_quiz: 'Save the Questionnaire',
				questionnaire: 'Questionnaire',
				tips: 'Tips',
				points_awarded_method: 'Points Awarding Method',
				tips_strict:
					'the candidate only gets points if he chooses all and only the correct answers.',
				tips_flexible:
					'the candidate gets a number of points proportional to the number of correct answers chosen. Any wrong answer chosen automatically makes him lose points.',
				question_removed:
					'Question successfully removed from the questionnaire',
				can_edit_question: 'You can edit the question',
				question: 'Question',
				edit_success: 'successfully edited',
				question_added_successfully:
					'Question successfully added to the questionnaire',
				cannot_be_worth_more: 'This question cannot be worth more than',
				required_question_notif:
					'Please fill in the question and ensure that at least one correct answer is among the proposed responses.',
				offer_deletion_warning:
					'Are you sure you want to delete this offer? <br />Before confirming this operation, note that the deletion is irreversible.',
					warning_text: 'Are you sure you want to delete this item? <br />' +
				'Before confirming this operation, be aware that deletion' +
				'is irreversible.',
				characters: 'Characters',
				execution_place: 'Execution Place',
				apply_link: 'Apply Link',
				execution: 'Execution',
				information: 'Information',
				candidate_profile: 'Candidate Profile',
			},
			profile: {
				index_advice_text:
					'Recruit directly from your space and select the best available profiles.<br /> A good candidate equals good performance.',
				post_offer_now: 'Post an Offer Now',
				candidacy_infos:
					'This form gathers the information provided by the candidate',
				candidacy_denied: 'Candidacy Denied',
				candidacy_pending: 'Candidacy Pending',
				candidacy_accepted: 'Candidacy Accepted',
				general_infos: 'General Information',
				contact_residence: 'Contacts + Residence',
				marital_status: 'Marital Status',
				skills_training: 'Skills, Degrees and Experiences',
				last_diploma: 'Last Diploma Obtained',
				graduation_year: 'Graduation Year',
				training_area: 'Training Area',
				tech_and_language: 'Computer Science and Languages',
				language: 'Language',
				read: 'Read',
				write: 'Write',
				software: 'Software',
				level: 'Level',
				download_doc: 'Downloaded Documents',
				idcard: 'ID Card',
				professional_photo: 'Professional Photo',
				resume: 'Resume',
				other_docs: 'Other Documents',
				test_result: 'Test Result',
				candidate_grade: 'This candidate scored',
				after_test: 'after the test',
				no_test: 'No Test',
				candidacy_status: 'Candidacy Status',
			},
		},

		auth: {
			fp_first_title:
				"Oh, it's scary to realize you've lost your login credentials. But don't worry, we'll help you recover your password in just a few clicks.",
			fp_second_title:
				"Simply follow the process and we'll send you an identity verification code to help you reset your password.",
			request_new_pwd: 'Request a new password',
			inter_ifu:
				'Enter your IFU number or your email to request a new password.',
			verification_code: 'Verification code',
			verify_code: 'Verify code',
			create_new_pwd: 'Create a new password',
			new_pwd: 'New password',
			conf_new_pwd: 'Confirm password',
			to_login: 'Login',

			invite_first_title:
				'You are invited to join the list of co-administrators on the Pro Gestion Soft platform.',
			invite_second_title:
				'To accept this invitation, set a password to access your account. Thank you!',
			define_pwd: 'Set a password to activate your account.',
			pwd: 'Password',
			conf_pwd: 'Confirm password',
			invitation_link: 'The invitation link is invalid.',

			coming_back: "Glad to see you're coming back",
			manag_effectively: 'MANAGE MORE EFFECTIVELY',
			your_business: 'YOUR BUSINESS',
			now: 'NOW',
			fogort_pwd: 'Forgot password?',
			are_you_new: 'New here?',
			signup: 'Sign up',
			login_happy: "Glad to know you're coming back",
			login_manage: "Manage your business more efficiently now",

			almost_there: "You're almost there!",
			valid_email: 'Validate your email address to continue!',
			email_is_verify: 'Your email address has been verified!',

			ifu_number: 'IFU Number',
			social_reason: 'Social Reason',
			legal_status: 'Legal Status',
			verify_email: 'Verify your email',
			when_connect: 'By signing up, you agree to our',
			politicy: 'regulatory policies',
			our: 'our',
			conditions: 'terms of use',
			and_our: 'and our',
			confidenciality: 'privacy statement',
		},

		documents: {
			library: 'Library',
			favorites: 'Favorites',
			add_favorites: 'Add to favoutites',
			remove_favorites: 'Remove from favourites',
			archives: 'Archives',
			doc_deletion_text:
				'Are you sure you want to delete this file(s)? <br />' +
				'Before confirming this operation, note that file deletion is irreversible.',
			doc_restoration_text:
				'Are you sure you want to restore this file(s)? <br />' +
				'Note that this action will remove the file(s) from the archives.',
			import_file: 'Import a file',
			doc_archives_advice:
				'Did you know, pinned documents are quickly accessible and ' +
				'quickly capture attention. <br /> Import your mails and pin them for better processing.',
			active_files: 'ACTIVE FOLDERS',
			new_document: 'New document',
			file_import: 'File import',
			imported: 'Imported',
			file: 'File',
			folder: 'Folder',
			select_file: 'Select the folder',
			drag_and_drop: 'Drag and drop your files here.',
			browse: 'Browse',
			doc_archiving_text:
				'Are you sure you want to archive this? <br />' +
				'Note that this action will move the message to the archives. But anyone with access to this account can still view it.',
			favorites_advice_text:
				'Did you know, pinned documents are quickly accessible and ' +
				'quickly capture attention. <br />' +
				'Import your mails and pin them for better processing.',
			file_deletion_text:
				'Are you sure you want to delete this file(s)? <br />' +
				'Before confirming this operation, note that file deletion is irreversible.',
			doc_index_advice_text:
				'A pinned document is always better ranked. <br />' +
				'Find your documents faster by favoriting them.',
			access_now: 'Access now',
			file_property: 'File properties',
			file_no_found: "We can't find this document",
		},

		mails: {
			visualize_mail: 'Visualize a mail',
			all_lettters: 'All mails',
			mail_sent: 'Sent mails',
			archived_letters: 'Archived mails',
			mail_processing: 'Mail processing',
			attach: 'Attachment',
			most_urgent: 'Most urgent',
			save_mail: 'Save a mail',
			to_treat: 'Process',
			issuer: 'Issuer',
			institution: 'Institution',
			fullname: 'Full name',
			description: 'Description',
			attach_file: 'Attach a file (Optional)',
			priority: 'Priority',
			processing_steps: 'Processing steps',
			step: 'Step',
			assigned_to: 'Assigned to',
			instructions: 'Instructions',
			view_mail: 'View a mail',
			hide_list: 'Hide processing list',
			show_list: 'Show processing list',
			total: "Couriel total",
			total_pendings: "Pending",
			total_processed: "Treaty",
			low_priority: "Priority high",
			medium_priority: "Medium priority",
			high_priority: "Priority high",
		},

		others: {
			assistance_advice_text:
				'An up-to-date profile inspires a lot of confidence and puts you in safety. <br />' +
				'Make sure you have added your profile picture, your documents, a co-administrator.',
			mark_as_read: 'Mark as resolved',
			resolved_subject: 'Resolved subject',
			ticket: 'Ticket',
			write_by: 'Written by',
			ask_on: 'Question asked on',
			download_file_number: 'Download file number',
			response_to_ticket: 'Responses to Ticket',
			technician: 'Technician',
			answer: 'Answer',
			the: 'the',
			ticket_no_found: "We can't find this ticket",
			the_responses: 'The responses',
			reply: 'Reply',
			write_your_comment: 'Write your comment',
		},

		programs: {
			amount_including_tax: 'Amount incl. tax',
			amount_excluding_tax: 'Amount excl. tax',
			company: {
				company_detail: "Company detail",
				label: {
					rccm: "RCCM",
					ifu: 'IFU',
					email: 'Email',
					contact: 'Contact',
					name: "Entreprise"
				},
				data: {
					rccm: "1234567899874",
					ifu: '789456123987',
					email: 'email',
					contact: '+229 98587854',
					name: "Quick Life"
				}
			},
			buying: {
				buy: 'Purchase',
				invoice: 'Invoice',
				supplier: 'Supplier',
				mode: 'Mode',
				archive_advice_text:
					'Do you want more control over your documents.<br />' +
					'You can import them, they will be stored and protected securely.',
				regist_invoice: 'Register an invoice',
				of_invoice: 'of Invoice',
				payment_method: 'Payment method',
				invoice_file: 'Invoice file',
				create_advice_text:
					'Do you want more control over your documents.<br />' +
					'You can import them, they will be stored and protected securely.',
				archive_warning_text:
					'Are you sure you want to archive this? <br />' +
					'Note that this action will move the purchase to the archive box. But anyone ' +
					'with access to this account can still view it.',
				invoice_no_found: "We can't find this invoice",
				client_detail: 'Client Detail',
				show_advice_text:
					'Once your invoice is validated, you cannot modify or delete it.' +
					'<br /> Make sure to provide the correct information and avoid mistakes.',
			},
			saling: {
				sale: 'Sale',
				archive_warning_text:
					'Are you sure you want to archive this? <br />' +
					'Note that this action will move the sale to the archive box. But anyone ' +
					'with access to this account can still view it.',
				archive_advice_text:
					'Do you want more control over your documents.<br />' +
					'You can import them, they will be stored and protected securely.',
				create_advice_text:
					'Once your invoice is validated, you cannot modify or delete it.' +
					'<br /> Make sure to provide the correct information and avoid mistakes.',
				add_item: 'Add an item',
				invoice_to: 'Invoiced to',
				issue_invoice: 'Issue an invoice',
				unit_price: 'Unit price',
				quantity: 'Quantity',
				item: 'Item',
				update_item: 'Update an item',
				index_advice_text:
					'Do you want more control over your documents. <br />' +
					'You can import them, they will be stored and protected securely.',
				show_advice_text:
					'Do you want more control over your documents. <br />' +
					'You can import them, they will be stored and protected securely.',
				certification: 'Certification',
				ventillation: 'Ventillation',
				retail_sales: 'Retail sales',
				delivred_to: 'Delivered to',
			},
		},

		suscribe: {
			features: 'FEATURES',
			co_admin: 'Co-administrator',
			test_after_test: 'Test after test',
			brainstorming_test: 'Brainstorming test',
			technical_assistance: 'Technical assistance',
			storage_espace: 'Storage space',
			monthly_subscription: 'Monthly subscription',
			annual_subscription: 'Annual subscription',
			subscribe_monthly: 'Subscribe Monthly',
			year: 'year',
			reduction: 'reduction',
			subscribe_annualy: 'Subscribe Annually',
			have_this_default: 'You have this default subscription',
			definition: 'DEFINITION',
			warning_text:
				'You have a valid subscription in progress. Any re-subscription before its expiration will result in its irreversible cancellation. Are you sure you want to continue?',
			yes_sure: "YES, I'm sure",
			no_give_up: 'NO, I give up',
			cant_suscribe:
				'You cannot proceed with the subscription because you are not the main administrator of this account',
			allright: 'All right',
			subscription_advice_text:
				'An up-to-date profile inspires a lot of confidence and puts you in safety. <br />' +
				'Make sure you have added your profile picture, your documents, a co-administrator.',
		},

		tools: {
			diary: 'Agenda',
			training: 'Training',
			deletion_warning_text: 'Are you sure you want to delete this task?',
			archiving_warning_text:
				'Are you sure you want to archive this? <br> ' +
				'Note that this action will move the message to the archive box.' +
				'But anyone with access to this account can still view it.',
			recommandations: 'Recommendations',
			tools_advice_text:
				'The more activities you have, the more productive you are. <br>' +
				'Create as many events as possible to allow for some dynamism in your structure.',
			add_task: 'Add a task',
			add_training_now: 'Add a training now',
			calendar: 'Calendar',
			period: 'Period',
			search: 'Search',
			free_day: 'Free day',
			no_task: 'No task',
			new_task: 'New Task',
			add_new_task: 'Add a new task',
			task_name: 'Task name',
			task_code: 'Task code',
			add_participants: 'Add participants',
			other_participants: 'Other participants',
			begining: 'Start',
			nature: 'Nature',
			place: 'Place(Address or link)',
			description: 'Description',
			online: 'Online',
			in_person: 'In person',
		},

		resources: {
			fullname: 'Full name',
			employees: 'Employees',
			employee: 'Employee',
			sections: 'Sections',
			postes: 'Positions',
			poste: 'Position',
			position_held: 'Position held',
			contracts: 'Contracts',
			contract: 'Contract',
			training: 'Trainings',
			a_contract: 'a contract',
			create: 'Create',
			update: 'Update',
			contract_file: 'Contract file',
			start_date: 'Start date',
			end_date: 'End date',
			leave_blank: 'Leave blank if not defined',
			contract_type: 'Contract type',
			remuneration: 'Remuneration',
			add_contract: 'Add a contract',
			update_at: 'Updated on',
			no_contract_found: 'No contract found',
			contract_advice_text:
				'An up-to-date profile inspires a lot of confidence and puts you in safety. <br />' +
				'Make sure you have added your profile picture, your documents, a co-administrator.',
			new_employee: 'New employee',
			add_new_employee: 'Add a new employee',
			registration_number: 'Registration number',
			grade: 'Grade',
			intry_into_service: 'Date of entry into service',
			date_first_payment: 'Date of first payment',
			star: 'Star',
			commitment: 'Commitment',
			qualities: 'Qualities',
			summary: 'Summary',
			experience_years: 'Years of experience',
			special_observations: 'Special observations',
			post_job_now: 'Post a job now',
			employee_advice_text:
				'Recruit directly from your space and select the best available profiles. <br />' +
				'A good candidate means good performance.',
			edit_employee: 'Edit an employee',
			employee_no_found: "We can't find this employee",
			edit_item_advice_text:
				'An up-to-date profile inspires a lot of confidence and puts you in safety. <br />' +
				'Make sure you have added your profile picture, your documents, a co-administrator.',
			performance: 'Performance',
			performances: 'Performances',
			pgs_rating: 'PGS rating',
			average_salary: 'Average salary',
			at_office: 'At the position',
			congrat_msg: 'Send a congratulation letter',
			employee_index_advice_text:
				'The performance of your employees contributes to the best of your company. <br />' +
				'Continue to encourage them to maintain the best of their performance.',
			no_employee_found: 'No employee found',
			salary_cost: 'Salary cost',
			add_employee: 'Add an employee',
			merit_table: 'Merit table',
			employees_deletion_warning:
				'Are you sure you want to delete this/these employee(s)? <br />' +
				'Before confirming this operation, note that deletion is irreversible.',
			designation: 'Designation',
			close_editing: 'Close editing',
			edit_file: 'Edit the file',
			download_file: 'Download the file',
			show_file: 'Show the file',
			net_salary: 'Net salary',
			reminder: 'Reminder',
			deduction: 'Deduction',
			detention: 'Detention',
			gain: 'Gain',
			element: 'Element',
			code: 'Code',
			nature: 'Nature',
			payslip: 'Payslip',
			cant_find_employee: "We can't find this employee",
			employee_infos: 'Employee information',
			with: 'with',
			dependent_child: 'dependent child(ren)',
			simplified_view: 'Simplified view',


		},

		training: {
			new_training: 'New training',
			add_new_training: 'Add a new training',
			training_name: 'Training name',
			sections: 'Sections',
			add_participants: 'Add participants',
			start: 'Start',
			end: 'End',
			nature: 'Nature',
			place: 'Place(Address or link)',
			add_task_now: 'Add a task now',
			create_advice_text:
				'You can also organize events with external collaborators' +
				".<br /> Add a task so you don't forget later.",
			update_training: 'Update training',
			training_no_found: "We can't find this training",
			edit_advice_text:
				'An up-to-date profile inspires a lot of confidence and puts you in safety. <br>' +
				'Make sure you have added your profile picture, your documents, a co-administrator.',
			online: 'Online',
			in_person: 'In person',
			training_deletion_text:
				'Are you sure you want to delete these trainings? <br />' +
				'Before confirming this operation, note that deletion is irreversible.',
			recommendations: 'Recommendations',
			add_training: 'Add training',
			alphabetical: 'Alphabetical',
			start_date: 'Start date',
			end_date: 'End date',
			event: 'Event',
			section: 'Section',
			place_or_link: 'Place/Link',
			index_advice_text:
				'They are more productive when their tasks are very specific.<br />' +
				'Hire more staff and assign specific tasks to each one.',
			post_job_now: 'Post a training now',
			all_online: 'Total online',
			total_present: 'Total Present',
			total_suspended: 'Total Suspended',
			total_training: 'Total trainings',
			identification_training: 'Training Identification',
			participants: 'Participants',
			periods: 'Periods',
			characters: 'Characters',
			relevance: 'Relevance',
			update: 'Update',
			suspend: 'Suspend',
			relaunch: 'Relaunch',
			sections_concerned: 'List of concerned sections',
			no_sections_found: 'No sections found',
			employees_concerned: 'List of concerned employees',
			fullname: 'Full name',
		},

		poste: {
			create_poste: 'Create a position',
			update_poste: 'Update a position',
			job_title: 'Job title',
			job_description: 'Job description',
			add_a_job: 'Add a position',
			update_the: 'Updated on',
			designation: 'Designation',
			no_job_found: 'No position found',
			poste_advice_text:
				'An up-to-date profile inspires a lot of confidence and puts you in safety. <br />' +
				'Make sure you have added your profile picture, your documents, a co-administrator.',
			job_deletion_text:
				'Are you sure you want to delete this position? <br />' +
				'Before confirming this operation, note that deletion is irreversible.',
			cant_find_job: "We can't find the position",
			about_job: 'About the position',
			post_employees: 'List of employees in the position',
			no_employee: 'No employees',
			post_sections: 'List of sections in the position',
			no_sections: 'No sections',
			show_advice_text:
				'An up-to-date profile inspires a lot of confidence and puts you in safety. <br />' +
				'Make sure you have added your profile picture, your documents, a co-administrator.',
		},

		section: {
			create_section: 'Create a section',
			update_section: 'Update a section',
			section_name: 'Section name',
			section_code: 'Section code',
			responsible: 'Responsible',
			delete_section: 'Delete a section',
			deletion_text:
				'Do you really want to delete this section? Deletion is irreversible.',
			update_at: 'Update at',
			no_employee_find: 'No employee found',
			section_advice_text:
				'An up-to-date profile inspires a lot of confidence and puts you in safety. <br />' +
				'Make sure you have added your profile picture, your documents, a co-administrator.',
			section_cant_find: "We can't find this section",
			about_section: 'About the section',
			section_manager: 'Section manager',
			section_employees: 'List of employees in the section',
			section_postes: 'List of positions in the section',
			poste_no_find: 'No position found',
			show_advice_text:
				'An up-to-date profile inspires a lot of confidence and puts you in safety. <br />' +
				'Make sure you have added your profile picture, your documents, a co-administrator.',
		},
	},
};

export default messages;
