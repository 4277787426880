<template>
    <TaskPopup v-show="showPopup" :tasks="allTask" :popupDate="taskData" @close-modal="showPopup = false"></TaskPopup>

    <div v-if="allTask.length !== 0" class="hstack cursor-pointer mb-3" type="button" @click="showPopup = true">
        <div
            class="bg-alt- me-2"
            style="width: 10px; height: 30px !important"
        ></div>
        <div>
            <div class="hstack w-100 fw-500 text-6">
                <div class="me-1">Tâches à faire: {{ allTask.length }}</div>
            </div>
        </div>
    </div>
    
  </template>
  
  <script>
  import Helper from '@/helpers/helper';
  import TaskPopup from '@/components/TaskPopup.vue'
  export default {
    name: "TaskTag",
    components: {TaskPopup},
    props: {
      taskData: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        allTask: [],
        showPopup: false,
      };
	},

    methods:{
        showOneFormation(showPopup){
            this.showPopup = !showPopup;
        },

        formatDate(dateString) {
          const date = new Date(dateString);
          return date.toISOString().split('T')[0];
        },

        loadDayTask(date) {
          const formattedDate = date//this.formatDate(date);
          this.loading = true;
          fetch(Helper.route('admin/task/all?date='+formattedDate), Helper.requestOptions())
            .then((response) => response.json())
            .then((data) => {
              this.loading = false;
              
              if (data.data) {
                this.allTask = data.data;
                console.log('day Task List:', date + " ********** "+ formattedDate, data.data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        },
      },

    created() {      
      this.loadDayTask(this.taskData);
    }
  }
  </script>
