<template>
	<div>
		<Template title="null" sidebarMenu="program-managements">
			<section class="row px-4 m-0 g-4 mt-1">
				<Back />

				<div class="col-xl-9">
					<div
						class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 p-1"
					>
						{{$t('global.preview')}}
					</div>
					<LoadingMain :state="loading" />
					<div
						v-if="buying == null && !loading"
						class="p-5 text-red- text-5 text-center"
					>
						{{$t('programs.buying.invoice_no_found')}}
					</div>
					<div class="row mt-1 g-4" v-if="buying !== null">
						<div class="col-xl-12">
							<div class="border">
								<div
									class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
								>
									{{$t('programs.buying.client_detail')}}
								</div>
								<div
									class="text-center pt-3 text-1 text-alt- text-uppercase fw-500"
								>
									{{ buying.supplier }}
								</div>
								<div class="row mt-3 px-3">
									<div class="col-lg-6">
										<h6 class="text-black-">{{$t('programs.buying.invoice')}} N° :</h6>
										<p class="mt-2">{{ buying.number }}</p>
									</div>
									<div class="col-lg-6">
										<h6 class="text-black-">{{$t('global.number')}} :</h6>
										<p class="mt-2">
											{{ $filters.convertDate(buying.date) }}
										</p>
									</div>
								</div>

								<div
									class="text-red- mt-3 mb-4 text-uppercase text-5 bg-light-hight- text-center p-1"
								>
									{{$t('global.details')}}
								</div>
								<div class="row px-3 mb-4">
									<div class="col-6">
										<table class="table" style="width: 100% !important">
											<tr>
												<td class="bg-dark px-3 border text-white- text-center">
													{{$t('programs.amount_excluding_tax')}}
												</td>
												<td
													class="border text-black- bg-white text-center px-4"
												>
													{{ $filters.formatAmount(buying.amountHT) }}
												</td>
											</tr>
											<tr>
												<td class="bg-dark px-3 border text-white- text-center">
													TVA
												</td>
												<td
													class="border text-black- bg-white text-center px-4"
												>
													{{ $filters.formatAmount(buying.tva) }}
												</td>
											</tr>
										</table>
									</div>
									<div class="col-6">
										<table class="table" style="width: 100% !important">
											<tr>
												<td class="bg-dark px-3 border text-white- text-center">
													{{$t('programs.amount_including_tax')}}
												</td>
												<td
													class="border text-black- bg-white text-center px-4"
												>
													{{ $filters.formatAmount(buying.amountTTC) }}
												</td>
											</tr>
											<tr>
												<td class="bg-alt- px-3 border text-white- text-center">
													{{$t('programs.buying.payment_method')}}
												</td>
												<td
													class="border text-black- bg-white text-center px-4"
												>
													{{ buying.paymentMean }}
												</td>
											</tr>
										</table>
									</div>
								</div>

								<div
									class="text-red- mt-3 mb-4 text-uppercase text-5 bg-light-hight- text-center p-1"
								>
									{{$t('global.document')}}
								</div>

								<div
									v-if="buying.document"
									class="px-3 my-3 row mx-3 text-center"
								>
									<a
										class="bg-white p-2 rounded w-100"
										type="button"
										@click="
											downloadFile(
												buying.document ? buying.document.url : '',
												'Facture-' + buying.number
											)
										"
									>
										<i
											class="uil uil-arrow-circle-down fs-6 text-primary-"
											type="button"
										></i>
										{{$t('global.download')}}
									</a>
								</div>
								<div v-else class="px-3 my-3 text-center mx-3">N/A</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xl-3">
					<div class="rounded bg-second- text-white text-4 text-center p-1">
						{{$t('global.advice')}}
					</div>
					<div
						class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
					>
						{{$t('programs.buying.show_advice_text')}}
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import Helper from '@/helpers/helper';
import LoadingMain from '@/components/LoadingMain.vue';
export default {
	name: 'ShowSectionPage',
	components: { Template, Back, LoadingMain },
	data() {
		return {
			id: '',
			loading: false,
			ok: true,
			buying: null,
		};
	},
	methods: {
		downloadFile(l, n) {
			Helper.downloadFile(l, n, this);
		},
		getbuying() {
			this.loading = true;
			fetch(Helper.route('admin/buying/' + this.id), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					console.log(data);
					if (data.buying) {
						this.buying = data.buying;
					}
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
	},
	created() {
		this.id = this.$route.params.id;
		this.getbuying();
	},
};
</script>
