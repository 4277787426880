<template>
	<div>
		<Template title="null" sidebarMenu="employees">
			<section class="row px-4 m-0 g-4 mt-1">
				<Back />

				<div class="col-xl-9">
					<div
						class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 p-1"
					>
						{{$t('resources.edit_employee')}}
					</div>
					<LoadingMain :state="loading" />
					<div
						v-if="employee == null && !loading"
						class="p-5 text-red- text-5 text-center"
					>
						{{$t('resources.employee_no_found')}}
					</div>
					<div class="bg-light-hight- py-3 mt-2 g-4" v-if="employee !== null">
						<form
							@submit.prevent="employeeUpdate"
							class="col-lg-11 mb-3 col-md-11 mx-auto"
						>
							<div class="mb-4 text-primary-" v-if="message != ''">
								{{ message }}
							</div>
							<div class="row">
								<div class="col-3 vstack">
									<input
										type="file"
										class="d-none"
										@change="onFileChange"
										id="photo"
										ref="photo"
										name="photo"
									/>
									<img
										:src="url"
										class="setting_user_image bg-white- border2"
										alt=""
									/>
									<i
										class="uil uil-camera text-1 text-primary-"
										@click="$refs.photo.click()"
										type="button"
										style="margin-top: -40px; margin-left: 92px"
									></i>
								</div>
								<div class="col-9">
									<div class="row">
										<div class="col">
											<InputFC
												:error="errors.firstname"
												:type="'text'"
												v-model="firstname"
												:placeholder="$t('global.firstname')"
											/>
										</div>
										<div class="col">
											<InputFC
												:error="errors.lastname"
												:type="'text'"
												v-model="lastname"
												:placeholder="$t('global.lastname')"
											/>
										</div>
									</div>
									<div class="row mt-4">
										<div class="col">
											<InputFC
												:error="errors.gender"
												:options="genders"
												:type="'select'"
												v-model="gender"
												:placeholder="$t('global.sex')"
											/>
										</div>
									</div>
								</div>
							</div>
							<hr class="mb-0" />

							<div class="row">
								<div class="col-lg-6 mt-4">
									<InputFC
										:error="errors.birthDate"
										:type="'date'"
										v-model="birthDate"
										:placeholder="$t('global.burn_date')"
									/>
								</div>
								<div class="col-lg-6 mt-4">
									<InputFC
										:error="errors.birthPlace"
										:type="'text'"
										v-model="birthPlace"
										:placeholder="$t('global.birth_place')"
									/>
								</div>
							</div>

							<div class="row">
								<div class="col-lg-6 mt-4">
									<InputFC
										:error="errors.maritalStatus"
										:options="matrimonialStutuses"
										:type="'select'"
										v-model="maritalStatus"
										:placeholder="$t('global.marital_status')"
									/>
								</div>
								<div class="col-lg-6 mt-4">
									<InputFC
										:error="errors.childrenNumber"
										:type="'number'"
										v-model="childrenNumber"
										:placeholder="$t('global.child_number')"
									/>
								</div>
							</div>
							<hr class="mb-0" />

							<div class="row">
								<div class="col-lg-6 mt-4">
									<InputFC
										:error="errors.address"
										:type="'text'"
										v-model="address"
										:placeholder="$t('global.address')"
									/>
								</div>
								<div class="col-lg-6 mt-4">
									<InputFC
										:error="errors.state"
										:type="'select'"
										:options="states"
										v-model="state"
										:placeholder="$t('global.state')"
									/>
								</div>
							</div>

							<div class="row">
								<div class="col-lg-6 mt-4">
									<InputFC
										:error="errors.phoneNumber"
										:type="'tel'"
										v-model="phoneNumber"
										:placeholder="$t('global.tel')"
									/>
								</div>
								<div class="col-lg-6 mt-4">
									<InputFC
										:error="errors.emailAddress"
										:type="'email'"
										v-model="emailAddress"
										:placeholder="$t('global.email')"
									/>
								</div>
							</div>
							<hr class="mb-0" />

							<div class="row">
								<div class="col-lg-6 mt-4">
									<label for="">{{$t('resources.poste')}}</label>
									<v-select
										multiple
										class="bg-white mt-1"
										v-model="job"
										:options="jobs"
										:reduce="(job) => job.id"
										label="name"
									></v-select>
									<span
										v-show="errors.job != ''"
										v-text="errors.job"
										class="error"
									></span>
								</div>
								<div class="col-lg-6 mt-4">
									<label for="">{{$t('resources.sections')}}</label>
									<v-select
										multiple
										class="bg-white mt-1"
										v-model="section"
										:options="sections"
										:reduce="(section) => section.id"
										label="name"
									></v-select>
									<span
										v-show="errors.section != ''"
										v-text="errors.section"
										class="error"
									></span>
								</div>
							</div>
							<div class="row">
								<div class="col-lg-6 mt-4">
									<InputFC
										:error="errors.matricule"
										:type="'text'"
										v-model="matricule"
										:disabled="true"
										:placeholder="$t('resources.registration_number')"
									/>
								</div>
								<div class="col-lg-6 mt-4">
									<InputFC
										:error="errors.grade"
										:type="'text'"
										v-model="grade"
										:placeholder="$t('resources.grade')"
									/>
								</div>
							</div>
							<hr class="mb-0" />

							<div class="row">
								<div class="col-lg-6 mt-4">
									<InputFC
										:error="errors.startDate"
										:type="'date'"
										v-model="startDate"
										:placeholder="$t('resources.intry_into_service')"
									/>
								</div>
								<div class="col-lg-6 mt-4">
									<InputFC
										:error="errors.firstPaymentDate"
										:type="'date'"
										v-model="firstPaymentDate"
										:placeholder="$t('resources.date_first_payment')"
									/>
								</div>
							</div>
							<hr class="mb-0" />

							<div class="row">
								<div class="col-lg-6 mt-4">
									<InputFC
										:error="errors.rate"
										:options="rates"
										:type="'select'"
										v-model="rate"
										:placeholder="$t('resources.star')"
									/>
								</div>
								<div class="col-lg-6 mt-4">
									<InputFC
										:error="errors.commitmentLevel"
										:type="'number'"
										:min="0"
										:max="100"
										v-model="commitmentLevel"
										:placeholder="$t('resources.commitment')"
									/>
								</div>
							</div>

							<div class="row">
								<div class="col-lg-6 mt-4">
									<InputFC
										:error="errors.qualities"
										:type="'text'"
										v-model="qualities"
										:placeholder="$t('resources.qualities')"
									/>
								</div>

								<div class="col-lg-6 mt-4">
									<InputFC
										:error="errors.experienceYears"
										:type="'number'"
										:min="0"
										:max="150"
										v-model="experienceYears"
										:placeholder="$t('resources.experience_years')"
									/>
								</div>
							</div>

							<hr class="mb-0" />

							<InputFC
								:error="errors.observation"
								:type="'textarea'"
								v-model="observation"
								:placeholder="$t('resources.special_observations')"
							/>

							<div class="hstack">
								<div class="">
									<SpinnerMe :elementId="'spinner'" />
									<button id="submit" class="button px-4 py-1 mt-4">
										{{ $t('global.send') }}
									</button>
								</div>
								<div class="ms-3">
									<button
										class="button-default bg-light- text-color- fw-500 shadow-sm px-4 py-1 mt-4"
										@click="$router.go(-1)"
									>
										{{ $t('global.cancel') }}
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div class="col-xl-3">
					<div class="rounded bg-second- text-white text-4 text-center p-1">
						{{$t('global.advice')}}
					</div>
					<div
						class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
					>
						{{$t('resources.edit_item_advice_text')}}
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import Helper from '@/helpers/helper';
import LoadingMain from '@/components/LoadingMain.vue';
import InputFC from '@/components/InputFC.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
export default {
	name: 'EditEmployeePage',
	components: { Template, Back, LoadingMain, SpinnerMe, InputFC },
	data() {
		return {
			lastname: '',
			firstname: '',
			gender: '',
			birthDate: '',
			childrenNumber: '',
			state: '',
			grade: '',
			phoneNumber: '',
			startDate: '',
			birthPlace: '',
			emailAddress: '',
			maritalStatus: '',
			firstPaymentDate: '',
			observation: '',
			job: [],
			section: [],
			matricule: '',
			rate: '',
			address: '',
			message: '',
			photo: null,
			url: '',
			id: '',
			errors: [],
			loading: true,
			employee: null,
			ok: false,
			sections: [],
			jobs: [],
			genders: [],
			states: [],
			qualities: '',
			commitmentLevel: 0,
			experienceYears: 0,
			matrimonialStutuses: [],
			rates: [],
		};
	},
	methods: {
		onFileChange(e) {
			this.photo = e.target.files[0];
			this.url = URL.createObjectURL(this.photo);
		},
		employeeUpdate() {
			this.errors = [];

			if (this.beforeUpdateItem()) {
				this.ok = true;
				this.error_message = '';
				this.message = '';
				Helper.loading('submit', 'spinner', true);
				let registerFormData = new FormData();
				registerFormData.append('id', this.id);
				registerFormData.append('lastname', this.lastname);
				registerFormData.append('firstname', this.firstname);
				registerFormData.append('birthPlace', this.birthPlace);
				registerFormData.append('telNumber', this.phoneNumber);
				registerFormData.append('emailAddress', this.emailAddress);
				registerFormData.append('gender', this.gender);
				registerFormData.append('matricule', this.matricule);
				registerFormData.append('maritalStatus', this.maritalStatus);
				registerFormData.append('childrenNumber', this.childrenNumber);
				registerFormData.append(
					'jobs',
					JSON.stringify(Helper.extractIdsFromListObj(this.job))
				);
				registerFormData.append('grade', this.grade);
				registerFormData.append('state', this.state);
				registerFormData.append(
					'sections',
					JSON.stringify(Helper.extractIdsFromListObj(this.section))
				);
				registerFormData.append('address', this.address);
				registerFormData.append(
					'birthDate',
					new Date(this.birthDate).toISOString()
				);
				registerFormData.append(
					'startDate',
					new Date(this.startDate).toISOString()
				);
				registerFormData.append(
					'firstPaymentDate',
					new Date(this.firstPaymentDate).toISOString()
				);
				registerFormData.append('qualities', this.qualities);
				registerFormData.append('commitmentLevel', this.commitmentLevel);
				registerFormData.append('experienceYears', this.experienceYears);
				registerFormData.append('observation', this.observation);
				registerFormData.append('rate', this.rate);
				if (document.getElementById('photo').files[0] != undefined) {
					registerFormData.append(
						'photo',
						document.getElementById('photo').files[0]
					);
				}

				fetch(
					Helper.route('admin/employee/update'),
					Helper.requestOptions('PUT', registerFormData)
				)
					.then((response) => {
						if (response.status === 401) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						Helper.loading('submit', 'spinner', false);

						if (data.problems) {
							this.errors.lastname = Helper.getError(data.problems, 'lastname');
							this.errors.firstname = Helper.getError(
								data.problems,
								'firstname'
							);
							this.errors.birthPlace = Helper.getError(
								data.problems,
								'birthPlace'
							);
							this.errors.emailAddress = Helper.getError(
								data.problems,
								'emailAddress'
							);
							this.errors.gender = Helper.getError(data.problems, 'gender');
							this.errors.address = Helper.getError(data.problems, 'address');
							this.errors.matricule = Helper.getError(
								data.problems,
								'matricule'
							);
							this.errors.maritalStatus = Helper.getError(
								data.problems,
								'maritalStatus'
							);
							this.errors.childrenNumber = Helper.getError(
								data.problems,
								'childrenNumber'
							);
							this.errors.job = Helper.getError(data.problems, 'jobs');
							this.errors.grade = Helper.getError(data.problems, 'grade');
							this.errors.state = Helper.getError(data.problems, 'state');
							this.errors.section = Helper.getError(data.problems, 'sections');
							this.errors.birthDate = Helper.getError(
								data.problems,
								'birthDate'
							);
							this.errors.startDate = Helper.getError(
								data.problems,
								'startDate'
							);
							this.errors.firstPaymentDate = Helper.getError(
								data.problems,
								'firstPaymentDate'
							);
							this.errors.observation = Helper.getError(
								data.problems,
								'observation'
							);
							this.errors.commitmentLevel = Helper.getError(
								data.problems,
								'commitmentLevel'
							);
							this.errors.experienceYears = Helper.getError(
								data.problems,
								'experienceYears'
							);
							this.errors.rate = Helper.getError(data.problems, 'rate');
							this.errors.phoneNumber = Helper.getError(
								data.problems,
								'phoneNumber'
							);
						} else {
							if (!this.ok) {
								this.error_message = data.message;
							} else {
								if (data.status) {
									Helper.notification('success', data.message);
									this.message = data.message;
									this.$router.go(-1);
								}
							}
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit', 'spinner', false);
					});
			}
		},
		beforeUpdateItem() {
			if (Helper.required(this.lastname) !== '') {
				this.errors.lastname = Helper.required(this.lastname);
			}
			if (Helper.required(this.firstname) !== '') {
				this.errors.firstname = Helper.required(this.firstname);
			}
			if (Helper.required(this.matricule) !== '') {
				this.errors.matricule = Helper.required(this.matricule);
			}
			if (Helper.required(this.phoneNumber) !== '') {
				this.errors.phoneNumber = Helper.required(this.phoneNumber);
			}
			if (Helper.required(this.emailAddress) !== '') {
				this.errors.emailAddress = Helper.required(this.emailAddress);
			}
			if (Helper.required(this.gender) !== '') {
				this.errors.gender = Helper.required(this.gender);
			}
			if (Helper.required(this.maritalStatus) !== '') {
				this.errors.maritalStatus = Helper.required(this.maritalStatus);
			}
			if (Helper.required(this.job) !== '') {
				this.errors.job = Helper.required(this.job);
			}
			if (Helper.required(this.state) !== '') {
				this.errors.state = Helper.required(this.state);
			}
			if (Helper.required(this.address) !== '') {
				this.errors.address = Helper.required(this.address);
			}
			if (Helper.required(this.birthPlace) != '') {
				this.errors.birthPlace = Helper.required(this.birthPlace);
			}
			if (Helper.required(this.birthDate) != '') {
				this.errors.birthDate = Helper.required(this.birthDate);
			}
			if (Helper.required(this.startDate) !== '') {
				this.errors.startDate = Helper.required(this.startDate);
			}
			if (Helper.required(this.firstPaymentDate) !== '') {
				this.errors.firstPaymentDate = Helper.required(this.firstPaymentDate);
			}
			if (Helper.required(this.rate) !== '') {
				this.errors.rate = Helper.required(this.rate);
			}
			return Helper.objectNumberKey(this.errors) == 0 ? true : false;
		},
		getEmployee() {
			this.loading = true;
			fetch(Helper.route('admin/employee/' + this.id), Helper.requestOptions())
				.then((response) => response.json())
				.then((data) => {
					this.loading = false;
					if (data.data) {
						this.employee = data.data;
						console.log(this.employee);

						this.lastname = this.employee.lastname;
						this.firstname = this.employee.firstname;
						this.gender = this.employee.gender;
						this.birthDate = Helper.convertDateToForm(this.employee.birthDate);
						this.childrenNumber = this.employee.childrenNumber;
						this.state = this.employee.state;
						this.grade = this.employee.grade;
						this.startDate = Helper.convertDateToForm(this.employee.startDate);
						this.birthPlace = this.employee.birthPlace;
						this.phoneNumber = this.employee.phoneNumber;
						this.emailAddress = this.employee.emailAddress;
						this.maritalStatus = this.employee.maritalStatus;
						this.firstPaymentDate = Helper.convertDateToForm(
							this.employee.firstPaymentDate
						);
						this.observation = this.employee.observation;
						this.commitmentLevel = this.employee.commitmentLevel;
						this.qualities = this.employee.qualities;
						this.experienceYears = this.employee.experienceYears;

						this.employee.jobs.forEach((job_item) => {
							this.job.push({
								id: job_item.id,
								name: job_item.designation,
							});
						});

						this.employee.sections.forEach((section_item) => {
							this.section.push({
								id: section_item.id,
								name: section_item.name,
							});
						});

						this.matricule = this.employee.matricule;
						this.rate = this.employee.rate;
						this.address = this.employee.address;
						this.message = this.employee.message;
						this.url = this.employee.photo.url;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
	created() {
		this.id = this.$route.params.id;
		this.getEmployee();
		this.rates = Helper.rates;
		this.genders = Helper.genders;
		this.matrimonialStutuses = Helper.matrimonialStutuses;
		this.states = Helper.states;

		fetch(Helper.route('admin/section/all'), Helper.requestOptions())
			.then((response) => response.json())
			.then((data) => {
				if (data.data) {
					data.data.forEach((section_item) => {
						this.sections.push({
							id: section_item.id,
							name: section_item.name,
						});
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});

		fetch(Helper.route('admin/job/all'), Helper.requestOptions())
			.then((response) => response.json())
			.then((data) => {
				if (data.data) {
					data.data.forEach((job_item) => {
						this.jobs.push({
							id: job_item.id,
							name: job_item.designation,
						});
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	},
};
</script>
