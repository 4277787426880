<template>
	<div>
		<Template :title="$t('documents.new_document')" sidebarMenu="documents">
			<section class="p-5 py-4">
				<Back />

				<div
					class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 py-1 mt-4"
				>
					{{$t('documents.file_import')}}
				</div>

				<div class="bg-light-hight- rounded py-5 mt-3">
					<div v-if="imported > 0" class="text-center pb-2">
						<span class="text-black-">{{$t('documents.imported')}}: </span>
						<span class="text-red- fw-600">{{ imported }}</span> {{
							imported > 1 ? $t('documents.file')+'s' : $t('documents.file')
						}}
					</div>
					<div class="col-lg-10 col-md-11 mx-auto">
						<InputFC
							:type="'select'"
							class="mb-4 col-12"
							:options="folders"
							v-model="folder"
							:placeholder="$t('documents.select_file')"
						/>

						<div>
							<div
								id="dropZone"
								@click="$refs.document_input.click()"
								type="button"
								class="bg-light- border-dashed- p-5 text-center"
							>
								<i type="button" class="uil uil-upload fs-1 text-alt-"></i>
								<p>{{$t('documents.drag_and_drop')}}</p>
							</div>
							<input
								type="file"
								multiple
								class="d-none"
								size="50"
								@change="onFileChange"
								id="document_input"
								ref="document_input"
								name="document_input"
							/>
							<button
								class="button-default w-100 py-1 text-center mt-3 bg-green-"
								@click="$refs.document_input.click()"
							>
								{{$t('documents.browse')}}
							</button>
							<hr />
						</div>

						<div v-for="file in files" :key="file.lastModified">
							<FileUpload
								:file="file"
								:folder="folder"
								@uploaded="uploadCompleted"
								@deleted="deletionCompleted"
							/>
						</div>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import Helper from '@/helpers/helper';
import FileUpload from '@/components/FileUpload.vue';
import InputFC from '@/components/InputFC.vue';

export default {
	name: 'CreateDocument',
	components: { Template, Back, FileUpload, InputFC },
	data() {
		return {
			files: [],
			url: '',
			folders: [],
			folder: 1,
			imported: 0,
		};
	},
	methods: {
		onFileChange(e) {
			this.files = [...this.files, ...e.target.files];
		},
		uploadCompleted(data) {
			if (data) {
				this.imported++;
			}
		},
		deletionCompleted(data) {
			if (data) {
				this.imported--;
			}
		},
	},
	mounted() {
		let dropZone = document.getElementById('dropZone');
		dropZone.addEventListener('dragover', (e) => {
			e.preventDefault();
		});
		dropZone.addEventListener('drop', (e) => {
			e.preventDefault();
			this.files = [...this.files, ...e.dataTransfer.files];
		});
	},
	created() {
		this.url = Helper.route('admin/document/add');

		fetch(Helper.route('folder/all'), Helper.requestOptions())
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				if (data.data) {
					data.data.forEach((folder_item) => {
						this.folders.push({
							id: folder_item.id,
							name: folder_item.name,
						});
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	},
};
</script>
<style></style>
