import { createRouter, createWebHistory } from 'vue-router';

import Admin from '../pages/Admin/Index';
import Admin_Create from '../pages/Admin/Create';
import Admin_Edit from '../pages/Admin/Edit';
import Admin_Invitation from '../pages/Admin/Invitation';
import Assistance from '../pages/Others/Assistance/Index';
import Buying_Archives from '../pages/Programs/Buying/Archives';
import Buying_Create from '../pages/Programs/Buying/Create';
import Buying_Index from '../pages/Programs/Buying/Index';
import Buying_Show from '../pages/Programs/Buying/Show';
import Calendar_New from '../pages/Tools/CreateTask';
import CandidatesProfiles from '../pages/Candidates/Profiles/Index';
import CoAdmin_Me_Right from '../pages/Admin/CoAdminRightView';
import CoAdmin_Right from '../pages/Admin/CoAdminRight';
import Comments from '../pages/Others/Comments/Index';
import Dashboard from '../pages/Dashboard/Index';
import Documents from '../pages/Documents/Index';
import Documents_Archives from '../pages/Documents/Archives';
import Documents_Favorites from '../pages/Documents/Favorites';
import Documents_New from '../pages/Documents/Create';
import Documents_Show from '../pages/Documents/Show';
import ForgotPassword from '../pages/Connection/ForgotPassword';
import FormNew from '../pages/Candidates/FormNew';
import Home from '../pages/Home';
import Invite from '../pages/Connection/Invite';
import Login from '../pages/Connection/Login';
import Mail_Add from '../pages/Mails/Create';
import Mail_All from '../pages/Mails/All';
import Mail_Treat from '../pages/Mails/Treat';
import Mail_NotTreat from '../pages/Mails/NotTreat';
import Mail_Index from '../pages/Mails/Index';
import Mail_Show from '../pages/Mails/Show';
import New_Assistance from '../pages/Others/Assistance/Create';
import NotRight from '../pages/Others/NotRight';
import Notification from '../pages/Others/Notification';
import Offer from '../pages/Candidates/Offres/Show';
import Offer_Applicants from '../pages/Candidates/Offres/OfferApplicant.vue';
import Offer_Edit from '../pages/Candidates/Offres/Edit';
import Offer_New from '../pages/Candidates/Offres/Create';
import Offer_Quiz from '../pages/Candidates/Offres/Quiz';
import Offres from '../pages/Candidates/Offres/Index';
import Pricing from '../pages/Suscribe/Pricing';
import ProfileShow from '../pages/Candidates/Profiles/Show.vue';
import Register from '../pages/Connection/Register';
import Ressource_Contrat from '../pages/Ressources/Contrat/Show';
import Ressource_Contrats from '../pages/Ressources/Contrat/Index';
import Ressource_Employee from '../pages/Ressources/Employee/ShowItem';
import Ressource_Employee_Edit from '../pages/Ressources/Employee/EditItem';
import Ressource_Employee_New from '../pages/Ressources/Employee/CreateItem';
import Ressource_Employees from '../pages/Ressources/Employee/IndexItem';
import Ressource_Formation from '../pages/Ressources/Formation/ShowItem';
import Ressource_Formation_Edit from '../pages/Ressources/Formation/EditItem';
import Ressource_Formation_New from '../pages/Ressources/Formation/CreateItem';
import Ressource_Formations from '../pages/Ressources/Formation/IndexItem';
import Ressource_Poste from '../pages/Ressources/Poste/Show';
import Ressource_Postes from '../pages/Ressources/Poste/Index';
import Ressource_Section from '../pages/Ressources/Section/Show';
import Ressource_Sections from '../pages/Ressources/Section/Index';
import Sale_Archives from '../pages/Programs/Sale/Archives';
import Sale_Create from '../pages/Programs/Sale/Create';
import Sale_Index from '../pages/Programs/Sale/Index';
import Sale_Show from '../pages/Programs/Sale/Show';
import Setting from '../pages/Others/Setting';
import Single_Assistance from '../pages/Others/Assistance/Show';
import Subscription from '../pages/Suscribe/Subscription';
import Tools from '../pages/Tools/Index';
import Tools_Task from '../pages/Tools/ShowItem.vue';
import Edit_Task from '../pages/Tools/EditTask.vue';
import helpers from './helper';

// import Mail_Sent from '../pages/Mails/Sent';
// import Mail_Archive from '../pages/Mails/Archive';

// import Email_Inbox from "../pages/Emails/Inbox";
// import Email_Sent from "../pages/Emails/Sent";
// import Email_Trash from "../pages/Emails/Trash";
// import Email_Spam from "../pages/Emails/Spam";
// import Email_New from "../pages/Emails/New";
// import Email_Draft from "../pages/Emails/Draft";
// import Email_Archive from "../pages/Emails/Archive";
// import Email_Single from "../pages/Emails/Single";
// import Email_Configure from "../pages/Emails/Configure";

// import Buying_Index from "../pages/Programs/Sale/Index";

const routes = [
	{
		name: 'start',
		path: '/',
		component: Login,
		meta: { auth: false },
	},
	{
		name: 'login',
		path: '/login',
		component: Login,
		meta: { auth: false },
	},
	{
		name: 'forgot-password',
		path: '/forgot-password',
		component: ForgotPassword,
		meta: { auth: false },
	},
	{
		name: 'register',
		path: '/register',
		component: Register,
		meta: { auth: false },
	},
	{
		name: 'invite',
		path: '/invite/:reference',
		component: Invite,
		meta: { auth: false },
	},
	{
		name: 'home',
		path: '/home',
		component: Home,
		meta: { auth: true },
	},
	{
		name: 'dashboard',
		path: '/dashboard',
		component: Dashboard,
		meta: { auth: true },
	},
	{
		name: 'notification',
		path: '/notification',
		component: Notification,
		meta: { auth: true },
	},
	{
		name: 'not-right',
		path: '/not-right',
		component: NotRight,
		meta: { auth: true },
	},

	/************ EMAIL *********/
	{
		name: 'mail',
		path: '/mail',
		component: Mail_Index,
		meta: { auth: true },
	},
	/* {
		name: 'mail-sent',
		path: '/mail/mail-sent',
		component: Mail_Sent,
		meta: { auth: true },
	},
	{
		name: 'mail-archive',
		path: '/mail/mail-archive',
		component: Mail_Archive,
		meta: { auth: true },
	}, */
	{
		name: 'mail-add',
		path: '/mail/add',
		component: Mail_Add,
		meta: { auth: true },
	},
	{
		name: 'mail-all',
		path: '/mail/all',
		component: Mail_All,
		meta: { auth: true },
	},
	{
		name: 'mail-treat',
		path: '/mail/treat',
		component: Mail_Treat,
		meta: { auth: true },
	},
	{
		name: 'mail-not-treat',
		path: '/mail/not-treat',
		component: Mail_NotTreat,
		meta: { auth: true },
	},
	{
		name: 'mail-show',
		path: '/mail/show/:id',
		component: Mail_Show,
		meta: { auth: true },
	},

	/*********** EMPLOYEES ********/
	{
		name: 'employees',
		path: '/employees',
		component: Ressource_Employees,
		meta: { auth: true },
	},
	{
		name: 'employee',
		path: '/employees/:id/:slug',
		component: Ressource_Employee,
		meta: { auth: true },
	},
	{
		name: 'employee-create',
		path: '/employees/create',
		component: Ressource_Employee_New,
		meta: { auth: true },
	},
	{
		name: 'employee-edit',
		path: '/employees/:id/:slug/edit',
		component: Ressource_Employee_Edit,
		meta: { auth: true },
	},
	{
		name: 'postes',
		path: '/postes',
		component: Ressource_Postes,
		meta: { auth: true },
	},
	{
		name: 'postes-show',
		path: '/postes/:id/:slug?',
		component: Ressource_Poste,
		meta: { auth: true },
	},
	{
		name: 'sections',
		path: '/sections',
		component: Ressource_Sections,
		meta: { auth: true },
	},
	{
		name: 'sections-show',
		path: '/sections/:id/:slug',
		component: Ressource_Section,
		meta: { auth: true },
	},
	{
		name: 'contrats',
		path: '/contrats',
		component: Ressource_Contrats,
		meta: { auth: true },
	},
	{
		name: 'contrats-show',
		path: '/contrats/:id/:slug',
		component: Ressource_Contrat,
		meta: { auth: true },
	},
	/** JOB OFFRERS */
	{
		name: 'offers',
		path: '/offers',
		component: Offres,
		meta: { auth: true },
	},
	{
		name: 'offer-create',
		path: '/offer/create',
		component: Offer_New,
		meta: { auth: true },
	},
	{
		name: 'offer',
		path: '/offer/:id/:slug',
		component: Offer,
		meta: { auth: true },
	},
	{
		name: 'offer-edit',
		path: '/offer/:id/:slug/edit',
		component: Offer_Edit,
		meta: { auth: true },
	},
	{
		name: 'offer-applicants',
		path: '/offer/:id/applicants',
		component: Offer_Applicants,
		meta: { auth: true },
	},
	{
		name: 'offer-quiz',
		path: '/offer/:id/:slug/quizs',
		component: Offer_Quiz,
		meta: { auth: true },
	},
	/** APPLICANTS */
	{
		name: 'candidates-profiles',
		path: '/candidates-profiles',
		component: CandidatesProfiles,
		meta: { auth: true },
	},
	{
		name: 'application',
		path: '/application/:id/:slug',
		component: ProfileShow,
		meta: { auth: true },
	},
	/** TOOLS */
	{
		name: 'form-new',
		path: '/form-new',
		component: FormNew,
		meta: { auth: true },
	},
	{
		name: 'tools',
		path: '/tools',
		component: Tools,
		meta: { auth: true },
	},
	{
		name: 'task',
		path: '/tasks/:id/:slug',
		component: Tools_Task,
		meta: { auth: true },
	},
	{
		name: 'task-edit',
		path: '/tasks/:id/:slug/edit',
		component: Edit_Task,
		meta: { auth: true },
	},
	{
		name: 'calendar-new',
		path: '/calendar-new',
		component: Calendar_New,
		meta: { auth: true },
	},
	/** DOCUMENTS */
	{
		name: 'documents',
		path: '/documents',
		component: Documents,
		meta: { auth: true },
	},
	{
		name: 'documents-show',
		path: '/documents/:id/:slug',
		component: Documents_Show,
		meta: { auth: true },
	},
	{
		name: 'document-new',
		path: '/documents/create',
		component: Documents_New,
		meta: { auth: true },
	},
	{
		name: 'documents-favorite',
		path: '/documents-favorites',
		component: Documents_Favorites,
		meta: { auth: true },
	},
	{
		name: 'documents-archives',
		path: '/documents-archives',
		component: Documents_Archives,
		meta: { auth: true },
	},
	/** SALES */
	{
		name: 'sales-index',
		path: '/program-managements/sale',
		component: Sale_Index,
		meta: { auth: true },
	},
	{
		name: 'sales-create',
		path: '/program-managements/sale/create',
		component: Sale_Create,
		meta: { auth: true },
	},
	{
		name: 'sales-show',
		path: '/program-managements/sales/:id/:slug',
		component: Sale_Show,
		meta: { auth: true },
	},
	{
		name: 'sales-archives',
		path: '/program-managements/sale-archives',
		component: Sale_Archives,
		meta: { auth: true },
	},
	/** BUYING */
	{
		name: 'buying-index',
		path: '/program-managements/buying',
		component: Buying_Index,
		meta: { auth: true },
	},
	{
		name: 'buying-create',
		path: '/program-managements/buying/create',
		component: Buying_Create,
		meta: { auth: true },
	},
	{
		name: 'buying-show',
		path: '/program-managements/buying/:id/:slug',
		component: Buying_Show,
		meta: { auth: true },
	},
	{
		name: 'buying-archives',
		path: '/program-managements/buying-archives',
		component: Buying_Archives,
		meta: { auth: true },
	},
	/** TRAININGS */
	{
		name: 'formations',
		path: '/formations',
		component: Ressource_Formations,
		meta: { auth: true },
	},
	{
		name: 'formation',
		path: '/formations/:id/:slug',
		component: Ressource_Formation,
		meta: { auth: true },
	},
	{
		name: 'formation-create',
		path: '/formations/create',
		component: Ressource_Formation_New,
		meta: { auth: true },
	},
	{
		name: 'formation-edit',
		path: '/formations/:id/:slug/edit',
		component: Ressource_Formation_Edit,
		meta: { auth: true },
	},
	/** OTHERS */
	{
		name: 'setting',
		path: '/setting',
		component: Setting,
		meta: { auth: true },
	},
	{
		name: 'assistance',
		path: '/assistance',
		component: Assistance,
		meta: { auth: true },
	},
	{
		name: 'ticket',
		path: '/ticket/:id',
		component: Single_Assistance,
		meta: { auth: true },
	},
	{
		name: 'new-ticket',
		path: '/ticket/create',
		component: New_Assistance,
		meta: { auth: true },
	},
	{
		name: 'comments',
		path: '/comments',
		component: Comments,
		meta: { auth: true },
	},
	{
		name: 'pricing',
		path: '/pricing',
		component: Pricing,
		meta: { auth: true },
	},
	{
		name: 'subscription',
		path: '/subscription',
		component: Subscription,
		meta: { auth: true },
	},
	/** COADMIN */
	{
		name: 'admin',
		path: '/admin',
		component: Admin,
		meta: { auth: true },
	},
	{
		name: 'admin-create',
		path: '/admin/create',
		component: Admin_Create,
		meta: { auth: true },
	},
	{
		name: 'admin-invitation',
		path: '/admin/invitation',
		component: Admin_Invitation,
		meta: { auth: true },
	},
	{
		name: 'admin-edit',
		path: '/admin/:id/:slug/edit',
		component: Admin_Edit,
		meta: { auth: true },
	},
	{
		name: 'grant-right',
		path: '/admin/grant-rights/:slug/:id',
		component: CoAdmin_Right,
		meta: { auth: true },
	},
	{
		name: 'view-rights',
		path: '/admin/view-rights',
		component: CoAdmin_Me_Right,
		meta: { auth: true },
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.meta.auth) {
		if (helpers.isConnected()) {
			return next();
		} else {
			return next('login');
		}
	} else if (to.meta.auth == false) {
		if (helpers.isConnected()) {
			return next('home');
		} else {
			return next();
		}
	} else {
		return next();
	}
});

export default router;
