<template>
	<div>
		<Template title="null" sidebarMenu="documents">
			<div class="bg-light-hight- hstack py-3 ps-2 pb-3">
				<router-link
					to="/documents"
					class="bg-light-hight- ms-2 text-6 top-item"
					>{{ $t('documents.library') }}</router-link
				>
				<router-link
					to="/documents-favorites"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('documents.favorites') }}</router-link
				>
				<router-link
					to="/documents-archives"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('documents.archives') }}</router-link
				>
			</div>

			<section class="row px-4 m-0 g-4 mt-1">
				<!-- Suppression -->
				<div
					class="modal fade"
					id="deleteModal"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="deleteModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content rounded-0">
							<div class="modal-body">
								<h6 class="text-center text-red-">
									{{ $t('global.deletion_warning') }}
								</h6>
								<hr />
								<p class="text-5" v-show="!deleted" v-html="$t('documents.file_deletion_text')">
								
								</p>
								<p class="text-5 text-center" v-show="deleted">
									{{ $t('global.deleted_successfully') }}
									<i class="uil uil-check-circle fs-4 text-primary-"></i>
								</p>
								<hr />
								<div class="row">
									<div class="col text-center">
										<button
											type="button"
											class="border bg-light px-3 text-5 rounded py-1"
											data-mdb-dismiss="modal"
										>
											{{ $t('global.close') }}
										</button>
									</div>
									<div
										class="col text-center"
										v-show="!deleted && selectedList.length !== 0"
									>
										<button
											type="button"
											class="bg-red- text-white- px-3 rounded py-1 text-5 border-0 set-opacity"
											@click="deletes()"
										>
											{{ $t('global.delete') }}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Archivage -->
				<div
					class="modal fade"
					id="archiveModal"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="archiveModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content rounded-0">
							<div class="modal-body">
								<h6 class="text-center text-alt-">
									{{ $t('global.archiving_warning') }}
								</h6>
								<hr />
								<p class="text-5" v-show="!archived" v-html="$t('documents.doc_archiving_text')">
									
								</p>
								<p class="text-5 text-center" v-show="archived">
									{{ $t('global.archiving_successfully') }}
									<i class="uil uil-check-circle fs-4 text-primary-"></i>
								</p>
								<hr />
								<div class="row">
									<div class="col text-center">
										<button
											type="button"
											class="border bg-light px-3 text-5 rounded py-1"
											data-mdb-dismiss="modal"
										>
											{{ $t('global.close') }}
										</button>
									</div>
									<div
										class="col text-center"
										v-show="!archived && selectedList.length !== 0"
									>
										<button
											type="button"
											class="bg-alt- text-white- px-3 rounded py-1 text-5 border-0 set-opacity"
											@click="archives()"
										>
											Archiver
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-xl-9">
					<div v-if="!loading && recents.length != 0">
						<div
							class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 p-1"
						>
							{{ $t('global.recents') }}
						</div>
						<div class="row mt-1 g-2 mb-4">
							<div
								class="col-md-3"
								v-for="(document, index) in recents"
								:key="index"
							>
								<div class="rate_item bg-light-hight-1 rounded">
									<div class="hstack w-75 mx-auto p-3">
										<div class="vstack">
											<img
												role="button"
												@click="favourite(document)"
												v-if="document.isFavourite"
												class="text-red-"
												style="width: 15px; height: 15px"
												src="images/heart-bold.png"
												alt="favourite"
											/>
											<img
												role="button"
												@click="favourite(document)"
												v-else
												src="images/heart-thin.png"
												style="width: 15px !important; height: 15px"
												alt="not favourite"
											/>
										</div>
										<img
											:src="$filters.mapFileIcon(document.type)"
											:alt="document.name"
										/>
									</div>
									<router-link
										:to="{
											name: 'documents-show',
											params: { id: document.reference, slug: document.slug },
										}"
									>
										<div
											class="bg-second- text-center text-truncate text-white rounded p-2 text-5"
										>
											{{ $filters.truncate(document.name, 20) }}
										</div>
									</router-link>
								</div>
							</div>
						</div>
					</div>

					<div class="hstack">
						<router-link
							to="/documents/create"
							class="bg-second- rounded ms-auto px-3 p-1"
						>
							<i class="uil uil-plus text-white"></i>
							<span class="ms-2 text-6 text-white">{{
								$t('documents.import_file')
							}}</span>
						</router-link>
					</div>

					<div class="hstack mt-4 p-2 px-4 rounded shadow-1 bg-white">
						<input
							type="checkbox"
							:checked="allIsCheck"
							@click="check(0, true)"
						/>
						<i
							class="uil uil-archive ms-3 fs-6"
							type="button"
							data-mdb-toggle="modal"
							data-mdb-target="#archiveModal"
							@click="archived = false"
						></i>
						<i
							class="uil uil-trash fs-6 ms-3"
							type="button"
							data-mdb-toggle="modal"
							data-mdb-target="#deleteModal"
							@click="deleted = false"
						></i>

						<div class="hstack ms-auto">
							<label for="" class="text-5 me-2">{{
								$t('global.filter')
							}}</label>
							<select class="form-control text-6" v-model="orderBy">
								<option value="name">{{ $t('global.title') }}</option>
								<option value="id">{{ $t('global.date') }}</option>
								<option value="folder">{{ $t('global.folder') }}</option>
								<option value="size">{{ $t('global.size') }}</option>
								<option value="type">{{ $t('global.type') }}</option>
								<option value="isFavourite">
									{{ $t('documents.favorites') }}
								</option>
							</select>

							<label for="" class="text-5 ms-2 me-2">{{
								$t('global.order')
							}}</label>
							<select class="form-control text-6" v-model="order">
								<option value="ASC">Asc</option>
								<option value="DESC">Desc</option>
							</select>
						</div>

						<div class="ms-auto hstack">
							<input type="search" v-model="searchText" class="form-control mx-2 w-40" :placeholder="$t('tools.search')">
							<i
								class="uil uil-arrow-circle-left fs-5"
								type="button"
								@click="previous"
							></i>
							<small class="ms-3"
								>{{ Math.ceil(documents.length / perPage) > 0 ? page : 0 }} /
								{{ Math.ceil(documents.length / perPage) }}</small
							>
							<i
								class="uil uil-arrow-circle-right fs-5 ms-3"
								type="button"
								@click="next"
							></i>
							<div class="hstack ms-2">
								<i
									class="uil uil-apps fs-6 p-1 px-3 rounded"
									:class="
										store.disposition == 'grid'
											? 'bg-black- text-white-'
											: 'bg-white- text-black-'
									"
									@click="store.setDisposition('grid')"
									type="button"
								></i>
								<i
									class="uil uil-list-ul fs-6 ms-1 p-1 px-3 rounded"
									:class="
										store.disposition == 'list'
											? 'bg-black- text-white-'
											: 'bg-white- text-black-'
									"
									@click="store.setDisposition('list')"
									type="button"
								></i>
							</div>
						</div>
					</div>

					<div
						class="table-responsive-lg mt-0 mb-4"
						v-if="store.disposition == 'list'"
					>
						<table
							class="table documents align-middle table-hover table-striped mb-0"
							style="width: 100% !important"
						>
							<thead class="bg-primary-">
								<tr class="text-6 text-white">
									<th></th>
									<th>{{ $t('global.title') }}</th>
									<th>{{ $t('global.size') }}</th>
									<th>{{ $t('global.date') }}</th>
									<th>{{ $t('global.folder') }}</th>
									<th>{{ $t('global.type') }}</th>
									<th></th>
								</tr>
							</thead>
							<tbody class="bg-alt-">
								<tr
									class="text-6 text-black"
									v-for="(document, index) in documents"
									:key="index"
									v-show="show(index)"
								>
									<td>
										<input
											type="checkbox"
											:checked="isSelected(document.id)"
											@click="check(document.id)"
										/>
									</td>
									<td :title="document.name">
										<router-link
											:to="{
												name: 'documents-show',
												params: { id: document.reference, slug: document.slug },
											}"
										>
											{{ $filters.truncate(document.name, 25) }}
										</router-link>
									</td>
									<td>{{ sizeConverter(document.file.size) }}</td>
									<td class="text-nowrap">
										{{
											$filters.convertDate(
												new Date(document.createdAt).toJSON()
											)
										}}
									</td>
									<td>{{ document.folder.name }}</td>
									<td>{{ document.type }}</td>
									<td>
										<img
											role="button"
											@click="favourite(document)"
											v-if="document.isFavourite"
											class="text-red-"
											style="width: 16px; height: 15px"
											src="images/heart-bold.png"
											alt="favourite"
										/>
										<img
											role="button"
											@click="favourite(document)"
											v-else
											src="images/heart-thin.png"
											style="width: 15px !important; height: 15px"
											alt="not favourite"
										/>
										<!-- <i
											class="uil uil-heart-alt fs-6"
											:class="document.isFavourite == true ? 'text-red-' : ''"
											@click="favourite(document)"
											type="button"
										></i> -->
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div class="row mt-1 g-2 mb-4" v-else>
						<div
							class="col-md-3"
							v-for="(document, index) in documents"
							:key="index"
							v-show="show(index)"
						>
							<div class="rate_item bg-light-hight-1 rounded">
								<div class="hstack w-75 mx-auto p-3">
									<div class="vstack">
										<img
											role="button"
											@click="favourite(document)"
											v-if="document.isFavourite"
											class="text-red-"
											style="width: 15px; height: 15px"
											src="images/heart-bold.png"
											alt="favourite"
										/>
										<img
											role="button"
											@click="favourite(document)"
											v-else
											src="images/heart-thin.png"
											style="width: 15px !important; height: 15px"
											alt="not favourite"
										/>
										<!-- <i
											class="uil uil-heart fs-6"
											:class="document.isFavourite == true ? 'text-red-' : ''"
										></i> -->
									</div>
									<img
										:src="$filters.mapFileIcon(document.type)"
										:alt="document.name"
									/>
								</div>
								<div
									class="bg-second- text-center text-truncate text-white rounded p-2 text-5"
								>
									<input
										type="checkbox"
										class="me-2"
										:checked="isSelected(document.id)"
										@click="check(document.id)"
									/>
									<router-link
										:to="{
											name: 'documents-show',
											params: { id: document.reference, slug: document.slug },
										}"
									>
										{{ document.name }}
									</router-link>
								</div>
							</div>
						</div>
					</div>

					<LoadingMain :state="loading" />
				</div>

				<div class="col-xl-3">
					<div class="">
						<div class="rounded bg-second- text-white text-4 text-center p-1">
							{{ $t('global.advice') }}
						</div>
						<div
							class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
							v-html="$t('documents.doc_index_advice_text')"
						></div>
						<router-link to="/documents-favorites"
							><button class="button bg-alt- btn-sm w-100 mt-1 p-1">
								{{ $t('documents.access_now') }}
							</button>
						</router-link>
					</div>

					<div class="" style="margin-top: 95px">
						<div class="rounded bg-second- text-white text-4 text-center p-1">
							{{ $t('documents.active_files') }}
						</div>
						<div
							class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
						>
							<div class="px-3">
								<div
									class="row py-1"
									v-for="(item, index) in stats"
									:key="index"
								>
									<div class="col-6 text-start">
										{{ item.folder }}
									</div>
									<div class="col-6 text-alt- text-end">
										{{ $filters.formatAmount(item.number, false) }}
									</div>
								</div>
							</div>
						</div>
						<div class="bg-alt- mt-1 p-1 rounded text-center">
							<span class="text-6 text-white-">TAGS</span>
							<p class="text-6 p-0 m-0 text-yellow-">
								#Documentation #Cloud #Messagerie #Gestion
							</p>
						</div>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import LoadingMain from '@/components/LoadingMain.vue';
import Helper from '@/helpers/helper';
import { useDispositionStore } from '@/store/disposition';

export default {
	setup() {
		const store = useDispositionStore();
		return { store };
	},
	name: 'HomePage',
	components: { Template, LoadingMain },
	data() {
		return {
			selectedList: [],
			allIsCheck: false,
			page: 1,
			perPage: 12,
			deleted: false,
			archived: false,
			loading: false,
			disposition: 'list',
			orderBy: 'id',
			order: 'DESC',
			recents: [],
			documents: [],
			allList: [],
			searchText: "",
			stats: [
				{
					folder: '-',
					number: 0,
				},
			],
		};
	},
	methods: {
		sizeConverter(bytes) {
			return Helper.sizeConverter(bytes);
		},
		search() {
			if (this.searchText != "") {
				this.searchText = this.searchText.toLowerCase()
				const founds = this.allList.filter(element =>
					element.name?.toLowerCase().includes(this.searchText) 
				);

				this.documents = founds
			} else {
				this.documents = this.allList
			}
		},
		check(id, all = false) {
			if (all) {
				if (this.selectedList.length !== this.documents.length) {
					this.selectedList.length = 0;
					this.documents.forEach((employee) => {
						this.selectedList.push({ id: employee.id });
					});
				} else {
					this.selectedList.length = 0;
				}
			} else {
				let found = false;
				this.selectedList.forEach((element) => {
					if (element.id == id) {
						found = true;
					}
				});
				if (found) {
					this.selectedList.filter((value, index) => {
						if (value.id == id) {
							this.selectedList.splice(index, 1);
						}
					});
				} else {
					this.selectedList.push({ id: id });
				}
			}
			if (this.selectedList.length === this.documents.length) {
				this.allIsCheck = true;
			} else {
				this.allIsCheck = false;
			}
		},
		show(index) {
			return (
				index < this.page * this.perPage &&
				index + 1 > (this.page - 1) * this.perPage
			);
		},
		next() {
			if (this.page * this.perPage < this.documents.length) {
				this.page++;
			}
		},
		previous() {
			if (this.page >= 2) {
				this.page--;
			}
		},
		isSelected(id) {
			let found = false;
			this.selectedList.forEach((element) => {
				if (element.id == id) {
					found = true;
				}
			});
			return found;
		},
		loadList() {
			this.loading = true;
			fetch(
				Helper.route(
					'admin/document/all/valid?sortBy=' +
						this.orderBy +
						'&order=' +
						this.order
				),
				Helper.requestOptions()
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					// console.log(response.json())
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					
					if (data.data) {
						this.documents = data.data;
						this.recents = data.recents;
						this.allList = this.documents
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		loadStats() {
			this.loading = true;
			fetch(Helper.route('admin/document/stats'), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					this.stats = data.data.documents;
					this.stats = this.stats.filter((s) => s.number > 0);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		favourite(document) {
			this.$isLoading(true);
			let registerFormData = new FormData();

			registerFormData.append('reference', document.reference);

			fetch(
				Helper.route('admin/document/favourite'),
				Helper.requestOptions('POST', registerFormData)
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					this.$isLoading(false);
					console.log(data);
					let document_new = document;
					document_new.isFavourite = data.status;
					this.documents = this.documents.map((i) =>
						i.id !== document.id ? i : document_new
					);
					Helper.notification('success', data.message);
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
		},
		archives() {
			this.$isLoading(true);
			let body = {
				ids: this.selectedList.map((element) => element.id),
			};
			fetch(
				Helper.route('admin/document/archive'),
				Helper.requestOptionsJSON('PUT', body)
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					this.$isLoading(false);
					console.log(data);
					this.archived = true;
					for (let i = 0; i < this.selectedList.length; i++) {
						const element = this.selectedList[i];
						this.documents = this.documents.filter((i) => i.id !== element.id);
						this.recents = this.recents.filter((i) => i.id !== element.id);
					}
					this.selectedList = [];
					// Helper.notification("success", data.message)
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
		},
		deletes() {
			this.$isLoading(true);
			let body = {
				ids: this.selectedList.map((element) => element.id),
			};
			fetch(
				Helper.route('admin/document/delete'),
				Helper.requestOptionsJSON('DELETE', body)
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					this.$isLoading(false);
					console.log(data);
					this.deleted = true;
					for (let i = 0; i < this.selectedList.length; i++) {
						const element = this.selectedList[i];
						this.documents = this.documents.filter((i) => i.id !== element.id);
					}
					this.selectedList = [];
					// Helper.notification("success", data.message)
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
		},
	},
	created() {
		this.loadList();
		this.loadStats();
		// fetch(Helper.route('admin/document/recent'), Helper.requestOptions())
		// .then(response => response.json())
		// .then(data => {
		//     if (data.data) {
		//         this.recents = data.data
		//     }
		// })
	},
	watch: {
		searchText: function () {
			this.search();
		},
		orderBy: function (n) {
			if (n == 'size') {
				if (this.order === 'ASC') {
					this.documents.sort((a, b) => a.file.size - b.file.size);
				} else {
					this.documents.sort((a, b) => b.file.size - a.file.size);
				}
			} else if (n === 'folder') {
				if (this.order === 'ASC') {
					this.documents.sort((a, b) =>
						a.folder.name.localeCompare(b.folder.name)
					);
				} else {
					this.documents.sort((a, b) =>
						b.folder.name.localeCompare(a.folder.name)
					);
				}
			} else {
				this.loadList();
			}
		},
		order: function (ord) {
			if (this.orderBy == 'size') {
				if (ord === 'ASC') {
					this.documents.sort((a, b) => a.file.size - b.file.size);
				} else {
					this.documents.sort((a, b) => b.file.size - a.file.size);
				}
			} else if (this.orderBy === 'folder') {
				if (ord === 'ASC') {
					this.documents.sort((a, b) =>
						a.folder.name.localeCompare(b.folder.name)
					);
				} else {
					this.documents.sort((a, b) =>
						b.folder.name.localeCompare(a.folder.name)
					);
				}
			} else {
				this.loadList();
			}
		},
		searchText: function () {
			this.search();
		},
	},
};
</script>
<style>
.rate_item img {
	height: 70px;
	width: 70px;
	object-fit: cover;
}
.documents th,
.documents td {
	color: white !important;
}
.employe_item img {
	height: 100px;
	width: 100px;
}
</style>
