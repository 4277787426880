<template>
    <div v-if="correct == 1">
        <div class="py-3 px-5 fs-4 fw-800 bg-white- text-primary-">
            PRO GESTION SOFT
        </div>
        <hr class="m-0">
        <div class="bg-light- py-5" style="height: calc(100vh - 12vh)">
            <div class="container h-100">
                <div class="row h-100">
                    <div class="my-auto">
                        <div class="row py-5">
                            <div class="col-md-6 text-center">
                                <img src="@/assets/images/SANS_FOND_SIGNATURE.png" alt="">
                                <p class="text-center fw-600 text-4 text-three- mt-2">{{ $t('auth.invite_first_title') }}</p>
                                <h2 class="text-primary- text-center fs-6 lh-lg mt-4">{{ $t('auth.invite_second_title') }}</h2>
                            </div>
                            <div class="col-md-6">
                                <h5 class="text-center text-primary- text-4 text-uppercase mb-4">{{$t('auth.define_pwd')}}</h5>
                                <div class="col-lg-8 rounded bg-primary-light- mx-auto border-black- shadow">
                                    <form @submit.prevent="accept" class="py-4 px-4">
                                        <div class="error mb-4">{{ error_message }}</div>
                                        <InputMe :type="'password'" v-model.trim="password" :error="errors.password" :placeholder="$t('auth.pwd')" class="mt-4" /> 
                                        <InputMe :type="'password'" v-model.trim="password_confirm" :error="errors.password_confirm" :placeholder="$t('auth.conf_pwd')" class="mt-4" />      
                                        <SpinnerMe :elementId="'spinner'" />                  
                                        <input type="submit" :value="$t('global.accept')" id="submit" class="button mt-4 w-100">
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </div>
    <div class="bg-light-" v-if="correct == 0">
        <div class="py-3 px-5 fs-4 fw-800 bg-white- text-primary-">
            PRO GESTION SOFT
        </div>
        <hr class="m-0">
        <div class="container" style="height: 72vh">
            <div class="row h-100">
                <div class="my-auto mx-auto">
                    <h1 class="text-alt- fw-900 text-center" style="font-size: 120px; letter-spacing: 30px">404</h1>
                    <h4 class="text-red- fw-600 text-center mt-4">{{$('auth.invitation_link')}}</h4>
                </div>
            </div>
        </div>
        
        <Footer />
    </div>
</template>

<style>
img {
    height: 170px;
}
</style>

<script>
import Footer from '@/components/Footer.vue'
import InputMe from '@/components/InputMe.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
import Helper from '@/helpers/helper';
export default {
    name: "InvitePage",
    data() {
        return {
            password: "", 
            password_confirm: "", 
            error_message: "",
            message: "",
            ok: true,
            correct: -1,
            errors: [],
            reference: '',
        };
    },
    components: { Footer, InputMe, SpinnerMe },
    methods: {
        accept() {  
            console.log("ok");       
            if (this.beforeAccept()) {
                this.ok = true
                this.error_message = ""
                this.message = ""
                Helper.loading("submit", "spinner", true)

                let registerFormData = new FormData()
                registerFormData.append('password', this.password)
                registerFormData.append('reference', this.reference)

                fetch(Helper.route('public/coadmin/invitation/accept'), Helper.requestOptions("POST", registerFormData))
                .then(response => {
                    if (response.status === 401) {
                        this.ok = false
                    } 
                    return response.json()
                })
                .then(data => {
                    console.log(data);
                    Helper.loading("submit", "spinner", false) 

                    if (data.problems) {
                        this.errors.password = Helper.getError(data.problems, "password")
                    } else {
                        if(!this.ok) {
                            this.error_message = data.message       
                        } else {
                            if (data.status) {
                                Helper.redirect(Helper.route('login', null, false))
                                Helper.notification('success', data.message)
                            } 
                        }
                    }
                    
                })
                .catch(error => {
                    console.log(error)
                    Helper.loading("submit", "spinner", false)
                });
            }
        },
        beforeAccept() {
            if (Helper.required(this.password) === "") {
                delete this.errors.password
            } else {
                this.errors.password = Helper.required(this.password)
            }
            if (Helper.required(this.password_confirm) === "") {
                if (this.password == this.password_confirm) {
                    delete this.errors.password_confirm
                } else {
                    this.errors.password_confirm = "Les deux mots de passe ne sont pas identiques"
                }
            } else {
                this.errors.password_confirm = Helper.required(this.password_confirm)
            }
            let a = Helper.objectNumberKey(this.errors) == 0 ? true : false
            console.log(a);
            return a
        },
        verify() {
            if (this.reference != "") {
                this.$isLoading(true)                
                fetch(Helper.route('public/coadmin/invitation/verify/'+this.reference), Helper.requestOptions())
                .then(response => {
                    Helper.tokenChecker(response.status)
                    return response.json()
                })
                .then(data => {
                    this.$isLoading(false)
                    console.log(data);
                    if (data.status == true) {
                        this.correct = 1
                    } else {
                        this.correct = 0
                    }
                })
                .catch(error => {
                    this.$isLoading(false)
                    console.log(error)
                });
            } else {
                
            }
        }
    },
    watch: {
        password: function() {
            if (Helper.required(this.password) === "") {
                delete this.errors.password
            } else {
                this.errors.password = Helper.required(this.password)
            }
        },
        password_confirm: function() {
            if (Helper.required(this.password_confirm) === "") {
                if (this.password == this.password_confirm) {
                    delete this.errors.password_confirm
                } else {
                    this.errors.password_confirm = "Les deux mots de passe ne sont pas identiques"
                }
            } else {
                this.errors.password_confirm = Helper.required(this.password_confirm)
            }
        },
    },
    created() {
        this.reference = this.$route.params.reference
        this.verify()
    }
}
</script>