<template>
    <div class="row text-center">
        <div class="spinner-border text-primary- loadingmain" role="status" v-if="state">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>

<style>
.loadingmain {
    height: 100px;
    width: 100px;
    margin: 50px auto;
}
</style>

<script>
export default {
    name: "LoadingMain", 
    props: {
        state: {
            default: false
        }
    }
}
</script>