let exports = {};

import "notyf/notyf.min.css";

import { Notyf } from "notyf";
import moment from "moment";

// const API_URL = "https://api.pgs.ctrlengine.com/";
// const SITE_URL = "http://localhost:8080/";
// const APP_URL = "http://localhost:8080/"; 
// const CLIENT_TOKEN = "jwt";
// const KKIAPAY_KEY = "9282b590653011efbf02478c5adba4b8"; 
// const DEFAULT_IMAGE = "https://i.im.ge/2022/10/08/1BUv0G.user.jpg";
// const TEST_URL = "https://hire.pgs.ctrlengine.com/";

const API_URL = "https://api.pgs.ctrlengine.com/";
const SITE_URL = "https://site.pgs.ctrlengine.com/";
const APP_URL = "https://web.pgs.ctrlengine.com/";
const CLIENT_TOKEN = "jwt";
const KKIAPAY_KEY = "9282b590653011efbf02478c5adba4b8";
const DEFAULT_IMAGE = "https://i.im.ge/2022/10/08/1BUv0G.user.jpg";
const TEST_URL = "https://hire.pgs.ctrlengine.com/";

const NOTYF = new Notyf({
  duration: 6000,
  position: {
    x: "center",
    y: "bottom",
  },
  types: [
    {
      type: "warning",
      background: "orange",
      icon: {
        className: "material-icons",
        tagName: "i",
        text: "warning",
      },
    },
    {
      type: "error",
      background: "indianred",
      duration: 5000,
      dismissible: true,
    },
    {
      type: "success",
      background: "green",
      duration: 5000,
      dismissible: true,
    },
  ],
});

exports.CLIENT_TOKEN = CLIENT_TOKEN;
exports.DEFAULT_IMAGE = DEFAULT_IMAGE;
exports.NOTYF = NOTYF;
exports.KKIAPAY_KEY = KKIAPAY_KEY;
exports.SITE_URL = SITE_URL;
exports.TEST_URL = TEST_URL;

exports.requestOptions = (
  method = "GET",
  formdata = new FormData(),
  signal = undefined
) => {
  let myHeaders = new Headers();

  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append(
    "Access-Control-Allow-Headers",
    "Origin, X-Requested-With, Content-Type, Accept"
  );

  if (exports.isConnected()) {
    myHeaders.append("Authorization", "Bearer " + exports.clientToken());
  }

  let requestOptions = {
    method: method,
    headers: myHeaders,
    redirect: "follow",
    signal: signal,
  };

  if (method != "GET") {
    requestOptions.body = formdata;
  }

  return requestOptions;
};

exports.requestOptionsJSON = (method = "GET", body = {}) => {
  let myHeaders = new Headers();

  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append(
    "Access-Control-Allow-Headers",
    "Origin, X-Requested-With, Content-Type, Accept"
  );
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('accept', 'application/json',)

  if (exports.isConnected()) {
    myHeaders.append("Authorization", "Bearer " + exports.clientToken());
  }
  

  let requestOptionsJSON = {
    method: method,
    headers: myHeaders,
    redirect: "follow",
  };

  if (method != "GET") {
    requestOptionsJSON.body = JSON.stringify(body);
  }

  return requestOptionsJSON;
};

exports.tokenChecker = (status) => {
  if (status == 422) {
    localStorage.removeItem(exports.CLIENT_TOKEN);
    exports.redirect(exports.route("login", null, false));
    localStorage.setItem("logout_message", "token_expire");
  }
  if (status == 401) {
    exports.redirect(exports.route("not-right", null, false));
  }
};

exports.html_entity_decode = (message) => {
  return message.replace(/[<>'"]/g, function (m) {
    return (
      "&" +
      {
        "'": "apos",
        '"': "quot",
        "&": "amp",
        "<": "lt",
        ">": "gt",
      }[m] +
      ";"
    );
  });
};

exports.required = (input) => {
  if (input != null) {
    if (input.length === 0) {
      return "Veuillez remplir ce champs";
    } else {
      return "";
    }
  } else {
    return "";
  }
};

exports.checkEmail = (input) => {
  var filter =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  if (filter.test(input)) {
    return "";
  } else {
    return "Email incorrect";
  }
};

exports.checkPassword = (input) => {
  if (input.length < 8) {
    return "Au moins 8 caractères";
  } else if (!/[A-Z]/.test(input)) {
    return "Au moins une lette majuscule";
  } else if (!/[a-z]/.test(input)) {
    return "Au moins une lette minuscule";
  } else if (!/\d/.test(input)) {
    return "Au moins un chiffre";
  } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(input)) {
    return "Au moins un caractère spécial";
  } else {
    return "";
  }
};

exports.toDate = (date) => {
  const d = new Date(date);
  let year = d.getFullYear();
  let month = d.getMonth() + 1;
  let day = d.getDate();

  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  return day + "/" + month + "/" + year;
};

exports.plurial = (message = "", length = 0) => {
  if (length > 1) {
    return " " + message + "s ";
  } else {
    return " " + message + " ";
  }
};

exports.redirect = (route) => {
  window.location.href = route;
};

exports.downloadFile = (l, name, ctx) => {
  ctx.$isLoading(true);
  fetch(l)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", name);
      link.click();

      window.URL.revokeObjectURL(url);
      ctx.$isLoading(false);
    })
    .catch((error) => {
      console.error("Error downloading file:", error);
      ctx.$isLoading(false);
    });
};

exports.route = (pagePath, args = null, api = true) => {
  if (args != null) {
    for (let i = 0; i < args.length; i++) {
      if (i === 0) {
        pagePath = pagePath + args[i];
      } else {
        pagePath = pagePath + "-" + args[i];
      }
    }
  }
  return (api ? API_URL + "api/v1/" : APP_URL + "") + pagePath;
};

exports.asset = (path) => {
  return API_URL + path;
};

exports.isset = (element) => {
  return typeof element !== "undefined" ? true : false;
};

exports.notification = (type, message) => {
  const notyf = NOTYF;
  if (type == "success") {
    notyf.success(message);
  } else if (type == "error") {
    notyf.error(message);
  } else {
    notyf.warning(message);
  }
};

exports.loading = (action = true) => {
  if (action) {
    document.getElementById("loadingModal").classList.remove("d-none");
    document.getElementById("loadingModal1").classList.remove("d-none");
  } else {
    document.getElementById("loadingModal").classList.add("d-none");
    document.getElementById("loadingModal1").classList.add("d-none");
  }
};

exports.setLocalstorage = (cname, cvalue) => {
  localStorage.setItem(cname, cvalue);
  return true;
};

exports.deleteLocalstorage = (cname) => {
  localStorage.removeItem(cname);
  return true;
};

exports.getLocalstorage = (cname) => {
  let content = localStorage.getItem(cname);
  if (content != null) {
    return content;
  }
  return "";
};

exports.checkLocalstorage = (cname) => {
  let cookie = exports.getLocalstorage(cname);
  if (cookie != "") {
    return true;
  } else {
    return false;
  }
};

exports.isConnected = () => {
  return exports.checkLocalstorage(CLIENT_TOKEN);
};

exports.clientToken = () => {
  return exports.getLocalstorage(CLIENT_TOKEN);
};

exports.validateUserName = (username) => {
  var usernameRegex = /^[a-zA-Z0-9]+$/;
  return usernameRegex.test(username);
};

exports.getError = (problems, key) => {
  for (let i = 0; i < problems.length; i++) {
    const element = problems[i];
    if (element[key] != undefined) {
      return element[key];
    }
  }
  return "";
};

exports.objectNumberKey = (obj) => {
  return Object.keys(obj).length;
};

exports.loading = (button, spinner, status = true) => {
  if (status) {
    document.getElementById(spinner)?.classList.remove("d-block");
    document.getElementById(button)?.classList.add("d-none");
    document.getElementById(spinner)?.classList.remove("d-none");
    document.getElementById(button)?.classList.add("d-block");
  } else {
    document.getElementById(button)?.classList.remove("d-block");
    document.getElementById(spinner)?.classList.add("d-none");
    document.getElementById(button)?.classList.remove("d-none");
    document.getElementById(spinner)?.classList.add("d-block");
  }
};

exports.truncate = (text, length, clamp = "...") => {
  return text.length > length ? text.slice(0, length) + clamp : text;
};
exports.capitalize = (value) => {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

exports.timestampToDate = (timestamp, withoutHour = true) => {
  let date = new Date(timestamp);
  if (withoutHour) {
    return date.customFormat("DD/MM/YYYY");
  }
  return date.customFormat("DD/MM/YYYY à hh:mm:ss");
};

exports.sections = (sections) => {
  let section = "";
  if (sections.length > 0) {
    sections.forEach((element) => {
      if (section != "") {
        section += ";";
      }
      section += " " + element.name;
    });
    return section;
  } else {
    return "";
  }
};

exports.employees = (employees) => {
  let employee = "";
  if (employees.length > 0) {
    let emps = [...employees];

    if (emps.length > 2) emps = emps.slice(0, 2);

    emps.forEach((element) => {
      if (employee != "") {
        employee += ",";
      }
      employee += " " + element.lastname;
    });
    return employees.length > 2
      ? employee +
          ` + ${employees.length - 2} autre${
            employees.length - 2 > 1 ? "s" : ""
          }`
      : employee;
  } else {
    return "Aucun";
  }
};

exports.jobs = (jobs) => {
  let job = "";
  if (jobs.length > 0) {
    jobs.forEach((element) => {
      if (job != "") {
        job += ";";
      }
      job += " " + element.designation;
    });
    return job;
  } else {
    return "";
  }
};

exports.states = [
  {
    id: "au poste",
    name: "Poste",
  },
  {
    id: "en congé",
    name: "Congé",
  },
  {
    id: "en vacance",
    name: "Vacances",
  },
];

exports.genders = [
  {
    id: "M",
    name: "Masculin",
  },
  {
    id: "F",
    name: "Féminin",
  },
];

exports.objects = [
  {
    id: "Tableau de bord",
    name: "Tableau de bord",
  },
  {
    id: "Courriels",
    name: "Courriels",
  },
  {
    id: "Gestion des ressources humaines",
    name: "Gestion des ressources humaines",
  },
  {
    id: "Documents",
    name: "Documents",
  },
  {
    id: "Candidatures",
    name: "Candidatures",
  },
  {
    id: "Outils",
    name: "Outils",
  },
  {
    id: "Programme de gestion intégrée",
    name: "Programme de gestion intégrée",
  },
];

exports.contract_types = [
  {
    id: "CDI",
    name: "CDI",
  },
  {
    id: "CDD",
    name: "CDD",
  },
  {
    id: "STAGE",
    name: "Stagiaire",
  },
];

exports.matrimonialStutuses = [
  {
    id: "Célibataire",
    name: "Célibataire",
  },
  {
    id: "Relation Libre",
    name: "Relation Libre",
  },
  {
    id: "Marié(e)",
    name: "Marié(e)",
  },
  {
    id: "Veuf/Veuve",
    name: "Veuf/Veuve",
  },
  {
    id: "Divorcé(e)",
    name: "Divorcé(e)",
  },
];

exports.rates = [
  {
    id: 1,
    name: 1,
  },
  {
    id: 1.5,
    name: 1.5,
  },
  {
    id: 2,
    name: 2,
  },
  {
    id: 2.5,
    name: 2.5,
  },
  {
    id: 3,
    name: 3,
  },
  {
    id: 3.5,
    name: 3.5,
  },
  {
    id: 4,
    name: 4,
  },
  {
    id: 4.5,
    name: 4.5,
  },
  {
    id: 5,
    name: 5,
  },
];

exports.boolean_choices = [
  {
    id: true,
    name: "Oui",
  },
  {
    id: false,
    name: "Non",
  },
];

exports.payment_methods = [
  {
    id: "Espèce",
    name: "Espèce",
  },
  {
    id: "Chèque",
    name: "Chèque",
  },
  {
    id: "Transaction Mobile",
    name: "Transaction Mobile",
  },
  {
    id: "Carte Bancaire",
    name: "Carte Bancaire",
  },
  {
    id: "Virement",
    name: "Virement",
  },
  {
    id: "Credit",
    name: "Credit",
  },
  {
    id: "Autre",
    name: "Autre",
  },
];

exports.priority_list = [
  {
    name: "Elevée",
    id: "high",
  },
  {
    name: "Moyenne",
    id: "medium",
  },
  {
    name: "Faible",
    id: "low",
  },
];

exports.priority_list_to_color = () => {
  const fr = localStorage.getItem("language") == "fr";
  return [
    {
      name: fr ? "Elevée" : "High",
      id: "high",
      color: "danger",
    },
    {
      name: fr ? "Moyenne" : "Medium",
      id: "medium",
      color: "warning",
    },
    {
      name: fr ? "Faible" : "Low",
      id: "low",
      color: "success",
    },
  ];
};

exports.application_status = () => {
  const fr = localStorage.getItem("language") == "fr";
  return [
    {
      name: fr ? "En cours" : "Pending",
      id: "pending",
    },
    {
      name: fr ? "Acceptée" : "Accepted",
      id: "accepted",
    },
    {
      name: fr ? "Refusée" : "Refused",
      id: "refused",
    },
  ];
};

exports.mail_processing_status = () => {
  const fr = localStorage.getItem("language") == "fr";
  return [
    {
      name: fr ? "A traiter" : "Waiting Process",
      id: "saved",
    },
    {
      name: fr ? "Traité" : "Processed",
      id: "processed",
    },
  ];
};

exports.mail_processing_status_to_color = () => {
  const fr = localStorage.getItem("language") == "fr";
  return [
    {
      name: fr ? "A traiter" : "Waiting",
      id: "saved",
      color: "gray",
    },
    {
      name: fr ? "En cours" : "In Progress",
      id: "inprogress",
      color: "warning",
    },
    {
      name: fr ? "Traité" : "Processed",
      id: "processed",
      color: "success",
    },
  ];
};

exports.sizeConverter = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

exports.convertDateToForm = (date, hour = false) => {
  if (!hour) {
    return moment(date).local().format("YYYY-MM-DD");
  } else {
    return moment(date).local().format("YYYY-MM-DD H:mm");
  }
};

// exports.convertDateToForm = (date) => {
//   return moment.utc(date).format('YYYY-MM-DD');
// }

exports.extractIdsFromListObj = (lobj) => {
  const ids = [];

  lobj.forEach((obj) => {
    obj && obj.id
      ? ids.push(obj.id)
      : obj && typeof obj == "number"
      ? ids.push(obj)
      : ids;
  });

  return ids;
};

export default exports;
