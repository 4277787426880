<template>
	<div>
		<Template :title="$t('training.new_training')" sidebarMenu="employees">
			<section class="p-5 py-4">
				<Back />

				<div class="row">
					<div class="col-xl-9">
						<div
							class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 py-1"
						>
							{{$t('training.add_new_training')}}
						</div>
						<div class="bg-light-hight- rounded mt-4 py-5">
							<form
								@submit.prevent="formationNew"
								class="col-lg-10 col-md-11 mx-auto"
							>
								<div class="mb-4 text-primary-" v-if="message != ''">
									{{ message }}
								</div>
								<div class="mb-4 error" v-if="error_message != ''">
									{{ error_message }}
								</div>
								<div class="row">
									<InputFC
										:error="errors.name"
										:type="'text'"
										v-model="name"
										:placeholder="$t('training.training_name')"
									/>
								</div>
								<div class="row">
									<div class="col-lg-6 mt-4">
										<label for="">{{$t('training.sections')}}</label>
										<v-select
											multiple
											class="bg-white mt-1"
											v-model="section"
											:options="sections"
											:reduce="(section) => section.id"
											label="name"
										></v-select>
										<span
											v-show="errors.section != ''"
											v-text="errors.section"
											class="error"
										></span>
									</div>
									<div class="col-lg-6 mt-4">
										<label for="">{{$t('training.add_participants')}}</label>
										<v-select
											multiple
											class="bg-white mt-1"
											v-model="employee"
											:options="employees"
											:reduce="(employee) => employee.id"
											label="name"
										></v-select>
										<span
											v-show="errors.employee != ''"
											v-text="errors.employee"
											class="error"
										></span>
									</div>
								</div>
								<hr />

								<fieldset class="p-2 border">
									<legend class="text-center text-4">{{$t('training.start')}}</legend>
									<div class="row">
										<InputFC
											:error="errors.startDate"
											:type="'date'"
											class="col"
											v-model="startDate"
											:placeholder="$t('global.date')"
										/>
										<InputFC
											:error="errors.startTime"
											:type="'time'"
											class="col"
											v-model="startTime"
											:placeholder="$t('global.time')"
										/>
									</div>
								</fieldset>
								<hr />

								<fieldset class="p-2 border">
									<legend class="text-center text-4">{{$t('training.end')}}</legend>
									<div class="row">
										<InputFC
											:error="errors.endDate"
											:type="'date'"
											class="col"
											v-model="endDate"
											:placeholder="$t('global.date')"
										/>
										<InputFC
											:error="errors.endTime"
											:type="'time'"
											class="col"
											v-model="endTime"
											:placeholder="$t('global.time')"
										/>
									</div>
								</fieldset>

								<div class="row mt-4">
									<InputFC
										:error="errors.nature"
										:options="natures"
										:type="'select'"
										v-model="nature"
										:placeholder="$t('training.nature')"
									/>
								</div>
								<div class="row mt-4">
									<InputFC
										:error="errors.linkOrPlace"
										:type="'text'"
										v-model="linkOrPlace"
										:placeholder="$t('training.place')"
									/>
								</div>

								<InputFC
									:error="errors.description"
									:type="'textarea'"
									class="mt-4"
									v-model="description"
									:placeholder="$t('global.description')"
								/>

								<div class="hstack">
									<div class="">
										<SpinnerMe :elementId="'spinner'" />
										<button id="submit" class="button px-4 py-1 mt-4">
											{{ $t('global.send') }}
										</button>
									</div>
									<div class="ms-3">
										<!--@click="$router.go(-1)"-->
										<button
											class="button-default bg-light- text-color- fw-500 shadow-sm px-4 py-1 mt-4"
										>
											{{ $t('global.cancel') }}
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div class="col-xl-3">
						<div class="rounded bg-second- text-white text-4 text-center p-1">
							{{$t('global.advice')}}
						</div>
						<div
							class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
						>
							{{$t('training.create_advice_text')}}
						</div>
						<router-link to="/calendar-new"
							><button class="button bg-alt- btn-sm w-100 mt-1 p-1">
								{{$t('training.add_task_now')}}
							</button>
						</router-link>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import InputFC from '@/components/InputFC.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
import Helper from '@/helpers/helper';

export default {
	name: 'HomePage',
	components: { Template, Back, InputFC, SpinnerMe },
	data() {
		return {
			errors: [],
			error_message: '',
			message: '',
			ok: false,
			check_form: false,
			originalSections: [],
			sections: [],
			section: [],
			name: '',
			nature: '',
			startDate: '',
			startTime: '',
			endDate: '',
			endTime: '',
			linkOrPlace: '',
			description: '',
			employees: [],
			employee: [],
			natures: [
				{
					id: 1,
					name: 'En ligne',
				},
				{
					id: 0,
					name: 'En Présentiel',
				},
			],
		};
	},
	methods: {
		formationNew() {
			this.check_form = true;
			if (this.beforeAdd()) {
				this.ok = true;
				this.error_message = '';
				this.message = '';
				Helper.loading('submit', 'spinner', true);

				const body = {
					name: this.name,
					nature: this.nature,
					startDate: new Date(
						this.startDate + ' ' + this.startTime
					).toISOString(),
					// startTime: this.startTime,
					endDate: new Date(this.endDate + ' ' + this.endTime).toISOString(),
					// endTime: this.endTime,
					linkOrPlace: this.linkOrPlace,
					description: this.description,
					sections: this.section.map((e) => (e.id ? e.id : e)),
					employees: this.employee.map((e) => (e.id ? e.id : e)),
				};

				fetch(
					Helper.route('admin/training/add'),
					Helper.requestOptionsJSON('POST', body)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						if (response.status != 200) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						this.check_form = false;
						Helper.loading('submit', 'spinner', false);

						if (data.problems) {
							this.errors.name = Helper.getError(data.problems, 'name');
							this.errors.nature = Helper.getError(data.problems, 'nature');
							this.errors.startDate = Helper.getError(
								data.problems,
								'startDate'
							);
							this.errors.startTime = Helper.getError(
								data.problems,
								'startTime'
							);
							this.errors.endDate = Helper.getError(data.problems, 'endDate');
							this.errors.endTime = Helper.getError(data.problems, 'endTime');
							this.errors.linkOrPlace = Helper.getError(
								data.problems,
								'linkOrPlace'
							);
							this.errors.description = Helper.getError(
								data.problems,
								'description'
							);
							this.errors.section = Helper.getError(data.problems, 'sections');
							this.errors.employee = Helper.getError(
								data.problems,
								'employees'
							);
							this.error_message = Helper.getError(data.problems, 'date');
							Helper.notification('error', this.error_message);
						} else {
							if (!this.ok) {
								this.error_message = data.message;
								Helper.notification('error', this.error_message);
							} else {
								if (data.status) {
									this.message = data.message;
									Helper.notification('success', this.message);
									this.name = '';
									this.nature = '';
									this.startDate = '';
									this.startTime = '';
									this.endDate = '';
									this.endTime = '';
									this.linkOrPlace = '';
									this.description = '';
									this.section = [];
									this.employee = [];
								}
							}
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit', 'spinner', false);
					});
			}
		},
		beforeAdd() {
			if (Helper.required(this.name) === '') {
				delete this.errors.name;
			} else {
				this.errors.name = Helper.required(this.name);
			}
			if (Helper.required(this.nature) === '') {
				delete this.errors.nature;
			} else {
				this.errors.nature = Helper.required(this.nature);
			}
			if (Helper.required(this.startDate) === '') {
				delete this.errors.startDate;
			} else {
				this.errors.startDate = Helper.required(this.startDate);
			}
			if (Helper.required(this.startTime) === '') {
				delete this.errors.startTime;
			} else {
				this.errors.startTime = Helper.required(this.startTime);
			}
			if (Helper.required(this.endDate) === '') {
				delete this.errors.endDate;
			} else {
				this.errors.endDate = Helper.required(this.endDate);
			}
			if (Helper.required(this.endTime) === '') {
				delete this.errors.endTime;
			} else {
				this.errors.endTime = Helper.required(this.endTime);
			}
			if (Helper.required(this.linkOrPlace) === '') {
				delete this.errors.linkOrPlace;
			} else {
				this.errors.linkOrPlace = Helper.required(this.linkOrPlace);
			}
			if (Helper.required(this.description) === '') {
				delete this.errors.description;
			} else {
				this.errors.description = Helper.required(this.description);
			}
			if (Helper.required(this.section) === '') {
				delete this.errors.section;
			} else {
				this.errors.section = Helper.required(this.section);
			}
			if (Helper.required(this.employee) === '') {
				delete this.errors.employee;
			} else {
				this.errors.employee = Helper.required(this.employee);
			}
			return Helper.objectNumberKey(this.errors) == 0 ? true : false;
		},
		reloadState() {
			this.employee = this.employee.map((e) => e);
		},
		autofillEmployee(v) {
			const lastSelected = this.originalSections.find(
				(j) => j.id == v[v.length - 1]
			);

			if (lastSelected) {
				for (const e of lastSelected.employees) {
					if (
						!this.employee.find((i) => i.id == e.id) &&
						!this.employee.find((i) => i == e.id)
					) {
						this.employee.push({
							id: e.id,
							name: e.lastname + ' ' + e.firstname,
						});
					}
				}
			}

			this.reloadState();
		},
	},
	created() {
		fetch(Helper.route('admin/section/all'), Helper.requestOptions())
			.then((response) => response.json())
			.then((data) => {
				if (data.data) {
					this.originalSections = data.data;

					data.data.forEach((section) => {
						this.sections.push({
							id: section.id,
							name: section.name,
						});
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});

		fetch(Helper.route('admin/employee/all'), Helper.requestOptions())
			.then((response) => response.json())
			.then((data) => {
				if (data.data) {
					data.data.forEach((employee) => {
						this.employees.push({
							id: employee.id,
							name: employee.lastname + ' ' + employee.firstname,
						});
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	},
	watch: {
		name: function () {
			if (this.check_form) {
				if (Helper.required(this.name) === '') {
					delete this.errors.name;
				} else {
					this.errors.name = Helper.required(this.name);
				}
			}
		},
		startDate: function () {
			if (this.check_form) {
				if (Helper.required(this.startDate) === '') {
					delete this.errors.startDate;
				} else {
					this.errors.startDate = Helper.required(this.startDate);
				}
			}
		},
		startTime: function () {
			if (this.check_form) {
				if (Helper.required(this.startTime) === '') {
					delete this.errors.startTime;
				} else {
					this.errors.startTime = Helper.required(this.startTime);
				}
			}
		},
		endDate: function () {
			if (this.check_form) {
				if (Helper.required(this.endDate) === '') {
					delete this.errors.endDate;
				} else {
					this.errors.endDate = Helper.required(this.endDate);
				}
			}
		},
		endTime: function () {
			if (this.check_form) {
				if (Helper.required(this.endTime) === '') {
					delete this.errors.endTime;
				} else {
					this.errors.endTime = Helper.required(this.endTime);
				}
			}
		},
		linkOrPlace: function () {
			if (this.check_form) {
				if (Helper.required(this.linkOrPlace) === '') {
					delete this.errors.linkOrPlace;
				} else {
					this.errors.linkOrPlace = Helper.required(this.linkOrPlace);
				}
			}
		},
		description: function () {
			if (this.check_form) {
				if (Helper.required(this.description) === '') {
					delete this.errors.description;
				} else {
					this.errors.description = Helper.required(this.description);
				}
			}
		},
		section: function (newV, oldV) {
			if (oldV.length < newV.length) {
				this.autofillEmployee(newV);
			}

			if (this.check_form) {
				if (Helper.required(this.section) === '') {
					delete this.errors.section;
				} else {
					this.errors.section = Helper.required(this.section);
				}
			}
		},
		employee: function () {
			if (this.check_form) {
				if (Helper.required(this.employee) === '') {
					delete this.errors.employee;
				} else {
					this.errors.employee = Helper.required(this.employee);
				}
			}
		},
		nature: function () {
			if (this.check_form) {
				if (Helper.required(this.nature) === '') {
					delete this.errors.nature;
				} else {
					this.errors.nature = Helper.required(this.nature);
				}
			}
		},
	},
};
</script>
<style></style>
