<template>
	<div>
		<!-- <label for="" v-if="type == 'date' || type == 'time'">{{ placeholder }}</label> -->
		<label for="" v-if="placeholder != ''">{{ placeholder }}</label>

		<input
			:disabled="disabled"
			:min="min"
			:required="required == true"
			autocomplete="off"
			v-if="type != 'select' && type != 'textarea'"
			:type="type"
			:value="modelValue"
			@input="updateValue"
			class="form-control"
			:readonly="readonly"
		/>

		<textarea
			:disabled="disabled"
			:required="required == true"
			v-else-if="type === 'textarea'"
			rows="4"
			class="form-control"
			:value="modelValue"
			@input="updateValue"
		></textarea>

		<select
			:required="required == true"
			:disabled="disabled"
			class="form-control"
			v-else-if="type === 'select'"
			:value="modelValue"
			@input="updateValue"
		>
			<!-- <option value="" v-if="placeholder != ''" v-text="placeholder"></option> -->
			<option value=""></option>
			<option
				v-for="option in options"
				v-bind:value="option.id"
				:key="option.id"
			>
				{{ option.name }}
			</option>
		</select>

		<span v-show="error != ''" v-text="error" class="error"></span>
	</div>
</template>

<script>
export default {
	name: 'InputFC',
	props: {
		error: {
			default: '',
		},
		placeholder: {
			default: '',
		},
		min: {
			default: '',
		},
		modelValue: {
			default: '',
		},
		required: {
			default: false,
		},
		type: {
			default: 'text',
		},
		options: {
			default: [],
		},
		value: {
			default: 'Valider',
		},
		disabled: {
			default: false,
		},
		readonly: {
			default: false,
		},
		multiple: {
			default: false,
		},
	},
	methods: {
		updateValue(event) {
			this.$emit('update:modelValue', event.target.value);
		},
	},
};
</script>
