<template>
	<div>
		<Template title="null" sidebarMenu="mails">
			<div class="bg-light-hight- hstack py-3 ps-2 pb-3">
				<router-link
					to="/mail/all"
					v-if="ustore.user.isMainAdmin"
					class="bg-light-hight- ms-2 text-6 me-2 top-item"
					>{{ $t('mails.all_lettters') }}</router-link
				>
				<router-link
					to="/mail/treat"
					v-if="ustore.user.isMainAdmin"
					class="bg-light-hight- ms-2 text-6 me-2 top-item"
					>{{ $t('mails.treat_lettters') }}</router-link
				>
				<router-link
					to="/mail/not-treat"
					v-if="ustore.user.isMainAdmin"
					class="bg-light-hight- ms-2 text-6 me-2 top-item"
					>{{ $t('mails.not_treat_lettters') }}</router-link
				>
			</div>


			<section class="row px-4 m-0 g-4 mt-1">
				<!-- Processing -->
				<div
					class="modal fade"
					id="deleteModal"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="deleteModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content rounded-0">
							<div class="modal-body">
								<h6 class="text-center text-alt-">
									{{ $t('mails.mail_proccessing') }}
								</h6>
								<hr />
								<div>
									<InputFC
										:type="'textarea'"
										class="mb-2"
										v-model="message"
										:error="errors.message"
										:placeholder="'Message'"
									/>

									<div class="col-lg-12 mt-lg-0 mt-4 mb-2">
										<label for="file_">{{ $t('mails.attach') }}</label>
										<input
											type="file"
											required
											id="file_"
											class="form-control"
										/>
									</div>
								</div>
								<p class="text-5 text-center" v-show="deleted">
									{{ mail_processed }}
									<i class="uil uil-check-circle fs-4 text-primary-"></i>
								</p>
								<hr />
								<div class="row mb-2">
									<div class="col text-center">
										<button
											type="button"
											class="border bg-light mt-4 px-3 text-5 rounded py-1"
											data-mdb-dismiss="modal"
										>
											{{ $t('global.close') }}
										</button>
									</div>
									<div class="col text-center">
										<SpinnerMe :elementId="'processSpinner'" />
										<button
											type="button"
											id="processBtn"
											class="bg-success text-white- mt-4 px-3 rounded py-1 text-5 border-default- set-opacity"
											@click="processMail()"
										>
											{{ $t('global.validate') }}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row">

					<!-- Suppression -->
					<div
						class="modal fade"
						id="deleteModal"
						data-mdb-backdrop="static"
						data-mdb-keyboard="false"
						tabindex="-1"
						aria-labelledby="deleteModalLabel"
						aria-hidden="true"
					>
						<div class="modal-dialog modal-dialog-centered">
							<div class="modal-content rounded-0">
								<div class="modal-body">
									<h6 class="text-center text-red-">
										{{ $t('global.deletion_warning') }}
									</h6>
									<hr />
									<p class="text-5" v-show="!deleted" v-html="$t('mails.file_deletion_text')">
									
									</p>
									<p class="text-5 text-center" v-show="deleted">
										{{ $t('global.deleted_successfully') }}
										<i class="uil uil-check-circle fs-4 text-primary-"></i>
									</p>
									<hr />
									<div class="row">
										<div class="col text-center">
											<button
												type="button"
												class="border bg-light px-3 text-5 rounded py-1"
												data-mdb-dismiss="modal"
											>
												{{ $t('global.close') }}
											</button>
										</div>
										<div
											class="col text-center"
											v-show="!deleted && selectedList.length !== 0"
										>
											<button
												type="button"
												class="bg-red- text-white- px-3 rounded py-1 text-5 border-0 set-opacity"
												@click="deletes()"
											>
												{{ $t('global.delete') }}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- Archivage -->
					<div
						class="modal fade"
						id="archiveModal"
						data-mdb-backdrop="static"
						data-mdb-keyboard="false"
						tabindex="-1"
						aria-labelledby="archiveModalLabel"
						aria-hidden="true"
					>
						<div class="modal-dialog modal-dialog-centered">
							<div class="modal-content rounded-0">
								<div class="modal-body">
									<h6 class="text-center text-alt-">
										{{ $t('global.archiving_warning') }}
									</h6>
									<hr />
									<p class="text-5" v-show="!archived" v-html="$t('mails.doc_archiving_text')">
										
									</p>
									<p class="text-5 text-center" v-show="archived">
										{{ $t('global.archiving_successfully') }}
										<i class="uil uil-check-circle fs-4 text-primary-"></i>
									</p>
									<hr />
									<div class="row">
										<div class="col text-center">
											<button
												type="button"
												class="border bg-light px-3 text-5 rounded py-1"
												data-mdb-dismiss="modal"
											>
												{{ $t('global.close') }}
											</button>
										</div>
										<div
											class="col text-center"
											v-show="!archived && selectedList.length !== 0"
										>
											<button
												type="button"
												class="bg-alt- text-white- px-3 rounded py-1 text-5 border-0 set-opacity"
												@click="archives()"
											>
												Archiver
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>


					<div class="col-xl-9">
						<div v-if="!loading && urgents.length != 0">
							<div
								class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 p-1"
							>
								{{ $t('mails.most_urgent') }}
							</div>
							<div class="row mt-1 g-2">
								<div
									class="col-md-3"
									v-for="(mail, index) in urgents"
									:key="index"
									v-show="show(index)"
								>
									<div class="rate_item bg-light-hight-1 rounded">
										<div class="hstack w-75 mx-auto p-3">
											<div class="vstack">
												<div
													class="rounded-9 h-25 w-25"
													:class="'bg-' + formatPriority(mail.priority).color"
												></div>
												<i
													class="uil uil- fs-6"
													:class="mail.isFavourite == true ? 'text-red-' : ''"
												></i>
											</div>
											<img src="@/assets/images/e-mail.png" alt="" />
										</div>
										<div
											class="bg-second- text-center text-truncate text-white rounded p-2 text-5"
										>
											<router-link
												:to="{
													name: 'mail-show',
													params: { id: mail.id },
												}"
											>
												{{ $filters.truncate(mail.reference, 20) }}
											</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="hstack mt-4">
							<router-link
								to="/mail/add"
								class="bg-second- rounded ms-auto px-3 p-1"
							>
								<i class="uil uil-plus text-white"></i>
								<span class="ms-2 text-6 text-white">{{
									$t('mails.save_mail')
								}}</span>
							</router-link>
						</div>

						<div class="hstack mt-4 p-2 px-4 rounded shadow-1 bg-white">
							<input
								type="checkbox"
								:checked="allIsCheck"
								@click="check(0, true)"
							/>
							<i
								class="uil uil-archive ms-3 fs-6"
								type="button"
								data-mdb-toggle="modal"
								data-mdb-target="#archiveModal"
								@click="archived = false"
							></i>
							<i
								class="uil uil-trash fs-6 ms-3"
								type="button"
								data-mdb-toggle="modal"
								data-mdb-target="#deleteModal"
								@click="deleted = false"
							></i>

							<div class="hstack ms-auto">
								<label for="" class="text-5 me-2">{{
									$t('global.filter')
								}}</label>
								<select class="form-control text-6" v-model="orderBy">
									<option value="id">
										{{ $t('global.order_of_addition') }}
									</option>
									<option value="reference">
										{{ $t('global.reference') }}
									</option>
									<option value="subject">{{ $t('global.object') }}</option>
									<option value="status">{{ $t('global.status') }}</option>
									<option value="priority">{{ $t('global.priority') }}</option>
								</select>

								<label for="" class="text-5 ms-2 me-2">{{
									$t('global.order')
								}}</label>
								<select class="form-control text-6" v-model="order">
									<option value="ASC">Asc</option>
									<option value="DESC">Desc</option>
								</select>
							</div>

							<div class="ms-auto hstack">
								<input type="search" v-model="searchText" class="form-control me-2 w-40" :placeholder="$t('tools.search')">
								<i
									class="uil uil-arrow-circle-left fs-5"
									type="button"
									@click="previous"
								></i>
								<small class="ms-3"
									>{{ Math.ceil(allMails.length / 25) > 0 ? page : 0 }} /
									{{ Math.ceil(allMails.length / 25) }}</small
								>
								<i
									class="uil uil-arrow-circle-right fs-5 ms-3"
									type="button"
									@click="next"
								></i>
								<div class="hstack ms-2">
									<i
										class="uil uil-apps fs-6 p-1 px-3 rounded"
										:class="
											store.disposition == 'grid'
												? 'bg-black- text-white-'
												: 'bg-white- text-black-'
										"
										@click="store.setDisposition('grid')"
										type="button"
									></i>
									<i
										class="uil uil-list-ul fs-6 ms-1 p-1 px-3 rounded"
										:class="
											store.disposition == 'list'
												? 'bg-black- text-white-'
												: 'bg-white- text-black-'
										"
										@click="store.setDisposition('list')"
										type="button"
									></i>
								</div>
							</div>
						</div>

						<div v-if="!loading && allMails.length != 0">
							<div
								class="table-responsive mt-0 mb-4"
								v-if="store.disposition == 'list'"
							>
								<table
									class="table mails align-middle table-hover table-striped mb-0"
								>
									<thead class="bg-primary-">
										<tr class="text-6 text-white">
											<th>#</th>
											<th>{{ $t('global.reference') }}</th>
											<th>{{ $t('global.object') }}</th>
											<th class="text-center">{{ $t('global.priority') }}</th>
											<th>{{ $t('global.sent_by') }}</th>
											<th class="text-center" style="width: 250px">
												{{ $t('global.status') }}
											</th>
											<th>{{ $t('global.add_the') }}</th>
											<!-- <th>Modifié le</th> -->
											<th>{{ $t('global.action') }}</th>
										</tr>
									</thead>
									<tbody class="bg-alt-">
										<tr
											class="text-6 text-black"
											v-for="(mail, index) in allMails"
											:key="index"
											v-show="show(index)"
										>
											<td>
												<input
													type="checkbox"
													:checked="isSelected(mail.id)"
													@click="check(mail.id)"
												/>
											</td>
											<td>
												<router-link
													:to="{
														name: 'mail-show',
														params: { id: mail.id },
													}"
												>
													{{ $filters.truncate(mail.reference, 10) }}
												</router-link>
											</td>
											<td :title="mail.subject">
												{{ $filters.truncate(mail.subject, 10) }}
											</td>
											<td class="text-center">
												<kbd
													class="px-2 py-1 rounded-9"
													:class="'bg-' + formatPriority(mail.priority).color"
													>{{ formatPriority(mail.priority).name }}</kbd
												>
											</td>
											<td>
												{{
													$filters.truncate(
														mail.sender.entity ??
															mail.sender.name ??
															mail.sender.email ??
															mail.sender.phone,
														15
													)
												}}
											</td>
											<td
												class="text-center mx-4 status"
												style="width: 250px; margin-left: 250px"
											>
												<kbd
													class="px-2 py-1 rounded-9"
													:class="'bg-' + formatStatus(mail.status).color"
													>{{ formatStatus(mail.status).name }}</kbd
												>
											</td>
											<td>
												{{ $filters.convertDate(mail.createdAt) }}
											</td>
											<!-- <td>
										{{ $filters.convertDate(mail.updatedAt) }}
									</td> -->
											<td>
												<div v-if="mail.status !== 'processed'">
													<button
														data-mdb-toggle="modal"
														data-mdb-target="#deleteModal"
														class="btn btn-sm button-second-"
														@click="selectMailToProcess(mail.id)"
													>
														{{ $t('mails.to_treat') }}
													</button>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div class="row mt-1 g-2" v-else>
								<div
									class="col-md-3"
									v-for="(mail, index) in allMails"
									:key="index"
									v-show="show(index)"
								>
									<div class="rate_item bg-light-hight-1 rounded">
										<div class="hstack w-75 mx-auto p-3">
											<div class="vstack">
												<div
													class="rounded-9 h-25 w-25"
													:class="'bg-' + formatPriority(mail.priority).color"
												></div>
												<i
													class="uil uil- fs-6"
													:class="mail.isFavourite == true ? 'text-red-' : ''"
												></i>
											</div>
											<img src="@/assets/images/e-mail.png" alt="" />
										</div>
										<div
											class="bg-second- text-center text-truncate text-white rounded p-2 text-5"
										>
											<router-link
												:to="{
													name: 'mail-show',
													params: { id: mail.id },
												}"
											>
												{{ $filters.truncate(mail.reference, 20) }}
											</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div
							class="py-4 text-center text-black-"
							v-if="!loading && allMails.length == 0"
						>
							{{ $t('global.none_element') }}
						</div>

						<LoadingMain :state="loading" />
					</div>
					<div class="col-xl-3 mt-4">
						<div class="rounded bg-second- text-white text-4 text-center p-1">
							{{ $t('global.advice') }}
						</div>
						<div
							class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
							v-html="$t('resources.employee_index_advice_text')"
						></div>
						<router-link to="/mail/add"
							><button class="button bg-alt- btn-sm w-100 mt-1 p-1">
								{{ $t('resources.congrat_msg') }}
							</button>
						</router-link>
	
						<div class="" style="margin-top: 85px">
							<div class="rounded bg-second- text-white text-4 text-center p-1">
								{{ $t('global.summary') }}
							</div>
							<div
								class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
							>
								<div class="row px-3">
									<div class="col-6 py-1 text-start">
										{{ $t('mails.total') }}:
									</div>
									<div class="col-6 py-1 text-alt- text-end">
										{{ $filters.formatAmount(stats.total, false) }}
									</div>
									<div class="col-6 py-1 text-start">
										{{ $t('mails.total_pendings') }}:
									</div>
									<div class="col-6 py-1 text-alt- text-end">
										{{ $filters.formatAmount(stats.pendings, false) }}
									</div>
									<div class="col-6 py-1 text-start">
										{{ $t('mails.total_processed') }}:
									</div>
									<div class="col-6 py-1 text-alt- text-end">
										{{ $filters.formatAmount(stats.processed, false) }}
									</div>
									<div class="col-6 py-1 text-start">
										{{ $t('mails.low_priority') }}:
									</div>
									<div class="col-6 py-1 text-alt- text-end">
										{{ $filters.formatAmount(stats.low, false) }}
									</div>
									<div class="col-6 py-1 text-start">
										{{ $t('mails.medium_priority') }}:
									</div>
									<div class="col-6 py-1 text-alt- text-end">
										{{ $filters.formatAmount(stats.medium, false) }}
									</div>
									
									<div class="col-6 py-1 text-start">
										{{ $t('mails.high_priority') }}:
									</div>
									<div class="col-6 py-1 text-alt- text-end">
										{{ $filters.formatAmount(stats.high, false) }}
									</div>
								</div>
							</div>
							<div class="bg-alt- mt-1 p-1 rounded text-center">
								<span class="text-6 text-white-">TAGS</span>
								<p class="text-6 p-0 m-0 text-yellow-">
									#Ponctualité #Dynamisme #Sérieux #Rigueur
								</p>
							</div>
						</div>
					</div>
					
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import LoadingMain from '@/components/LoadingMain.vue';
import Helper from '@/helpers/helper';
import InputFC from '@/components/InputFC.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
import { useUserStore } from '@/store/user';

import { useDispositionStore } from '@/store/disposition';

export default {
	name: 'MailIndex',
	components: { Template, InputFC, LoadingMain, SpinnerMe },
	setup() {
		const ustore = useUserStore();
		const store = useDispositionStore();
		return {
			ustore: ustore,
			store: store,
		};
	},
	data() {
		return {
			selectedList: [],
			allIsCheck: false,
			page: 1,
			perPage: 25,
			deleted: false,
			archived: false,
			loading: false,
			disposition: 'list',
			orderBy: 'id',
			order: 'DESC',
			urgents: [],
			allMails: [],
			priority_list_to_color: [],
			mail_processing_status_to_color: [],
			errors: {},
			message: '',
			ok: false,
			mail_processed: '',
			processTarget: 0,
			allList: [],
			stats: [],
			searchText: ""
		};
	},
	methods: {
		downloadFile(l, n) {
			Helper.downloadFile(l, n, this);
		},

		selectMailToProcess(i) {
			this.processTarget = i;
		},

		formatPriority(id) {
			return this.priority_list_to_color.find((p) => p.id == id);
		},

		formatStatus(id) {
			return this.mail_processing_status_to_color.find((p) => p.id == id);
		},
		search() {
			if (this.searchText != "") {
				this.searchText = this.searchText.toLowerCase()
				const founds = this.allList.filter(element =>
					element.reference?.toLowerCase().includes(this.searchText) ||
					element.subject?.toLowerCase().includes(this.searchText)
				);

				this.allMails = founds
			} else {
				this.allMails = this.allList
			}
		},
		archives() {
			this.$isLoading(true);
			let body = {
				ids: this.selectedList.map((element) => element.id),
			};
			fetch(
				Helper.route('admin/mail/archive'),
				Helper.requestOptionsJSON('PUT', body)
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					this.$isLoading(false);
					console.log(data);
					this.archived = true;
					for (let i = 0; i < this.selectedList.length; i++) {
						const element = this.selectedList[i];
						this.mails = this.mails.filter((i) => i.id !== element.id);
					}
					this.selectedList = [];
					// Helper.notification("success", data.message)
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
		},
		deletes() {
			this.$isLoading(true);
			let body = {
				ids: this.selectedList.map((element) => element.id),
			};
			fetch(
				Helper.route('admin/mail/delete'),
				Helper.requestOptionsJSON('DELETE', body)
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					this.$isLoading(false);
					console.log(data);
					this.deleted = true;
					for (let i = 0; i < this.selectedList.length; i++) {
						const element = this.selectedList[i];
						this.mails = this.mails.filter((i) => i.id !== element.id);
					}
					this.selectedList = [];
					// Helper.notification("success", data.message)
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
		},
		check(id, all = false) {
			if (all) {
				if (this.selectedList.length !== this.allMails.length) {
					this.selectedList.length = 0;
					this.allMails.forEach((offer) => {
						this.selectedList.push({ id: offer.id });
					});
				} else {
					this.selectedList.length = 0;
				}
			} else {
				let found = false;
				this.selectedList.forEach((element) => {
					if (element.id == id) {
						found = true;
					}
				});
				if (found) {
					this.selectedList.filter((value, index) => {
						if (value.id == id) {
							this.selectedList.splice(index, 1);
						}
					});
				} else {
					this.selectedList.push({ id: id });
				}
			}
			if (this.selectedList.length === this.allMails.length) {
				this.allIsCheck = true;
			} else {
				this.allIsCheck = false;
			}
		},
		show(index) {
			return (
				index < this.page * this.perPage &&
				index + 1 > (this.page - 1) * this.perPage
			);
		},
		next() {
			if (this.page * this.perPage < this.allMails.length) {
				this.page++;
			}
		},
		previous() {
			if (this.page >= 2) {
				this.page--;
			}
		},
		isSelected(id) {
			let found = false;
			this.selectedList.forEach((element) => {
				if (element.id == id) {
					found = true;
				}
			});
			return found;
		},
		processMail() {
			this.ok = true;
			Helper.loading('processBtn', 'processSpinner', true);

			let registerFormData = new FormData();

			registerFormData.append('mail', this.processTarget);
			registerFormData.append('message', this.message);
			registerFormData.append(
				'file',
				document.getElementById('file_').files[0]
			);

			fetch(
				Helper.route('admin/mail/process'),
				Helper.requestOptions('POST', registerFormData)
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					Helper.loading('processBtn', 'processSpinner', false);
					if (data.problems) {
						this.errors.message = Helper.getError(data.problems, 'message');
						this.errors.file = Helper.getError(data.problems, 'file_');
						if (data.problems[0]) {
							Helper.notification(
								'error',
								data.problems[0]['admin'] ??
									data.problems[0]['mail'] ??
									data.message
							);
						} else {
							Helper.notification('error', data.message);
						}
					} else {
						this.mail_processed = data.message;
						Helper.notification('success', data.message);
						this.loadList();
					}
				})
				.catch((error) => {
					console.log(error);
					Helper.loading('processBtn', 'processSpinner', false);
				});
		},
		loadStats() {
			this.loading = true;
			fetch(Helper.route('admin/mail/stats'), Helper.requestOptions())
				.then((response) => response.json())
				.then((data) => {
					this.loading = false;
					console.log('stats : ', data);
					this.stats = data.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		loadList() {
			this.loading = true;
			fetch(
				Helper.route(
					'admin/mail/me?sortBy=' + this.orderBy + '&order=' + this.order
				),
				Helper.requestOptions()
			)
				.then((response) => response.json())
				.then((data) => {
					this.loading = false;
					console.log(data);
					if (data.data) {
						this.allMails = data.data.all;
						this.urgents = data.data.urgent;
						this.allList = this.allMails
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
	watch: {
		orderBy: function () {
			this.loadList();
		},
		order: function () {
			this.loadList();
		},
		searchText: function () {
			this.search();
		},
	},
	created() {
		this.priority_list_to_color = Helper.priority_list_to_color();
		this.mail_processing_status_to_color =
		Helper.mail_processing_status_to_color();
		this.loadList();
		this.loadStats();
	},
};
</script>
<style>
.rate_item img {
	height: 70px;
	width: 70px;
	object-fit: cover;
}

.mails th,
.mails td {
	color: white !important;
}

.mails td.status {
	min-width: 120px !important;
}

.employe_item img {
	height: 100px;
	width: 100px;
}
</style>
