<template>
    <div class="modal-overlay" @click="$emit('close-modal')">
        <div class="m-modal" @click.stop>
            <div class="d-flex p-2 justify-content-between bg-second-">
                <h4 class="text-2 text-white- pt-3 text-end">Les tâches du {{formatDateTitle()}}</h4>
                <button @click="$emit('close-modal')" class="close mx-3">Fermer</button>
            </div>
            <div class="modal-container">
                <div class="row g-2 mt-0">
                    <div
                        class="col-lg-4 mb-3"
                        v-for="(task, index) in tasks"
                        :key="index"
                    >
                        <div class="rate_item bg-light-hight-1 rounded">
                            <div class="fit-content- text-center mx-auto p-1 py-2">
                                <i class="uil uil-star text-orange- fs-2 mb-3"></i>
                                <div class="ms-2 vstack">
                                    <span class="text-4 text-black">
                                        <router-link
                                            :to="{
                                                name: 'task',
                                                params: {
                                                    id: task.id ,
                                                    slug: task.slug,
                                                },
                                            }"
                                        >
                                            {{ $filters.truncate(task.name, 25) }}
                                        </router-link>
                                    </span>
                                </div>
                            </div>
                            <div class="bg-second- text-white rounded p-2 text-5">
                                <div class="hstack">
                                    <span class="text-6 text-white">{{
                                        $filters.convertDate(popupDate, false)
                                    }}</span>
                                </div>
                                <div class="hstack mt-1">
                                    <div class="">
                                        <span class="text-6 text-white">{{
                                            task.nature == 1
                                                ? $t('training.online')
                                                : $t('training.in_person')
                                        }}</span>
                                    </div>
                                    <!-- <div class="ms-auto">
                                        <input
                                            type="checkbox"
                                            :checked="isSelected(task.id)"
                                            @click="check(task.id)"
                                        />
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
						class="py-4 text-center text-black-"
						v-if="tasks.length == 0"
					>
						{{ $t('global.none_element') }}
				</div>
                
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import {format} from 'date-fns';
  import frLocale from 'date-fns/locale/fr';

  export default {
    name: 'TaskPopup',
    props: {
      tasks: {
        type: Array,
        required: true
      },
      popupDate: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        showPopup: true
      };
    },
    methods: {
      formatDate(date) {
        return new Date(date).toLocaleDateString();
      },
      formatTaskDay(date) {
        date = new Date(date)
		return format(date, 'dd MMM yyyy', { locale: frLocale });
	},
      formatDateTitle() {
        return  this.formatTaskDay(this.popupDate);
      },
      closePopup() {
        this.showPopup = false;
      }
    }
  };
  </script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    background-color: #000000be;
}

.m-modal {
    background-color: white;
    height: 80vh;
    width: 70vw;
    margin-top: 5%;
    border-radius: 15px;
    max-height: 100vh;
    overflow-y: auto;
}

.modal-title {
  margin-top: 0;
  color: #fff;
  padding-left: 20px;
  font-size: 2.5rem;
  font-weight: bold;
}

.modal-container{
    padding: 10px 20px;
}


p {
    font-size: 16px;
    margin: 20px 10%;
    color: #4d4b4bcc;
}

.close {
    background-color: #f81d1d;
    width: 150px;
    height: 40px;
    color: rgb(250, 249, 249);
    font-size: 14px;
    border-radius: 6px;
    margin-top: 12px;
    float: right;
    outline: none;
    border: none;
}

.valide:hover, .close:hover {
    transform: scale(0.95);
}



@media screen and (max-width: 414px) {
    .m-modal {
        width: 80%;
    }
}


@media screen and (max-width: 375px) {
    .m-modal {
        width: 80%;
    }
}


@media screen and (max-width: 280px) {
    .m-modal {
        width: 80%;
    }
}
</style>
  