<template>
    <div>
        <i class="uil uil-arrow-left fs-4 text-second" type="button" @click="$router.go(-1)"></i>
    </div>
</template>

<script>
export default {
    name: "BackComponent"
}
</script>