import { defineStore } from 'pinia';

export const useDispositionStore = defineStore('disposition', {
	state: () => {
		return {
			disposition: 'list',// 'grid',
		};
	},
	actions: {
		setDisposition(d) {
			this.disposition = d;
		},
	},
	getters: {
		getDisposition() {
			return this.disposition;
		},
	},
});
