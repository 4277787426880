<template>
    <div>
        <Template title="Nouveau formulaire">

            <section class="p-5 py-4">
                <Back />
                
                <div class="bg-second- shadow-sm text-uppercase text-center rounded text-white px-4 p-2 mt-4">
                  Créer un formulaire d'inscription
                </div>
                
                <div v-if="step == 1" class="bg-light-hight- rounded mt-4 py-5">

                    <div class="text-center">Ce formulaire pré-conçu est en mode affichage et permettra aux candidats de s'inscrire à votre offre d'emploi</div>
                    <hr>

                    <div class="col-lg-10 mx-auto">

                        <div class="row m-0 mt-4">
                            <input type="text" class="form-control text-center" placeholder="Titre du formulaire">
                        </div>
                        
                    <hr>

                      <fieldset>
                        <legend class="text-center">Informations générales</legend>
                        <div class="row mt-4">
                        <div class="col-lg-6">
                                <input type="text" class="form-control" placeholder="Nom">
                            </div>
                            <div class="col-lg-6">
                                <input type="text" class="form-control" placeholder="Prénoms">
                            </div>
                        </div>   
    
                    <div class="row mt-4">
                        <div class="col-lg-6">
                                <input type="date" class="form-control" placeholder="Date de naissance">
                            </div>
                            <div class="col-lg-6">
                                <input type="text" class="form-control" placeholder="Lieu de naissance">
                            </div>
                            
                    </div>
                    <div class="row mt-4">
                        <div class="col-lg-6">
                                <input type="text" class="form-control" placeholder="Nationalité">
                            </div>
                            <div class="col-lg-6">
                                <select class="form-control text-light-">
                                    <option value="">{{$t('global.sex')}}</option>
                                    <option value="M">Masculin</option>
                                    <option value="F">Féminin</option>
                                </select>
                            </div>
                            
                        </div>
                      </fieldset>
                        <hr>

                        <fieldset>
                            <legend class="text-center">Contacts + Résidences</legend>
                            <div class="row mt-4">
                        <div class="col-lg-6">
                                <input type="phone" class="form-control" placeholder="Téléphone 1">
                            </div>
                            <div class="col-lg-6">
                                <input type="phone" class="form-control" placeholder="Téléphone 2">
                            </div>
                            
                    </div>

                    <div class="row m-0 mt-4">
                            <input type="text" class="form-control" placeholder="Adresse email">
                        </div>

                        <div class="row mt-5">
                        <div class="col-lg-6">
                                <input type="text" class="form-control" placeholder="Pays">
                            </div>
                            <div class="col-lg-6">
                                <input type="text" class="form-control" placeholder="Département">
                            </div>
                        </div>   
    
                    <div class="row mt-4">
                        <div class="col-lg-6">
                                <input type="text" class="form-control" placeholder="Commune">
                            </div>
                            <div class="col-lg-6">
                                <input type="text" class="form-control" placeholder="Lieu">
                            </div>
                            
                    </div>

                    <div class="row m-0 mt-4">
                        <select class="form-control text-light-">
                                    <option value="">Situation matrimoniale</option>
                                    <option value="Célibataire">Célibataire</option>
                                    <option value="Célibataire avec enfant">Célibataire avec enfant</option>
                                    <option value="Marié">Marié</option>
                                    <option value="Marié sans enfant">Marié sans enfant</option>
                                    <option value="Marié avec enfant">Marié avec enfant</option>
                                </select>
                    </div>
                        
                        </fieldset>


                        <div class="hstack">
                            <div class="">
                                <button @click="step = 2" class="button px-4 py-1 mt-4">Suivant <i class="uil uil-arrow-right text-white fs-6"></i></button>
                            </div>
                            <div class="col"></div>
                            <div class="ms-3">
                                <button class="button-default bg-light- text-color- fw-500 shadow-sm px-4 py-1 mt-4">{{ $t('global.cancel') }}</button>
                            </div>
                        </div>

                    </div>
                </div>

                <div v-if="step == 2" class="bg-light-hight- rounded mt-4 py-5">

                    <div class="col-lg-10 mx-auto">

                      <fieldset>
                        <legend class="text-center">Formations, Diplômes et Expériences</legend>
                        <div class="row m-0 mt-4">
                        <select class="form-control text-light-">
                                    <option value="">Dernier diplôme obtenu</option>
                                    <option value="L">Licence</option>
                                    <option value="M">Master</option>
                                    <option value="D">Doctorat</option>
                                    <option value="B">Baccalauréat</option>
                                </select>
                    </div>
                        <div class="row mt-4">
                        <div class="col-lg-6">
                                <input type="text" class="form-control" placeholder="Année d'obtention">
                            </div>
                            <div class="col-lg-6">
                                <input type="text" class="form-control" placeholder="Domaine de formation">
                            </div>
                        </div>

                        <hr>

                        <div class="row mt-4">
                        <div class="col-lg-6">
                                <input type="text" class="form-control" placeholder="Structure">
                            </div>
                            <div class="col-lg-6">
                                <input type="text" class="form-control" placeholder="Durée (mois)">
                            </div>
                        </div> 

                        <div class="mt-4 text-center">
                            <button class="btn btn-primary btn-sm"><i class="uil uil-plus fs-6 text-white"></i></button>
                        </div>
    
                      </fieldset>
                        <hr>

                        <fieldset>
                            <legend class="text-center">Informatique et Langues</legend>
                            <div class="row mt-4">
                        <div class="col-lg-6">
                                <input type="phone" disabled class="form-control" placeholder="Word">
                            </div>
                            <div class="col-lg-6">
                                <select class="form-control text-light-">
                                    <option value="">Niveau</option>
                                    <option value="D">Débutant</option>
                                    <option value="I">Intermédiare</option>
                                    <option value="E">Expert</option>
                                </select>
                            </div>
                            
                    </div>

                    <div class="row mt-4">
                        <div class="col-lg-6">
                                <input type="phone" disabled class="form-control" placeholder="Excel">
                            </div>
                            <div class="col-lg-6">
                                <select class="form-control text-light-">
                                    <option value="">Niveau</option>
                                    <option value="D">Débutant</option>
                                    <option value="I">Intermédiare</option>
                                    <option value="E">Expert</option>
                                </select>
                            </div>
                            
                    </div>

                    <div class="row mt-4">
                        <div class="col-lg-6">
                                <input type="phone" class="form-control" placeholder="Logiciel Maîtrisé">
                            </div>
                            <div class="col-lg-6">
                                <select class="form-control text-light-">
                                    <option value="">Niveau</option>
                                    <option value="D">Débutant</option>
                                    <option value="I">Intermédiare</option>
                                    <option value="E">Expert</option>
                                </select>
                            </div>
                            
                    </div>

                    <div class="mt-4 text-center">
                            <button class="btn btn-primary btn-sm"><i class="uil uil-plus fs-6 text-white"></i></button>
                        </div>

                        <hr>

                        <div class="row mt-4">
                        <div class="col-lg-4">
                                <input type="phone" disabled class="form-control" placeholder="Français">
                            </div>
                            <div class="col-lg-4">
                                <select class="form-control text-light-">
                                    <option value="">Parlé</option>
                                    <option value="D">Débutant</option>
                                    <option value="I">Intermédiare</option>
                                    <option value="E">Expert</option>
                                </select>
                            </div>
                            <div class="col-lg-4">
                                <select class="form-control text-light-">
                                    <option value="">Ecrit</option>
                                    <option value="D">Débutant</option>
                                    <option value="I">Intermédiare</option>
                                    <option value="E">Expert</option>
                                </select>
                            </div>
                            
                    </div>

                    <div class="row mt-4">
                        <div class="col-lg-4">
                                <input type="phone" disabled class="form-control" placeholder="Anglais">
                            </div>
                            <div class="col-lg-4">
                                <select class="form-control text-light-">
                                    <option value="">Parlé</option>
                                    <option value="D">Débutant</option>
                                    <option value="I">Intermédiare</option>
                                    <option value="E">Expert</option>
                                </select>
                            </div>
                            <div class="col-lg-4">
                                <select class="form-control text-light-">
                                    <option value="">Ecrit</option>
                                    <option value="D">Débutant</option>
                                    <option value="I">Intermédiare</option>
                                    <option value="E">Expert</option>
                                </select>
                            </div>
                            
                    </div>
                    <div class="row mt-4">
                        <div class="col-lg-4">
                                <input type="phone" class="form-control" placeholder="Langue">
                            </div>
                            <div class="col-lg-4">
                                <select class="form-control text-light-">
                                    <option value="">Parlé</option>
                                    <option value="D">Débutant</option>
                                    <option value="I">Intermédiare</option>
                                    <option value="E">Expert</option>
                                </select>
                            </div>
                            <div class="col-lg-4">
                                <select class="form-control text-light-">
                                    <option value="">Ecrit</option>
                                    <option value="D">Débutant</option>
                                    <option value="I">Intermédiare</option>
                                    <option value="E">Expert</option>
                                </select>
                            </div>
                            
                    </div>

                    <div class="mt-4 text-center">
                            <button class="btn btn-primary btn-sm"><i class="uil uil-plus fs-6 text-white"></i></button>
                        </div>

                        <hr>
                        
                        </fieldset>


                        <div class="hstack">
                            <div class="">
                                <button @click="step = 1" class="button px-4 py-1 mt-4"><i class="uil uil-arrow-left text-white fs-6"></i> Précédent</button>
                            </div>
                            <div class="ms-3">
                                <button class="button-default bg-light- text-color- fw-500 shadow-sm px-4 py-1 mt-4">{{ $t('global.cancel') }}</button>
                            </div>
                            <div class="col"></div>
                            <div class="ms-3">
                                <button @click="step = 3" class="button px-4 py-1 mt-4">Suivant <i class="uil uil-arrow-right text-white fs-6"></i></button>
                            </div>
                        </div>

                    </div>
                </div>
                
                <div v-if="step == 3" class="bg-light-hight- rounded mt-4 py-5">

                    <div class="col-lg-10 mx-auto">

                      <fieldset>
                        <legend class="text-center">Télécharger votre carte nationale d'identité</legend>
                        <div class="row m-0 mt-4">
                            <input type="file" class="form-control">
                        </div>

                        <hr>

                        <div class="row mt-2">
                            <div class="col-lg-2">
                                Format accepté :
                            </div>
                            <div class="col-lg-10">
                                Image (JPG ou PNG)
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-lg-2">
                                Taille maximum :
                            </div>
                            <div class="col-lg-10">
                                2 MO
                            </div>
                        </div>

                        <hr>
    
                      </fieldset>
                        

                        <fieldset>
                            <legend class="text-center">Télécharger votre photo professionnelle</legend>
                            
                            <div class="row m-0 mt-4">
                            <input type="file" class="form-control">
                        </div>

                        <hr>

                        <div class="row mt-2">
                            <div class="col-lg-2">
                                Format accepté :
                            </div>
                            <div class="col-lg-10">
                                Image (JPG)
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-lg-2">
                                Taille maximum :
                            </div>
                            <div class="col-lg-10">
                                10 MO
                            </div>
                        </div>

                        <hr>
                        
                        </fieldset>

                        <fieldset>
                            <legend class="text-center">Télécharger votre Curiculum Vitae</legend>
                            
                            <div class="row m-0 mt-4">
                            <input type="file" class="form-control">
                        </div>

                        <hr>

                        <div class="row mt-2">
                            <div class="col-lg-2">
                                Format accepté :
                            </div>
                            <div class="col-lg-10">
                                Document (PDF)
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-lg-2">
                                Taille maximum :
                            </div>
                            <div class="col-lg-10">
                                5 MO
                            </div>
                        </div>

                        <hr>
                        
                        </fieldset>

                        <fieldset>
                            <legend class="text-center">Télécharger document supplémentaires</legend>
                            
                            <div class="row m-0 mt-4">
                            <input type="file" class="form-control">
                        </div>

                        <hr>

                        <div class="row mt-2">
                            <div class="col-lg-2">
                                Détails :
                            </div>
                            <div class="col-lg-10">
                                Télécharger un document que vous jugez important comme votre motivation, un projet d'avenir, une recommandation, etc... (Facultatif)
                            </div> 
                        </div>

                        <div class="row mt-2">
                            <div class="col-lg-2">
                                Format accepté :
                            </div>
                            <div class="col-lg-10">
                                Image ou Document (JPG ou PNG ou PDF)
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-lg-2">
                                Taille maximum :
                            </div>
                            <div class="col-lg-10">
                                10 MO
                            </div>
                        </div>

                        <hr>
                        
                        </fieldset>


                        <div class="hstack">
                            <div class="">
                                <button @click="step = 2" class="button px-4 py-1 mt-4"><i class="uil uil-arrow-left text-white fs-6"></i> Précédent</button>
                            </div>
                            <div class="ms-3">
                                <button class="button-default bg-light- text-color- fw-500 shadow-sm px-4 py-1 mt-4">{{ $t('global.cancel') }}</button>
                            </div>
                            <div class="col"></div>
                            <div class="ms-3">
                                <button @click="step = 3" class="button px-4 py-1 mt-4">Valider</button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            
        </Template>
    </div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
export default {
    name: "CandidatesPage",
    components: { Template, Back },
    data() {
        return {
            step: 1,
        }
    }
}
</script>
<style>
    
</style>