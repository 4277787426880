<template>
	<div>
		<Template title="null" sidebarMenu="employees">
			<div class="bg-light-hight- hstack py-3 ps-2 pb-3">
				<router-link
					to="/employees"
					class="bg-light-hight- ms-3 text-6 top-item"
					>{{ $t('resources.employees') }}</router-link
				>
				<router-link
					to="/sections"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('resources.sections') }}</router-link
				>
				<router-link
					to="/postes"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('resources.postes') }}</router-link
				>
				<router-link
					to="/contrats"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('resources.contracts') }}</router-link
				>
				<router-link
					to="/formations"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('resources.training') }}</router-link
				>
			</div>

			<section class="row px-4 m-0 g-4 mt-1">
				<!-- Modal -->
				<div
					class="modal fade"
					id="deleteModal"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="deleteModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content rounded-0">
							<div class="modal-body">
								<h6 class="text-center text-red-">
									{{ $t('global.deletion_warning') }}
								</h6>
								<hr />
								<p class="text-5" v-show="!deleted" v-html="$t('resources.employees_deletion_warning')">
									
								</p>
								<p class="text-5 text-center" v-show="deleted">
									{{ $t('global.deleted_successfully') }}
									<i class="uil uil-check-circle fs-4 text-primary-"></i>
								</p>
								<hr />
								<div class="row">
									<div class="col text-center">
										<button
											type="button"
											class="border bg-light px-3 text-5 rounded py-1"
											data-mdb-dismiss="modal"
										>
											{{ $t('global.close') }}
										</button>
									</div>
									<div class="col text-center" v-show="!deleted">
										<button
											type="button"
											class="bg-red- text-white- px-3 rounded py-1 text-5 border-0 set-opacity"
											@click="deletes()"
										>
											{{ $t('global.delete') }}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Modal -->
				<!-- <div class="modal fade" id="archiveModal" data-mdb-backdrop="static" data-mdb-keyboard="false" tabindex="-1" aria-labelledby="archiveModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content rounded-0">
                            <div class="modal-body">
                                <h6 class="text-center text-alt-">{{$t('global.archiving_warning')}}</h6> 
                                <hr>
                                <p class="text-5" v-show="!archived">
                                    Etes-vous sûr de vouloir archiver ceci ? <br> 
                                    Notez que cette action déplacera le message dans la boîte aux archives. 
                                    Mais toutes personnes ayant accès à ce compte peut toujours le consulter.
                                </p>
                                <p class="text-5 text-center" v-show="archived">
                                    {{$t('global.archiving_successfully')}} <i class="uil uil-check-circle fs-4 text-primary-"></i>
                                </p>
                                <hr>
                                <div class="row">
                                    <div class="col text-center">
                                        <button type="button" class="border bg-light px-3 text-5 rounded py-1" data-mdb-dismiss="modal">{{$t('global.close')}}</button>
                                    </div>
                                    <div class="col text-center" v-show="!archived">
                                        <button type="button" class="bg-alt- text-white- px-3 rounded py-1 text-5 border-0 set-opacity" @click="archived = true">Archiver</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

				<div class="col-xl-9 mb-5">
					<div v-if="!loading && bests.length != 0">
						<div
							class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 p-1"
						>
							{{ $t('resources.merit_table') }}
						</div>
						<div class="row mt-1 g-2 mb-4">
							<div class="col-md-3" v-for="(best, index) in bests" :key="index">
								<div class="rate_item bg-light-hight-1 rounded h-100">
									<div class="hstack fit-content- mx-auto p-3">
										<img :src="best.photo.url" class="rounded-circle" alt="" />
										<div class="ms-2 vstack">
											<span class="text-5">{{ best.rate }}</span>
											<i class="uil uil-favorite text-orange- fs-5"></i>
										</div>
									</div>
									<div
										class="bg-second- text-center text-6 text-white rounded p-2 text-5"
										style="height: 60px"
									>
										<router-link
											:to="{
												name: 'employee',
												params: { id: best.id, slug: best.slug },
											}"
										>
											{{ best.lastname + ' ' + best.firstname }}
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="hstack">
						<router-link
							to="/employees/create"
							class="bg-second- rounded ms-auto px-3 p-1"
						>
							<i class="uil uil-plus text-white"></i>
							<span class="ms-2 text-6 text-white">{{
								$t('resources.add_employee')
							}}</span>
						</router-link>
					</div>

					<div class="hstack mt-4 p-2 px-4 rounded shadow-1 bg-white">
						<input
							type="checkbox"
							:checked="allIsCheck"
							@click="check(0, true)"
						/>
						<!-- <i class="uil uil-archive ms-3 fs-6"  type="button" data-mdb-toggle="modal" data-mdb-target="#archiveModal" @click="archived = false"></i> -->
						<i
							class="uil uil-trash fs-6 ms-3"
							type="button"
							data-mdb-toggle="modal"
							data-mdb-target="#deleteModal"
							@click="deleted = false"
						></i>

						<div class="hstack ms-auto">
							<label for="" class="text-5 me-2">{{
								$t('global.filter')
							}}</label>
							<select class="form-control text-6" v-model="orderBy">
								<option value="id">{{ $t('global.order_of_addition') }}</option>
								<option value="lastname">
									{{ $t('global.alphabetical') }}
								</option>
								<option value="matricule">
									{{ $t('resources.registration_number') }}
								</option>
								<option value="grade">{{ $t('resources.grade') }}</option>
								<option value="state">{{ $t('global.state') }}</option>
							</select>

							<label for="" class="text-5 ms-2 me-2">{{
								$t('global.order')
							}}</label>
							<select class="form-control text-6" v-model="order">
								<option value="ASC">Asc</option>
								<option value="DESC">Desc</option>
							</select>
						</div>

						<div class="ms-auto hstack">
							<input type="search" v-model="searchText" class="form-control mx-2 w-40" :placeholder="$t('tools.search')">
							<i
								class="uil uil-arrow-circle-left fs-5"
								type="button"
								@click="previous"
							></i>
							<small class="ms-3"
								>{{ Math.ceil(employees.length / 25) > 0 ? page : 0 }} /
								{{ Math.ceil(employees.length / 25) }}</small
							>
							<i
								class="uil uil-arrow-circle-right fs-5 ms-3"
								type="button"
								@click="next"
							></i>
							<div class="hstack ms-5">
								<i
									class="uil uil-apps fs-6 p-1 px-3 rounded"
									:class="
										store.disposition == 'grid'
											? 'bg-black- text-white-'
											: 'bg-white- text-black-'
									"
									@click="store.setDisposition('grid')"
									type="button"
								></i>
								<i
									class="uil uil-list-ul fs-6 ms-1 p-1 px-3 rounded"
									:class="
										store.disposition == 'list'
											? 'bg-black- text-white-'
											: 'bg-white- text-black-'
									"
									@click="store.setDisposition('list')"
									type="button"
								></i>
							</div>
						</div>
					</div>

					<LoadingMain :state="loading" />

					<div v-if="!loading && employees.length != 0">
						<div
							class="table-responsive mt-0"
							v-if="store.disposition == 'list'"
						>
							<table
								class="table employees align-middle table-hover table-striped mb-0"
								style="width: 100% !important"
							>
								<thead class="bg-primary-">
									<tr class="text-6 text-white">
										<th></th>
										<th class="text-nowrap">{{ $t('resources.fullname') }}</th>
										<th class="text-nowrap">
											{{ $t('resources.position_held') }}
										</th>
										<th class="text-nowrap">{{ $t('resources.sections') }}</th>
										<th class="text-nowrap">
											{{ $t('resources.registration_number') }}
										</th>
										<th class="text-nowrap">{{ $t('resources.grade') }}</th>
										<th class="text-nowrap">{{ $t('global.state') }}</th>
									</tr>
								</thead>
								<tbody class="bg-alt-">
									<tr
										v-for="(employee, index) in employees"
										:key="employee.id"
										v-show="show(index)"
										class="text-6 text-black"
									>
										<td>
											<input
												type="checkbox"
												:checked="isSelected(employee.id)"
												@click="check(employee.id)"
											/>
										</td>
										<td class="text-nowrap">
											<router-link
												:to="{
													name: 'employee',
													params: { id: employee.id, slug: employee.slug },
												}"
											>
												{{ employee.lastname + ' ' + employee.firstname }}
											</router-link>
										</td>
										<td class="text-nowrap">{{ getJobs(employee.jobs) }}</td>
										<td class="text-nowrap">
											{{ getSections(employee.sections) }}
										</td>
										<td class="text-nowrap">{{ employee.matricule }}</td>
										<td class="text-nowrap">{{ employee.grade }}</td>
										<td class="text-nowrap">{{ employee.state }}</td>
									</tr>
								</tbody>
							</table>
							<div class="hstack bg-second- mt-0">
								<div class="text-uppercase text-white p-2 ps-4 py-2 text-6">
									{{ $t('resources.salary_cost') }}
								</div>
								<div
									class="bg-black py-2 px-4 text-white- fw-600 ms-auto text-6"
								>
									{{ $filters.formatAmount(salaryCharge) }}
								</div>
							</div>
						</div>

						<div v-if="store.disposition == 'grid'">
							<div class="row g-3 mt-0">
								<div
									class="col-xl-3 col-lg-4"
									v-for="(employee, index) in employees"
									:key="employee.id"
									v-show="show(index)"
								>
									<div class="employe_item bg-white h-100">
										<div class="p-3 text-center bg-alt-">
											<img
												:src="employee.photo.url"
												class="rounded-circle"
												alt=""
											/>
										</div>
										<div class="fw-600 mt-2 text-black- p-1 text-6 text-center">
											<input
												type="checkbox"
												:checked="isSelected(employee.id)"
												@click="check(employee.id)"
												class="me-2"
											/>
											<router-link
												:to="{
													name: 'employee',
													params: { id: employee.id, slug: employee.slug },
												}"
											>
												{{ employee.lastname + ' ' + employee.firstname }}
											</router-link>
										</div>
									</div>
								</div>
							</div>

							<div class="hstack bg-second- mt-4">
								<div class="text-uppercase text-white p-2 ps-4 py-2 text-6">
									{{ $t('resources.salary_cost') }}
								</div>
								<div
									class="bg-black py-2 px-4 text-white- fw-600 ms-auto text-6"
								>
									{{ $filters.formatAmount(salaryCharge) }}
								</div>
							</div>
						</div>
					</div>
					<div
						class="py-4 text-center text-black-"
						v-if="!loading && employees.length == 0"
					>
						{{ $t('resources.no_employee_found') }}
					</div>
				</div>

				<div class="col-xl-3 mt-4">
					<div class="rounded bg-second- text-white text-4 text-center p-1">
						{{ $t('global.advice') }}
					</div>
					<div
						class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
						v-html="$t('resources.employee_index_advice_text')"
					></div>
					<router-link to="/mail/add"
						><button class="button bg-alt- btn-sm w-100 mt-1 p-1">
							{{ $t('resources.congrat_msg') }}
						</button>
					</router-link>

					<div class="" style="margin-top: 85px">
						<div class="rounded bg-second- text-white text-4 text-center p-1">
							{{ $t('global.today_summary') }}
						</div>
						<div
							class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
						>
							<div class="row px-3">
								<div class="col-6 py-1 text-start">
									{{ $t('resources.employees') }}:
								</div>
								<div class="col-6 py-1 text-alt- text-end">
									{{ $filters.formatAmount(stats.total, false) }}
								</div>
								<div class="col-6 py-1 text-start">
									{{ $t('resources.at_office') }}:
								</div>
								<div class="col-6 py-1 text-alt- text-end">
									{{ $filters.formatAmount(stats.present, false) }}
								</div>
								<div class="col-6 py-1 text-start">
									{{ $t('resources.average_salary') }}:
								</div>
								<div class="col-6 py-1 text-alt- text-end">
									{{ $filters.formatAmount(stats.salaryAverage) }}
								</div>
								<div class="col-6 py-1 text-start">
									{{ $t('resources.pgs_rating') }}:
								</div>
								<div class="col-6 py-1 text-alt- text-end">
									{{ $filters.formatAmount(stats.rate, false) }}
									<i class="uil uil-star text-yellow-"></i>
								</div>
								<div class="col-6 py-1 text-start">
									{{ $t('resources.performance') }}:
								</div>
								<div class="col-6 py-1 text-alt- text-end">
									{{ $filters.formatAmount(stats.performance, false) }}%
								</div>
							</div>
						</div>
						<div class="bg-alt- mt-1 p-1 rounded text-center">
							<span class="text-6 text-white-">TAGS</span>
							<p class="text-6 p-0 m-0 text-yellow-">
								#Ponctualité #Dynamisme #Sérieux #Rigueur
							</p>
						</div>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Helper from '@/helpers/helper';
import LoadingMain from '@/components/LoadingMain.vue';
import { useDispositionStore } from '@/store/disposition';

export default {
	setup() {
		const store = useDispositionStore();
		return { store };
	},
	name: 'EmployeesPage',
	components: { Template, LoadingMain },
	data() {
		return {
			deleted: false,
			archived: false,
			loading: true,
			selectedList: [],
			allIsCheck: false,
			page: 1,
			perPage: 25,
			orderBy: 'id',
			order: 'DESC',
			employees: [],
			errors: [],
			bests: [],
			salaryCharge: 0,
			name: '',
			code: '',
			check_form: false,
			message_section_add: '',
			description: '',
			stats: {
				present: 0,
				total: 0,
				salaryAverage: 0,
				performance: 0,
				rate: 0,
			},
			allList: [],
			searchText: ""
		};
	},
	methods: {
		search() {
			if (this.searchText != "") {
				this.searchText = this.searchText.toLowerCase()
				const founds = this.allList.filter(element =>
					element.lastname?.toLowerCase().includes(this.searchText) ||
					element.firstname?.toLowerCase().includes(this.searchText)
				);

				this.employees = founds
			} else {
				this.employees = this.allList
			}
		},
		check(id, all = false) {
			if (all) {
				if (this.selectedList.length !== this.employees.length) {
					this.selectedList.length = 0;
					this.employees.forEach((employee) => {
						this.selectedList.push({ id: employee.id });
					});
				} else {
					this.selectedList.length = 0;
				}
			} else {
				let found = false;
				this.selectedList.forEach((element) => {
					if (element.id == id) {
						found = true;
					}
				});
				if (found) {
					this.selectedList.filter((value, index) => {
						if (value.id == id) {
							this.selectedList.splice(index, 1);
						}
					});
				} else {
					this.selectedList.push({ id: id });
				}
			}
			if (this.selectedList.length === this.employees.length) {
				this.allIsCheck = true;
			} else {
				this.allIsCheck = false;
			}
		},
		show(index) {
			return (
				index < this.page * this.perPage &&
				index + 1 > (this.page - 1) * this.perPage
			);
		},
		next() {
			if (this.page * this.perPage < this.employees.length) {
				this.page++;
			}
		},
		previous() {
			if (this.page >= 2) {
				this.page--;
			}
		},
		isSelected(id) {
			let found = false;
			this.selectedList.forEach((element) => {
				if (element.id == id) {
					found = true;
				}
			});
			return found;
		},
		loadList() {
			this.loading = true;
			fetch(
				Helper.route(
					'admin/employee/all?sortBy=' + this.orderBy + '&order=' + this.order
				),
				Helper.requestOptions()
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					if (data.data) {
						this.employees = data.data;
						this.salaryCharge = data.stats.salaryCharge;
						this.allList = this.employees
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		loadStats() {
			this.loading = true;
			fetch(Helper.route('admin/employee/stats'), Helper.requestOptions())
				.then((response) => response.json())
				.then((data) => {
					this.loading = false;
					console.log(data);
					this.stats = data.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		deletes() {
			this.$isLoading(true);
			let body = {
				ids: this.selectedList.map((element) => element.id),
			};
			fetch(
				Helper.route('admin/employee/delete'),
				Helper.requestOptionsJSON('DELETE', body)
			)
				.then((response) => response.json())
				.then((data) => {
					this.$isLoading(false);
					console.log(data);
					this.deleted = true;
					for (let i = 0; i < this.selectedList.length; i++) {
						const element = this.selectedList[i];
						this.employees = this.employees.filter((i) => i.id !== element.id);
						this.bests = this.bests.filter((i) => i.id !== element.id);
					}
					this.selectedList = [];
					// Helper.notification("success", data.message)
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
		},
		getSections(sections) {
			return Helper.sections(sections);
		},
		getJobs(jobs) {
			return Helper.jobs(jobs);
		},
	},
	created() {
		this.loadList();
		this.loadStats();
		fetch(Helper.route('admin/employee/best'), Helper.requestOptions())
			.then((response) => response.json())
			.then((data) => {
				if (data.data) {
					this.bests = data.data;
				}
			})
			.catch((error) => {
				console.log(error);
			});
	},
	watch: {
		searchText: function () {
			this.search();
		},
		orderBy: function () {
			this.loadList();
		},
		order: function () {
			this.loadList();
		},
		code: function () {
			if (this.check_form) {
				if (Helper.required(this.code) === '') {
					delete this.errors.code;
				} else {
					this.errors.code = Helper.required(this.code);
				}
			}
		},
		description: function () {
			if (this.check_form) {
				if (Helper.required(this.description) === '') {
					delete this.errors.description;
				} else {
					this.errors.description = Helper.required(this.description);
				}
			}
		},
		name: function () {
			if (this.check_form) {
				if (Helper.required(this.name) === '') {
					delete this.errors.name;
				} else {
					this.errors.name = Helper.required(this.name);
				}
			}
		},
	},
};
</script>
<style>
.rate_item img {
	height: 70px;
	width: 70px;
	object-fit: cover;
}
.employees th,
.employees td {
	color: white !important;
}
.employe_item img {
	height: 100px;
	width: 100px;
}
</style>
