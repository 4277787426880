<template>
	<div :title="`${percent}%`">
		<canvas ref="donutChart" :width="width" :height="height"></canvas>
	</div>
</template>
<style>
canvas {
	display: block;
	margin: 0 auto;
}
</style>
<script>
export default {
	name: 'HalfDonut',
	props: {
		percent: { default: 0 },
		width: { default: 200 },
		height: { default: 50 },
		radius: { default: 40 },
		background: { default: '#FFF' },
		foreground: { default: '#F00' },
	},
	mounted() {
		this.drawHalfDonut();
	},
	methods: {
		drawHalfDonut() {
			const canvas = this.$refs.donutChart;
			const ctx = canvas.getContext('2d');

			ctx.clearRect(0, 0, canvas.width, canvas.height);

			const centerX = canvas.width / 2;
			const centerY = canvas.height;
			const radius = this.radius;
			const startAngle = Math.PI;
			const endAngle = startAngle + (this.percent / 100) * Math.PI;
			const counterClockwise = false;

			ctx.beginPath();
			ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
			ctx.fillStyle = this.background;
			ctx.fill();

			ctx.beginPath();
			ctx.arc(centerX, centerY, radius, startAngle, endAngle, counterClockwise);
			ctx.lineTo(centerX, centerY);
			ctx.fillStyle = this.foreground;
			ctx.fill();
		},
	},
	watch: {
		percent() {
			this.drawHalfDonut();
		},
		foreground() {
			this.drawHalfDonut();
		},
		background() {
			this.drawHalfDonut();
		},
	},
};
</script>
