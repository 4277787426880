<template>
	<div>
		<input
			type="search"
			v-model="query"
			class="rounded text-6 px-5 py-1 border-0"
			:title="$t('topbar.search_placeholder')"
			:placeholder="$t('topbar.search_placeholder')"
			@input="search"
		/>
		<div v-if="results.length > 0" class="drp active">
			<div v-for="(result, index) in results" :key="index">
				<div
					class="text-6 text-black- py-2 drp-child px-2"
					role="button"
					@click="handleSubmit(result)"
				>
					{{ result.name }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SearchInput',
	props: {},
	data: function () {
		return {
			query: '',
			results: [],
		};
	},
	methods: {
		search() {
			let q = this.query;

			this.results = [];
			let uniqueResults = {};

			if (q.length < 2) {
				this.results = [];
				return [];
			}

			const data = localStorage.getItem('language') == 'en' ? this.searchDataEn() : this.searchDataFr();

			for (let category in data) {
				let words = data[category].words;

				for (const wordList of words) {
					const splits = wordList.split(' ');

					for (const word of splits) {
						if (word.toLowerCase().startsWith(q.toLowerCase())) {
							data[category].results.forEach((result) => {
								uniqueResults[result.link] = result;
							});
						}
					}
				}
			}

			this.results = Object.values(uniqueResults);
		},
		handleSubmit(result) {
			this.$router.push({ name: result.link });
		},
		searchDataFr() {
			return {
				document: {
					words: [
						'Document',
						'Fichiers',
						'Rapports',
						'Texte',
						'Article',
						'Feuille de calcul',
						'Présentation',
						'PDF',
						'Images',
						'Notes',
						'Procès-verbal',
						'Factures',
						'Livre blanc',
						'Mémoire',
						'CV',
						'Note de service',
						'Bibliothèque',
						'Favoris',
						'Importer',
					],
					results: [
						{
							name: 'Mes documents',
							link: 'documents',
						},
						{
							name: 'Ajouter un document',
							link: 'document-new',
						},
						{
							name: 'Documents favoris',
							link: 'documents-favorite',
						},
						{
							name: 'Documents archivés',
							link: 'documents-archives',
						},
					],
				},
				mail: {
					words: [
						'Emails',
						'Courriels',
						'Courriers',
						'Boîte de réception',
						"Boîte d'envoi",
						'Message',
					],
					results: [
						{
							name: 'Liste des courriers',
							link: 'mail',
						},
						{
							name: 'Ajouter un courrier',
							link: 'mail-add',
						},
					],
				},
				joboffer: {
					words: [
						"Offres d'emploi",
						'Annonces de poste',
						'Opportunités de carrière',
						'Recrutements',
					],
					results: [
						{
							name: "Nos offres d'emploi",
							link: 'offers',
						},
						{
							name: 'Ajouter une offre',
							link: 'offer-create',
						},
						{
							name: 'Profils de candidats',
							link: 'candidates-profiles',
						},
					],
				},
				ticket: {
					words: [
						'Tickets',
						'Billets',
						"Demandes d'assistance",
						'Assistances',
						'Aides',
						'Incidents',
						'Problèmes',
						'Supports',
						'Réclamations',
					],
					results: [
						{
							name: "Centre d'assistance",
							link: 'assistance',
						},
						{
							name: 'Créer un nouveau ticket',
							link: 'new-ticket',
						},
					],
				},
				task: {
					words: [
						'Tâches',
						'Outils',
						'Calendrier',
						'Taches',
						'Missions',
						'ToDo',
						'Agenda',
					],
					results: [
						{
							name: 'Ajouter une tâche',
							link: 'calendar-new',
						},
						{
							name: 'Mon agenda',
							link: 'tools',
						},
					],
				},
				sale: {
					words: [
						'Ventes',
						'Transactions',
						'Facturations',
						'Paiements',
						'Commandes',
						'Programme de gestion intégré',
					],
					results: [
						{
							name: 'Nos ventes',
							link: 'sales-index',
						},
						{
							name: 'Enregistrer une facture de vente',
							link: 'sales-create',
						},
						{
							name: 'Les factures de vente archivées',
							link: 'sales-archives',
						},
					],
				},
				buying: {
					words: [
						'Achats',
						'Acquisition',
						'Commandes',
						'Transactions',
						'Factures',
						'Programme de gestion intégré',
					],
					results: [
						{
							name: 'Nos achats',
							link: 'buying-index',
						},
						{
							name: "Enregistrer une facture d'achat",
							link: 'buying-create',
						},
						{
							name: "Les factures d'achat archivées",
							link: 'sales-archives',
						},
					],
				},
				application: {
					words: [
						'Candidatures',
						'Profils de candidats',
						'Candidats',
						'Postulants',
						'Demandeurs',
						'Personnes intéressées',
					],
					results: [
						{
							name: 'Profils de candidats',
							link: 'candidates-profiles',
						},
					],
				},
				employee: {
					words: [
						'Employés',
						'Collaborateurs',
						'Travailleurs',
						'Membres du personnel',
						'Ressources humaines',
						'Gestion des ressources humaines',
					],
					results: [
						{
							name: 'Liste des employés',
							link: 'employees',
						},
						{
							name: 'Ajouter un employé',
							link: 'employee-create',
						},
					],
				},
				training: {
					words: [
						'Formations',
						'Entraînements',
						'Ateliers',
						'Sessions de formation',
						'Didacticiels',
					],
					results: [
						{
							name: 'Liste des formations',
							link: 'formations',
						},
						{
							name: 'Ajouter une formation',
							link: 'formation-create',
						},
					],
				},
				section: {
					words: ['Sections', 'Parties', 'Rubrique', 'Catégories'],
					results: [
						{
							name: 'Liste des sections',
							link: 'sections',
						},
					],
				},
				profil: {
					words: [
						'Profil',
						'Compte utilisateur',
						'Identité',
						'Détails personnels',
						'Paramètres',
						'Sécurités',
						'Securites',
					],
					results: [
						{
							name: 'Paramètres',
							link: 'setting',
						},
					],
				},
				dashboard: {
					words: [
						'Tableau de bord',
						"Vue d'ensemble",
						'Tableau de bord utilisateur',
						'Statistiques',
					],
					results: [
						{
							name: 'Tableau de bord',
							link: 'dashboard',
						},
					],
				},
				subscription: {
					words: ['Abonnements', 'Tarifications', "Plans d'abonnement"],
					results: [
						{
							name: 'Mes abonnements',
							link: 'subscription',
						},
						{
							name: 'Tarification PGS',
							link: 'pricing',
						},
					],
				},
				job: {
					words: ['Postes', 'Fonctions', 'Occupations'],
					results: [
						{
							name: 'Liste des postes',
							link: 'postes',
						},
					],
				},
				contract: {
					words: ['Contrats', 'Conventions'],
					results: [
						{
							name: 'Liste des contrats',
							link: 'contrats',
						},
					],
				},
				comment: {
					words: [
						'Commentaires',
						'Remarques',
						'Opinions',
						'Réactions',
						'Feedbacks',
					],
					results: [
						{
							name: 'Commentaires sur PGS',
							link: 'comments',
						},
					],
				},
				notification: {
					words: ['Notifications', 'Alertes'],
					results: [
						{
							name: 'Mes notifications',
							link: 'notification',
						},
					],
				},
				coadmin: {
					words: [
						'Co-administrateur',
						'Coadministrateur',
						'Collaborateur administratif',
						'Admin',
					],
					results: [
						{
							name: 'Liste des co-administrateurs',
							link: 'admin',
						},
						{
							name: 'Ajouter un co-administrateurs',
							link: 'admin-create',
						},
						{
							name: 'Liste des invitations',
							link: 'admin-invitation',
						},
					],
				},
				home: {
					words: ['Accueil'],
					results: [
						{
							name: 'Accueil',
							link: 'home',
						},
					],
				},
			};
    },
    searchDataEn() {
    return {
        document: {
            words: [
                'Document',
                'Files',
                'Reports',
                'Text',
                'Article',
                'Spreadsheet',
                'Presentation',
                'PDF',
                'Images',
                'Notes',
                'Minutes',
                'Invoices',
                'White paper',
                'Thesis',
                'CV',
                'Memo',
                'Library',
                'Favorites',
                'Import',
            ],
            results: [
                {
                    name: 'My documents',
                    link: 'documents',
                },
                {
                    name: 'Add a document',
                    link: 'document-new',
                },
                {
                    name: 'Favorite documents',
                    link: 'documents-favorite',
                },
                {
                    name: 'Archived documents',
                    link: 'documents-archives',
                },
            ],
        },
        mail: {
            words: [
                'Emails',
                'Email',
                'Mails',
                'Inbox',
                'Sent',
                'Message',
            ],
            results: [
                {
                    name: 'Mail list',
                    link: 'mail',
                },
                {
                    name: 'Add an email',
                    link: 'mail-add',
                },
            ],
        },
        joboffer: {
            words: [
                'Job offers',
                'Job postings',
                'Career opportunities',
                'Recruitments',
            ],
            results: [
                {
                    name: 'Our job offers',
                    link: 'offers',
                },
                {
                    name: 'Add a job offer',
                    link: 'offer-create',
                },
                {
                    name: 'Candidate profiles',
                    link: 'candidates-profiles',
                },
            ],
        },
        ticket: {
            words: [
                'Tickets',
                'Tickets',
                'Support requests',
                'Assistance',
                'Help',
                'Incidents',
                'Issues',
                'Supports',
                'Claims',
            ],
            results: [
                {
                    name: 'Help center',
                    link: 'assistance',
                },
                {
                    name: 'Create a new ticket',
                    link: 'new-ticket',
                },
            ],
        },
        task: {
            words: [
                'Tasks',
                'Tools',
                'Calendar',
                'To-Do',
                'Missions',
                'ToDo',
                'Agenda',
            ],
            results: [
                {
                    name: 'Add a task',
                    link: 'calendar-new',
                },
                {
                    name: 'My calendar',
                    link: 'tools',
                },
            ],
        },
        sale: {
            words: [
                'Sales',
                'Transactions',
                'Invoices',
                'Payments',
                'Orders',
                'Enterprise Resource Planning',
            ],
            results: [
                {
                    name: 'Our sales',
                    link: 'sales-index',
                },
                {
                    name: 'Record a sales invoice',
                    link: 'sales-create',
                },
                {
                    name: 'Archived sales invoices',
                    link: 'sales-archives',
                },
            ],
        },
        buying: {
            words: [
                'Purchases',
                'Acquisition',
                'Orders',
                'Transactions',
                'Invoices',
                'Enterprise Resource Planning',
            ],
            results: [
                {
                    name: 'Our purchases',
                    link: 'buying-index',
                },
                {
                    name: 'Record a purchase invoice',
                    link: 'buying-create',
                },
                {
                    name: 'Archived purchase invoices',
                    link: 'sales-archives',
                },
            ],
        },
        application: {
            words: [
                'Applications',
                'Candidate profiles',
                'Applicants',
                'Candidates',
                'Applicants',
                'Interested individuals',
            ],
            results: [
                {
                    name: 'Candidate profiles',
                    link: 'candidates-profiles',
                },
            ],
        },
        employee: {
            words: [
                'Employees',
                'Colleagues',
                'Workers',
                'Staff members',
                'Human resources',
                'Human resource management',
            ],
            results: [
                {
                    name: 'Employee list',
                    link: 'employees',
                },
                {
                    name: 'Add an employee',
                    link: 'employee-create',
                },
            ],
        },
        training: {
            words: [
                'Trainings',
                'Training sessions',
                'Workshops',
                'Training sessions',
                'Tutorials',
            ],
            results: [
                {
                    name: 'Training list',
                    link: 'formations',
                },
                {
                    name: 'Add a training',
                    link: 'formation-create',
                },
            ],
        },
        section: {
            words: ['Sections', 'Parts', 'Section', 'Categories'],
            results: [
                {
                    name: 'Section list',
                    link: 'sections',
                },
            ],
        },
        profile: {
            words: [
                'Profile',
                'User account',
                'Identity',
                'Personal details',
                'Settings',
                'Securities',
            ],
            results: [
                {
                    name: 'Settings',
                    link: 'setting',
                },
            ],
        },
        dashboard: {
            words: [
                'Dashboard',
                'Overview',
                'User dashboard',
                'Statistics',
            ],
            results: [
                {
                    name: 'Dashboard',
                    link: 'dashboard',
                },
            ],
        },
        subscription: {
            words: ['Subscriptions', 'Pricing', 'Subscription plans'],
            results: [
                {
                    name: 'My subscriptions',
                    link: 'subscription',
                },
                {
                    name: 'PGS Pricing',
                    link: 'pricing',
                },
            ],
        },
        job: {
            words: ['Jobs', 'Positions', 'Roles'],
            results: [
                {
                    name: 'Job list',
                    link: 'postes',
                },
            ],
        },
        contract: {
            words: ['Contracts', 'Agreements'],
            results: [
                {
                    name: 'Contract list',
                    link: 'contrats',
                },
            ],
        },
        comment: {
            words: [
                'Comments',
                'Remarks',
                'Opinions',
                'Reactions',
                'Feedback',
            ],
            results: [
                {
                    name: 'PGS Comments',
                    link: 'comments',
                },
            ],
        },
        notification: {
            words: ['Notifications', 'Alerts'],
            results: [
                {
                    name: 'My notifications',
                    link: 'notification',
                },
            ],
        },
        coadmin: {
            words: [
                'Co-administrator',
                'Co-administrator',
                'Administrative collaborator',
                'Admin',
            ],
            results: [
                {
                    name: 'Co-administrator list',
                    link: 'admin',
                },
                {
                    name: 'Add a co-administrator',
                    link: 'admin-create',
                },
                {
                    name: 'Invitation list',
                    link: 'admin-invitation',
                },
            ],
        },
        home: {
            words: ['Home'],
            results: [
                {
                    name: 'Home',
                    link: 'home',
                },
            ],
        },
    };
},

	},
};
</script>
<style>
.drp {
	position: absolute !important;
	background-color: #fff;
	min-width: 200px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	display: none;
	border-radius: 8px;
}

.drp.active {
	display: block;
}

.drp-child:hover {
	background-color: #f6f6f6;
}
</style>
