<template>
	<div v-if="isMobile">
		<section class="p-5 py-4">
			<div style="height: 80vh">
				<div class="row h-100">
					<div class="my-auto col-xl-9 mx-auto text-center">
						<img
							src="images/nomobile.png"
							alt=""
							style="width: 55px; height: 55px"
						/>
						<p class="text-black mt-2">
							<!-- Cette plateforme n’est pas encore disponible sur mobile. Veuillez ressayer sur un ordinateur s’il vous plaît -->
							This app is not accessible on mobile devices. Retry on a computer,
							please.
						</p>
					</div>
				</div>
			</div>
		</section>
	</div>
	<div v-else class="hstack">
		<Sidebar :sidebarMenu="sidebarMenu" />
		<div class="content">
			<Topbar />
			<div
				v-show="title != 'null'"
				:class="title.length === 0 ? 'py-4' : 'py-3'"
				class="bg-light-hight- ps-5 pb-2"
			>
				{{ title }}
			</div>
			<div v-show="isOnline">
				<slot></slot>
			</div>
			<div v-show="!isOnline" class="text-center my-auto">
				<section class="p-5 py-4">
					<div style="height: 80vh">
						<div class="row h-100">
							<div class="my-auto col-xl-9 mx-auto text-center">
								<img
									src="images/nointernet.png"
									alt=""
									style="width: 55px; height: 55px"
								/>
								<p class="text-black mt-2">
									Vous semblez ne pas avoir accès à internet
								</p>
								<button
									class="button btn-sm bg-red- text-white-"
									@click="updateOnlineStatus"
								>
									Reéssayer
								</button>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</template>

<style>
.top-left {
	width: 263px;
	border-right: 1px solid #555;
}

.dropdown-menu li {
	position: relative;
}
.dropdown-menu .dropdown-submenu {
	display: none;
	position: absolute;
	left: 100%;
	top: -7px;
}
.dropdown-menu .dropdown-submenu-left {
	right: 100%;
	left: auto;
}
.dropdown-menu > li:hover > .dropdown-submenu {
	display: block;
}
.content {
	width: 100%;
	padding-top: 6vh;
	height: 100vh;
	overflow: auto;
}
</style>

<script>
import Sidebar from '../components/Sidebar.vue';
import Topbar from '@/components/Topbar.vue';
export default {
	name: 'TemplateComponent',
	props: {
		title: {
			default: '',
		},
		sidebarMenu: {
			default: '',
		},
	},
	data() {
		return {
			isOnline: navigator.onLine,
			isMobile: this.detectMobile(),
		};
	},
	created() {
		window.addEventListener('online', this.updateOnlineStatus);
		window.addEventListener('offline', this.updateOnlineStatus);
	},
	unmounted() {
		window.removeEventListener('online', this.updateOnlineStatus);
		window.removeEventListener('offline', this.updateOnlineStatus);
	},
	methods: {
		updateOnlineStatus() {
			this.isOnline = navigator.onLine;
		},
		detectMobile() {
			// Use regular expressions to detect mobile devices
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			);
		},
	},

	components: { Sidebar, Topbar },
};
</script>
