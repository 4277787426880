<template>
	<div>
		<Template :title="ticket.subject + ' (REF: ' + ticket.reference + ')'">
			<section class="p-5 py-4">
				<Back />

				<div v-if="loading == false">
					<div
						v-if="ticket.content != null"
						id="solved"
						:class="loading_status ? 'd-none' : ''"
						class="hstack mt-2"
					>
						<button
							@click="markAsSolved"
							v-if="ticket.status === 'pending'"
							class="bg-green- border-0 rounded ms-auto px-3 p-1"
						>
							<i class="uil uil-lock text-white"></i>
							<span class="ms-2 text-5 text-white">{{$t('others.mark_as_read')}}</span>
						</button>
						<button v-else class="bg-alt- border-0 rounded ms-auto px-3 p-1">
							<i class="uil uil-lock text-white"></i>
							<span class="ms-2 text-5 text-white">{{$t('others.resolved_subject')}}</span>
						</button>
					</div>
					<!-- <div
						id="spinner1"
						:class="!loading_status ? 'd-none' : ''"
						class="hstack mt-2"
					>
						<button class="bg-green- border-0 rounded ms-auto px-3 p-1">
							<span class="ms-2 text-5 text-white">Chargement...</span>
						</button>
					</div> -->

					<div class="mt-3" v-if="ticket.content != null">
						<div class="row m-0 py-3 assistance border bg-white-">
							<div class="col-md-3">
								<div class="pt-3 text-center">
									<img
										:src="ticket?.author?.photo?.url"
										class="rounded-circle border user_image"
										alt=""
									/>
									<h6 class="mt-2 text-5 text-black-">
										{{
											(ticket?.author?.firstname?? "") + ' ' + (ticket?.author?.lastname?? "")
										}}
									</h6>
									<p class="text-primary- mb-0">{{ ticket.company?.name }}</p>
									<i class="uil uil-message fs-5 text-primary-"></i>
								</div>
							</div>
							<div class="col-md-9">
								<div
									class="border shadow-1 rounded bg-white- text-4 text-black- p-3"
								>
									<h6 class="fw-600 text-4 text-alt-">
										{{$t('others.ticket')}} : #{{ ticket.id }} - {{ ticket.object }}
									</h6>
									<p class="text-6">
										{{$t('others.write_by')}} :
										{{
											(ticket.author?.firstname?? "") + ' ' + (ticket.author?.lastname?? "")
										}}
										{{$t('others.ask_on')}} :
										{{ $filters.convertDate(ticket.createdAt) }}
									</p>
									<p class="text-5 text-justify lh-lg">
										{{ ticket.content }}
									</p>
									<div>
										<li v-for="(file, index) in ticket.files" :key="file.id">
											<a
												class="text-5 fw-900 text-dark m-0 mb-2"
												@click="downloadFile(file.url, file.name)"
												>{{$t('others.download_file_number')}} {{ index + 1 }}
											</a>
										</li>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div
						class="bg-alt- shadow-sm mb-0 text-center rounded text-white px-4 p-2 mt-2"
						v-if="ticket.content != null"
					>
						{{$t('others.response_to_ticket')}}
					</div>

					<div
						class="mt-2"
						v-for="(response, index) in ticket.responses"
						:key="response.id"
					>
						<div class="row m-0 py-3 assistance border bg-white-">
							<div class="col-md-3">
								<div v-if="!response?.fromSuperAdmin" class="pt-3 text-center">
									<img
										:src="response?.author?.photo.url"
										class="rounded-circle border user_image"
										alt=""
									/>
									<h6 class="mt-2 text-5 text-black-">
										{{
											(response?.author?.firstname?? "") +
											' ' +
											(response?.author?.lastname?? "")
										}}
									</h6>
									<p class="text-primary- mb-0">
										{{ response?.author?.company?.name }}
									</p>
									<i class="uil uil-message fs-5 text-primary-"></i>
								</div>
								<div v-else class="pt-3 text-center">
									<img
										src="@/assets/images/SANS_FOND_LOGO_NOIR.png"
										class="rounded-circle border user_image"
										alt=""
									/>
									<h6 class="mt-2 text-5 text-black-">{{$t('others.technician')}}</h6>
									<p class="text-primary- mb-0">PRO GESTION SOFT</p>
									<i class="uil uil-message fs-5 text-primary-"></i>
								</div>
							</div>
							<div class="col-md-9">
								<div
									class="border shadow-1 rounded bg-white- text-4 text-black- p-3"
								>
									<h6 class="fw-600 text-4 text-alt-">
										{{$t('others.answer')}} {{ index + 1 }}
									</h6>
									<p class="text-6">
										{{$t('others.write_by')}} :
										{{
											(response?.author?.firstname?? "") +
											' ' +
											(response?.author?.lastname?? "")
										}}
										{{$t('others.the')}} : {{ $filters.convertDate(response.createdAt) }}
									</p>
									<p
										class="text-5 text-justify lh-lg"
										v-html="$filters.decodeUri(response.content)"
									></p>
								</div>
							</div>
						</div>
					</div>

					<!-- REPONDRE -->
					<form
						@submit.prevent="reply"
						v-if="ticket.status == 'pending'"
						class="mt-2"
					>
						<div class="row m-0 py-3 assistance border bg-white-">
							<div class="col-md-3">
								<div class="pt-3 text-center">
									<img
										:src="user?.photo?.url"
										class="rounded-circle user_image border"
										alt=""
									/>
									<h6 class="mt-2 text-5 text-black-">
										{{ (user?.firstname?? "") + ' ' + (user?.lastname?? "") }}
									</h6>
									<p class="text-primary- mb-0">{{ user?.company?.name }}</p>
									<i class="uil uil-message fs-5 text-primary-"></i>
								</div>
							</div>
							<div class="col-md-9">
								<InputFC
									v-model="content"
									:type="'textarea'"
									:error="errors.content"
									:placeholder="''"
								/>
								<SpinnerMe :elementId="'spinner'" />
								<button class="button mt-3" id="submit">
									<i class="uil uil-message fs-6 me-2 text-white-"></i>
									{{ $t('global.send') }}
								</button>
							</div>
						</div>
					</form>
				</div>

				<div v-if="notFound" class="mt-4 text-center text-red-">
					{{$t('others.ticket_no_found')}}
				</div>

				<LoadingMain :state="true" v-if="loading == true" />
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import Helper from '@/helpers/helper';
import LoadingMain from '@/components/LoadingMain.vue';
import InputFC from '@/components/InputFC.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
import { useUserStore } from '@/store/user';
export default {
	watch: {},
	name: 'ShowTicket',
	setup() {
		const store = useUserStore();
		return { store };
	},
	components: { Template, Back, LoadingMain, InputFC, SpinnerMe },
	data() {
		return {
			id: '',
			errors: [],
			ticket: { subject: '', reference: '########', content: null },
			status: '',
			content: '',
			loading: false,
			user: this.store.user,
			loading_status: false,
			notFound: false,
		};
	},
	methods: {
		downloadFile(l, n) {
			Helper.downloadFile(l, n, this);
		},
		getTicket() {
			this.loading = true;
			fetch(Helper.route('admin/ticket/' + this.id), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					console.log(data);
					if (data.data) {
						this.loading = false;
						this.ticket = data.data;
					} else {
						this.loading = false;
						this.notFound = true;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		reply() {
			if (this.beforeReply()) {
				this.ok = true;
				this.error_message = '';
				Helper.loading('submit', 'spinner', true);
				let registerFormData = new FormData();
				registerFormData.append('id', this.id);
				registerFormData.append('content', this.content);

				fetch(
					Helper.route('admin/ticket/reply'),
					Helper.requestOptions('POST', registerFormData)
				)
					.then((response) => {
						if (response.status === 401) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						Helper.loading('submit', 'spinner', false);

						if (data.problems) {
							this.errors.content = Helper.getError(data.problems, 'content');
						} else {
							if (!this.ok) {
								this.error_message = data.message;
							} else {
								if (data.status) {
									this.ticket.responses.push(data.data);
									this.content = '';
									// this.$router.go(0)
								}
							}
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit', 'spinner', false);
					});
			}
		},
		markAsSolved() {
			this.ok = true;
			this.loading_status = true;
			this.error_message = '';
			Helper.loading('solved', 'spinner1', true);
			let registerFormData = new FormData();
			registerFormData.append('id', this.id);

			fetch(
				Helper.route('admin/ticket/mark-as-solved/' + this.id),
				Helper.requestOptions('PUT', registerFormData)
			)
				.then((response) => response.json())
				.then((data) => {
					this.loading_status = false;
					console.log(data);
					if (data.status) {
						Helper.loading('solved', 'spinner1', false);
						this.ticket.status = 'solved';
					}
				})
				.catch((error) => {
					Helper.loading('solved', 'spinner1', false);
					this.loading_status = false;
					console.log(error);
				});
		},
		beforeReply() {
			if (Helper.required(this.content) === '') {
				delete this.errors.content;
			} else {
				this.errors.content = Helper.required(this.content);
			}
			return Helper.objectNumberKey(this.errors) == 0 ? true : false;
		},
		getUser() {
			fetch(Helper.route('admin/profile/get-data'), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.user = data.data;
					this.store.setUser(this.user);
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
	created() {
		this.id = this.$route.params.id;
		this.user = this.store.user;
		this.getTicket();
		this.getUser();
	},
};
</script>
<style>
.assistance .user_image {
	height: 100px !important;
	width: 100px !important;
}
</style>
