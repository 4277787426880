<template>
	<div>
		<Template>
			<section class="p-5 py-4">
				<div style="height: 80vh">
					<div class="row h-100">
						<div class="my-auto col-xl-9 mx-auto text-center">
							<h1 class="fs-1 text-uppercase text-red- text-center">
								{{ $t('global.unauthorized') }}
							</h1>
							<p class="text-black- text-5 my-4 lh-lg">
								{{ $t('global.not_right') }}
							</p>
							<button class="button bg-alt-" @click="$router.go(-1)">
								<i class="uil uil-arrow-left text-white- me-2"></i>
								{{ $t('global.back') }}
							</button>
						</div>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
export default {
	name: 'HomePage',
	components: { Template },
};
</script>
<style></style>
