<template>
	<div>
		<Template title="null" sidebarMenu="employees">
			<div class="bg-light-hight- hstack py-3 ps-2 pb-3">
				<router-link
					to="/employees"
					class="bg-light-hight- ms-3 text-6 top-item"
					>{{ $t('resources.employees') }}</router-link
				>
				<router-link
					to="/sections"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('resources.sections') }}</router-link
				>
				<router-link
					to="/postes"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('resources.postes') }}</router-link
				>
				<router-link
					to="/contrats"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('resources.contracts') }}</router-link
				>
				<router-link
					to="/formations"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('resources.training') }}</router-link
				>
			</div>

			<div class="row px-4 m-0 g-4 mt-1">
				<!-- Modal -->
				<div
					class="modal fade"
					id="createjob"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="createjobLabel"
					aria-hidden="true"
				>
					<div
						class="modal-dialog modal-dialog-scrollable modal-dialog-centered"
					>
						<div class="modal-content rounded-0">
							<form @submit.prevent="addJob" class="modal-body">
								<h6 class="text-center text-alt-">
									{{
										job_id == ''
											? $t('poste.create_poste')
											: $t('poste.update_poste')
									}}
								</h6>
								<hr />
								<div class="mb-2 text-primary-" v-show="message_job_add != ''">
									{{ message_job_add }}
								</div>
								<InputFC
									:error="errors.designation"
									:type="'text'"
									v-model="designation"
									:required="true"
									:placeholder="$t('poste.job_title')"
								/>
								<InputFC
									:error="errors.description"
									:type="'textarea'"
									:required="true"
									class="mt-4"
									v-model="description"
									:placeholder="$t('poste.job_description')"
								/>
								<hr />
								<div class="row">
									<div class="col mt-4 text-center">
										<span
											type="button"
											class="border bg-light px-3 text-5 rounded py-1"
											data-mdb-dismiss="modal"
											>{{ $t('global.close') }}</span
										>
									</div>
									<div class="col text-center">
										<SpinnerMe :elementId="'spinner'" />
										<input
											type="submit"
											value="Enregistrer"
											id="submit"
											class="bg-alt- mt-4 text-white- px-3 rounded py-1 text-5 border-0 set-opacity"
										/>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div class="col-xl-9">
					<div class="mb-5">
						<div class="hstack">
							<button
								class="bg-second- rounded ms-auto px-3 p-1"
								type="button"
								@click="job_id = ''"
								data-mdb-toggle="modal"
								data-mdb-target="#createjob"
							>
								<i class="uil uil-plus text-white"></i>
								<span class="ms-2 text-6 text-white">{{
									$t('poste.add_a_job')
								}}</span>
							</button>
						</div>

						<div class="hstack mt-4 p-2 px-4 rounded shadow-1 bg-white">
							<!-- <input type="checkbox" :checked="allIsCheck" @click="check(0, true)"> -->
							<span>#</span>

							<div class="hstack ms-auto">
								<label for="" class="text-5 me-2">{{
									$t('global.filter')
								}}</label>
								<select class="form-control text-6" v-model="orderBy">
									<option value="id">
										{{ $t('global.order_of_addition') }}
									</option>
									<option value="updatedAt">
										{{ $t('poste.update_the') }}
									</option>
									<option value="designation">
										{{ $t('poste.designation') }}
									</option>
								</select>

								<label for="" class="text-5 ms-2 me-2">{{
									$t('global.order')
								}}</label>
								<select class="form-control text-6" v-model="order">
									<option value="ASC">Asc</option>
									<option value="DESC">Desc</option>
								</select>
							</div>

							<div class="ms-auto hstack">
								<input type="search" v-model="searchText" class="form-control mx-2 w-40" :placeholder="$t('tools.search')">
								<i
									class="uil uil-arrow-circle-left fs-5"
									type="button"
									@click="previous"
								></i>
								<small class="ms-3"
									>{{ Math.ceil(jobs.length / 25) > 0 ? page : 0 }} /
									{{ Math.ceil(jobs.length / 25) }}</small
								>
								<i
									class="uil uil-arrow-circle-right fs-5 ms-3"
									type="button"
									@click="next"
								></i>
								<div class="hstack ms-5">
									<i
										class="uil uil-list-ul fs-6 ms-1 p-1 px-3 rounded"
										:class="
											disposition == 'list'
												? 'bg-black- text-white-'
												: 'bg-white- text-black-'
										"
										@click="disposition = 'list'"
										type="button"
									></i>
								</div>
							</div>
						</div>

						<LoadingMain :state="loading" />

						<div v-if="!loading && jobs.length != 0">
							<div class="table-responsive bg-red- mt-0">
								<table class="table jobs align-middle table-hover table-striped mb-0" style="width: 100% !important">
									<thead class="bg-primary-">
										<tr class="text-6 text-white">
											<th></th>
											<th>{{ $t('poste.designation') }}</th>
											<th>{{ $t('global.description') }}</th>
											<th class="text-center">{{ $t('global.action') }}</th>
										</tr>
									</thead>
									<tbody class="bg-alt-">
										<tr
											v-for="(job, index) in jobs"
											:key="job.id"
											v-show="show(index)"
											class="text-6 text-black"
										>
											<td>{{ index + 1 }}</td>
											<td>
												<router-link
													:to="{
														name: 'postes-show',
														params: { id: job.id, slug: job.slug },
													}"
												>
													{{ job.designation }}
												</router-link>
											</td>
											<td>{{ job.description }}</td>
											<td class="text-center">
												<i
													class="uil uil-pen fs-6 text-white"
													type="button"
													@click="editInit(job)"
													data-mdb-toggle="modal"
													data-mdb-target="#createjob"
												></i>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div
							class="py-4 text-center text-black-"
							v-if="!loading && jobs.length == 0"
						>
							{{ $t('poste.no_job_found') }}
						</div>
					</div>
				</div>

				<div class="col-xl-3">
					<div class="rounded bg-second- text-white text-4 text-center p-1">
						{{ $t('global.advice') }}
					</div>
					<div
						class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
						v-html="$t('poste.poste_advice_text')"
					></div>
				</div>
			</div>
		</Template>
	</div>
</template>

<script>
import Template from '@/components/Template.vue';
import Helper from '@/helpers/helper';
import LoadingMain from '@/components/LoadingMain.vue';
import InputFC from '@/components/InputFC.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
export default {
	name: 'jobIndex',
	components: { Template, LoadingMain, InputFC, SpinnerMe },
	data() {
		return {
			deleted: false,
			archived: false,
			loading: true,
			selectedList: [],
			allIsCheck: false,
			page: 1,
			perPage: 25,
			orderBy: 'id',
			order: 'DESC',
			disposition: 'list',
			jobs: [],
			errors: [],
			designation: '',
			check_form: false,
			message_job_add: '',
			description: '',
			job_id: '',
			allList: [],
			searchText: ""
		};
	},
	methods: {
		search() {
			if (this.searchText != "") {
				this.searchText = this.searchText.toLowerCase()
				const founds = this.allList.filter(element =>
					element.designation?.toLowerCase().includes(this.searchText) 
				);

				this.jobs = founds
			} else {
				this.jobs = this.allList
			}
		},
		check(id, all = false) {
			if (all) {
				if (this.selectedList.length !== this.jobs.length) {
					this.selectedList.length = 0;
					this.jobs.forEach((job) => {
						this.selectedList.push({ id: job.id });
					});
				} else {
					this.selectedList.length = 0;
				}
			} else {
				let found = false;
				this.selectedList.forEach((element) => {
					if (element.id == id) {
						found = true;
					}
				});
				if (found) {
					this.selectedList.filter((value, index) => {
						if (value.id == id) {
							this.selectedList.splice(index, 1);
						}
					});
				} else {
					this.selectedList.push({ id: id });
				}
			}
			if (this.selectedList.length === this.jobs.length) {
				this.allIsCheck = true;
			} else {
				this.allIsCheck = false;
			}
		},
		show(index) {
			return (
				index < this.page * this.perPage &&
				index + 1 > (this.page - 1) * this.perPage
			);
		},
		next() {
			if (this.page * this.perPage < this.jobs.length) {
				this.page++;
			}
		},
		previous() {
			if (this.page >= 2) {
				this.page--;
			}
		},
		isSelected(id) {
			let found = false;
			this.selectedList.forEach((element) => {
				if (element.id == id) {
					found = true;
				}
			});
			return found;
		},
		editInit(job) {
			this.message_job_add = '';
			this.job_id = job.id;
			this.designation = job.designation;
			this.description = job.description;
		},
		addJob() {
			this.message_job_add = '';
			if (this.job_id == '') {
				this.ok = true;
				Helper.loading('submit', 'spinner', true);
				let registerFormData = new FormData();
				registerFormData.append('description', this.description);
				registerFormData.append('designation', this.designation);

				fetch(
					Helper.route('admin/job/add'),
					Helper.requestOptions('POST', registerFormData)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						if (response.status != 200) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						Helper.loading('submit', 'spinner', false);
						console.log(data);

						if (data.problems) {
							this.errors.designation = Helper.getError(
								data.problems,
								'designation'
							);
							this.errors.description = Helper.getError(
								data.problems,
								'description'
							);
						} else {
							this.message_job_add = data.message;
							this.jobs.unshift(data.data);
							this.designation = '';
							this.description = '';
							Helper.notification('success', data.message);
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit', 'spinner', false);
					});
			} else {
				this.ok = true;
				Helper.loading('submit', 'spinner', true);
				let registerFormData = new FormData();
				registerFormData.append('id', this.job_id);
				registerFormData.append('description', this.description);
				registerFormData.append('designation', this.designation);

				fetch(
					Helper.route('admin/job/update'),
					Helper.requestOptions('PUT', registerFormData)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						if (response.status != 200) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						Helper.loading('submit', 'spinner', false);
						console.log(data);

						if (data.problems) {
							this.errors.designation = Helper.getError(
								data.problems,
								'designation'
							);
							this.errors.description = Helper.getError(
								data.problems,
								'description'
							);
						} else {
							this.jobs = this.jobs.map((i) =>
								i.id !== this.job_id ? i : data.data
							);
							this.message_job_add = data.message;
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit', 'spinner', false);
					});
			}
		},
		loadList() {
			this.loading = true;
			fetch(
				Helper.route(
					'admin/job/all?sortBy=' + this.orderBy + '&order=' + this.order
				),
				Helper.requestOptions()
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					console.log(data);
					this.loading = false;
					if (data.data) {
						this.jobs = data.data;
						this.allList = this.jobs
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
	created() {
		this.loadList();
	},
	watch: {
		orderBy: function () {
			this.loadList();
		},
		order: function () {
			this.loadList();
		},
		searchText: function () {
			this.search();
		},
	},
};
</script>

<style>
.jobs th,
.jobs td {
	color: white !important;
}
</style>
