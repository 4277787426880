<template>
	<div>
		<Template title="Profil candidat" sidebarMenu="offers">
			<section class="p-5 py-4">
				<Back />

				<div class="row">
					<div class="col-xl-9 mt-3">
						<div
							class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 p-1"
						>
							{{$t('candidate.offers.employee_profile')}}
						</div>

						<div v-if="!loading">
							<div
								v-if="application.applicant"
								class="text-center border mt-3 pt-4"
							>
								{{$t('candidate.profile.candidacy_infos')}}
								<div
									class="text-center status mt-3"
									v-if="application.status == 'refused'"
								>
									<div class="bg-red- text-white rounded-pill px-3">
										{{$t('candidate.profile.candidacy_denied')}}
									</div>
								</div>
								<div
									class="text-center status mt-3"
									v-else-if="application.status == 'pending'"
								>
									<div class="bg-light text-dark rounded-pill px-3">
										{{$t('candidate.profile.candidacy_pending')}}
									</div>
								</div>
								<div class="text-center status mt-3" v-else>
									<div class="bg-green- text-white rounded-pill px-3">
										{{$t('candidate.profile.candidacy_accepted')}}
									</div>
								</div>
								<div class="text-4 py-2 fw-500 text-black-">
									{{ application.applicant.lastname }}
									{{ application.applicant.firstname }}
								</div>
							</div>

							<div v-if="step == 1" class="border mt-3 pt-4 pb-5">
								<div class="mx-auto">
									<div
										class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
									>
										{{$t('candidate.profile.general_infos')}}
									</div>
									<fieldset class="px-5 py-3">
										<!-- <legend class="text-center">Informations générales</legend> -->
										<div class="row">
											<InputFCV
												class="col-6"
												:placeholder="$t('global.lastname')"
												:modelValue="application.applicant.lastname"
												disabled
											/>

											<InputFCV
												class="col-6"
												:placeholder="$t('global.firstname')"
												:modelValue="application.applicant.firstname"
												disabled
											/>
										</div>

										<div class="row mt-4">
											<InputFCV
												class="col-6"
												:placeholder="$t('global.burn_date')"
												:modelValue="
													$filters.convertDate(
														application.applicant.birthdate,
														false
													)
												"
												disabled
											/>

											<InputFCV
												class="col-6"
												:placeholder="$t('global.birth_place')"
												:modelValue="application.applicant.birthplace"
												disabled
											/>
										</div>
										<div class="row mt-4">
											<InputFCV
												class="col-6"
												:placeholder="$t('global.nationality')"
												:modelValue="application.applicant.country"
												disabled
											/>

											<InputFCV
												class="col-6"
												:placeholder="$t('global.gender')"
												:modelValue="
													application.applicant.gender == 'M'
														? $t('global.male')
														: $t('global.feminine')
												"
												disabled
											/>
										</div>
									</fieldset>

									<div
										class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
									>
										{{$t('candidate.profile.contact_residence')}}
									</div>

									<fieldset class="px-5 py-3">
										<div class="row">
											<InputFCV
												class="col-6"
												:placeholder="$t('global.phone')+' 1'"
												:modelValue="application.applicant.phoneNumber1"
												disabled
											/>

											<InputFCV
												class="col-6"
												:placeholder="$t('global.phone')+' 2'"
												:modelValue="application.applicant.phoneNumber2"
												disabled
											/>
										</div>

										<div class="row g-0 m-0 mt-4">
											<InputFCV
												class=""
												:placeholder="$t('global.email')"
												:modelValue="application.applicant.emailAddress"
												disabled
											/>
										</div>

										<div class="row mt-5">
											<InputFCV
												class="col-6"
												:placeholder="$t('global.country')"
												:modelValue="application.applicant.country"
												disabled
											/>

											<InputFCV
												class="col-6"
												:placeholder="$t('global.department')"
												:modelValue="application.applicant.department"
												disabled
											/>
										</div>

										<div class="row mt-4">
											<InputFCV
												class="col-6"
												:placeholder="$t('global.municipal')"
												:modelValue="application.applicant.township"
												disabled
											/>

											<InputFCV
												class="col-6"
												:placeholder="$t('global.city')"
												:modelValue="application.applicant.town"
												disabled
											/>
										</div>

										<div class="row g-0 m-0 mt-4">
											<InputFCV
												class=""
												:placeholder="$t('candidate.profile.marital_status')"
												:modelValue="application.applicant.maritalStatus"
												disabled
											/>
										</div>
									</fieldset>

									<hr />

									<div class="hstack px-5">
										<div class="">
											<button @click="step = 2" class="button px-4 py-1 mt-4">
												{{$t('global.next')}}
												<i class="uil uil-arrow-right text-white fs-6"></i>
											</button>
										</div>
										<div class="col"></div>
										<div class="ms-3">
											<button
												class="button-default bg-light- text-color- fw-500 shadow-sm px-4 py-1 mt-4"
												@click="$router.go(-1)"
											>
												{{ $t('global.cancel') }}
											</button>
										</div>
									</div>
								</div>
							</div>

							<div v-if="step == 2" class="border mt-3 pt-4 pb-5">
								<div class="mx-auto">
									<div
										class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
									>
										{{$t('candidate.profile.skills_training')}}
									</div>

									<fieldset class="px-5 py-3">
										<div class="row g-0">
											<InputFCV
												class=""
												:placeholder="$t('candidate.profile.last_diploma')"
												:modelValue="application.applicant.lastDiploma"
												disabled
											/>
										</div>

										<div class="row mt-4">
											<InputFCV
												class="col-6"
												:placeholder="$t('candidate.profile.graduation_year')"
												:modelValue="
													$filters.convertDate(
														application.applicant.lastDiplomaDate,
														false
													)
												"
												disabled
											/>

											<InputFCV
												class="col-6"
												:placeholder="$t('candidate.profile.training_area')"
												:modelValue="application.applicant.studyField"
												disabled
											/>
										</div>

										<div
											v-for="(item, index) in application.applicant.experiences"
											:key="index"
											class="row mt-5"
										>
											<InputFCV
												class="col-6"
												:placeholder="'Structure'"
												:modelValue="item.structure"
												disabled
											/>

											<InputFCV
												class="col-6"
												:placeholder="'Durée (mois)'"
												:modelValue="item.duration"
												disabled
											/>
										</div>
									</fieldset>

									<div
										class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
									>
										{{$t('candidate.profile.tech_and_language')}}
									</div>

									<fieldset class="px-5 py-3">
										<div
											v-for="(item, index) in application.applicant.languages"
											:key="index"
											class="row mb-4"
										>
											<InputFCV
												class="col-4"
												:placeholder="$t('candidate.profile.language')"
												:modelValue="item.language"
												disabled
											/>

											<InputFCV
												class="col-4"
												:placeholder="$t('candidate.profile.read')"
												:modelValue="item.reading"
												disabled
											/>

											<InputFCV
												class="col-4"
												:placeholder="$t('candidate.pofile.write')"
												:modelValue="item.writing"
												disabled
											/>
										</div>

										<div
											v-for="(item, index) in application.applicant.softwares"
											:key="index"
											class="row mt-4"
										>
											<InputFCV
												class="col-6"
												:placeholder="$t('candidate.profile.software')"
												:modelValue="item.software"
												disabled
											/>

											<InputFCV
												class="col-6"
												:placeholder="$t('candidate.profile.level')"
												:modelValue="item.level"
												disabled
											/>
										</div>
									</fieldset>

									<hr />

									<div class="hstack px-5">
										<div class="">
											<button @click="step = 1" class="button px-4 py-1 mt-4">
												<i class="uil uil-arrow-left text-white fs-6"></i>
												{{$t('global.previous')}}
											</button>
										</div>
										<div class="ms-3">
											<button
												class="button-default bg-light- text-color- fw-500 shadow-sm px-4 py-1 mt-4"
												@click="$router.go(-1)"
											>
												{{ $t('global.cancel') }}
											</button>
										</div>
										<div class="col"></div>
										<div class="ms-3">
											<button @click="step = 3" class="button px-4 py-1 mt-4">
												{{$t('global.next')}}
												<i class="uil uil-arrow-right text-white fs-6"></i>
											</button>
										</div>
									</div>
								</div>
							</div>

							<div v-if="step == 3" class="border mt-3 pt-4 pb-5">
								<div class="mx-auto">
									<div
										class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
									>
										{{$t('candidate.profile.download_doc')}}
									</div>

									<fieldset class="px-5 py-3">
										<div class="row mt-2">
											<div class="col-6">{{$t('candidate.profile.idcard')}} :</div>
											<div class="col-6 text-primary">
												<router-link
													:to="{
														name: 'documents-show',
														params: {
															id: application.documents[0].reference,
															slug: application.documents[0].slug,
														},
													}"
													>{{
														$filters.truncate(application.documents[0].slug, 25)
													}}</router-link
												>
											</div>
										</div>

										<div class="row mt-2">
											<div class="col-6">{{$t('candidate.profile.professional_photo')}} :</div>
											<div class="col-6 text-primary">
												<router-link
													:to="{
														name: 'documents-show',
														params: {
															id: application.documents[1].reference,
															slug: application.documents[1].slug,
														},
													}"
													>{{
														$filters.truncate(application.documents[1].slug)
													}}</router-link
												>
											</div>
										</div>

										<div class="row mt-2">
											<div class="col-6">{{$t('candidate.profile.resume')}} :</div>
											<div class="col-6 text-primary">
												<router-link
													:to="{
														name: 'documents-show',
														params: {
															id: application.documents[2].reference,
															slug: application.documents[2].slug,
														},
													}"
													>{{
														$filters.truncate(application.documents[2].slug)
													}}</router-link
												>
											</div>
										</div>

										<div
											class="row mt-2"
											v-if="application.documents.length > 3"
										>
											<div class="col-6">{{$t('candidate.profile.other_docs')}} :</div>
											<div class="col-6 text-primary">
												<div
													class="row"
													v-for="(item, index) in application.documents"
													:key="index"
												>
													<div v-if="index > 2" class="col-12 text-primary">
														<router-link
															:to="{
																name: 'documents-show',
																params: {
																	id: item.reference,
																	slug: item.slug,
																},
															}"
															>{{ $filters.truncate(item.slug) }}</router-link
														>
													</div>
												</div>
											</div>
										</div>
									</fieldset>

									<div
										class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
									>
										{{$t('candidate.profile.test_result')}}
									</div>

									<fieldset
										class="px-5 py-3"
										v-if="application.test.length > 0"
									>
										<div class="my-2">
											<span class="text-6"
												>{{$t('candidate.profile.candidate_grade')}}
											</span>
											<kbd class="bg-white rounded-9 text-red- text-3"
												>{{ application.testResult.points }}/{{
													application.testResult.total
												}}</kbd
											>
											<span class="text-6"> {{$t('candidate.profile.after_test')}}. </span>
										</div>

										<div v-for="(item, index) in application.test" :key="index">
											<div class="text-black-">
												Q{{ index + 1 }}- {{ item.question.statement }}
												<span class="text-5 text-primary-"
													>({{
														item.question.points +
														' point' +
														(item.question.points > 1 ? 's' : '')
													}})</span
												>
												<kbd class="text-warning bg-white text-6 rounded-9 mx-2"
													>{{$t('candidate.offers.rating')}}:{{
														item.question.isStrict ? 'stricte' : 'flexible'
													}}</kbd
												>
											</div>

											<ul>
												<li v-for="(answer, i) in item.answers" :key="i">
													<span class="text-5 text-black-">* </span>
													<span class="text-5 me-2 0">{{
														answer.statement
													}}</span>
													<i
														v-if="
															answer.isCorrect == true ||
															answer.isCorrect == 'true'
														"
														class="text-4 uil uil-check-circle text-green-"
													></i>
													<i
														v-else
														class="text-4 uil uil-times-circle text-red-"
													></i>
												</li>
											</ul>
										</div>
									</fieldset>
									<div v-else class="px-5 py-3">{{$t('candidate.profile.no_test')}}</div>

									<div
										class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
									>
										{{$t('candidate.profile.action')}}
									</div>

									<fieldset class="px-5 py-3">
										<InputFC
											:type="'select'"
											:options="application_status"
											class=""
											:placeholder="$t('candidate.profile.candidacy_status')"
											v-model="applicationStatus"
										/>
									</fieldset>

									<div class="hstack px-5">
										<div class="">
											<button @click="step = 2" class="button px-4 py-1 mt-4">
												<i class="uil uil-arrow-left text-white fs-6"></i>
												{{$t('global.previous')}}
											</button>
										</div>
										<div class="ms-3">
											<button
												class="button-default bg-light- text-color- fw-500 shadow-sm px-4 py-1 mt-4"
												@click="$router.go(-1)"
											>
												{{ $t('global.cancel') }}
											</button>
										</div>
										<div class="col"></div>
										<div class="ms-3">
											<SpinnerMe :elementId="'manageSpinner'" />
											<button
												@click="manageApplication"
												class="button px-4 py-1 mt-4"
												id="manageBtn"
											>
												{{$t('global.validate')}}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>

						<LoadingMain :state="loading" />
					</div>

					<div class="col-xl-3"></div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
import Helper from '@/helpers/helper';
import InputFC from '@/components/InputFC.vue';
import InputFCV from '@/components/InputFCV.vue';
import LoadingMain from '@/components/LoadingMain.vue';
export default {
	name: 'CandidatesPage',
	components: { Template, Back, InputFC, InputFCV, LoadingMain, SpinnerMe },
	data() {
		return {
			step: 1,
			id: 0,
			application: {},
			loading: true,
			application_status: [],
			applicationStatus: '',
			errors: {},
		};
	},
	methods: {
		manageApplication() {
			Helper.loading('manageBtn', 'manageSpinner', true);

			this.errors.message = '';

			fetch(
				Helper.route('admin/application/manage'),
				Helper.requestOptionsJSON('PUT', {
					ids: [this.id],
					status: this.applicationStatus,
				})
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					Helper.loading('manageBtn', 'manageSpinner', false);

					if (data.problems) {
						Helper.notification('error', data.message);
					} else {
						this.getApplication();
						Helper.notification('success', data.message);
					}
				})
				.catch((error) => {
					console.log(error);
					Helper.loading('manageBtn', 'manageSpinner', false);
				});
		},
		getApplication() {
			this.loading = true;
			fetch(
				Helper.route('admin/application/' + this.id),
				Helper.requestOptions()
			)
				.then((response) => response.json())
				.then((data) => {
					this.loading = false;
					if (data.data) {
						console.log(data.data);
						this.application = data.data;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
	created() {
		this.id = this.$route.params.id;
		this.getApplication();
		this.application_status = Helper.application_status;
		this.applicationStatus = this.application.status;
	},
};
</script>
<style></style>
