<template>
	<div>
		<Template :title="$t('assistance.new_ticket')">
			<section class="p-5 py-4">
				<Back />

				<div
					class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 py-1 mt-4"
				>
					{{ $t('assistance.create_new_ticket') }}
				</div>

				<form @submit.prevent="createTicket" class="bg-light-hight- p-5">
					<div class="col-lg-8 mx-auto">
						<div class="row">
							<div class="text-black- col-2 text-4">
								{{ $t('assistance.subject') }}
							</div>
							<div class="col-10">
								<InputFC
									:options="objects"
									:type="'select'"
									:placeholder="''"
									:error="errors.object"
									v-model="object"
								/>
							</div>
						</div>
						<div class="row mt-4">
							<div class="text-black- col-2 text-4">
								{{ $t('assistance.object') }}
							</div>
							<div class="col-10">
								<InputFC
									:type="'text'"
									:placeholder="''"
									:error="errors.subject"
									v-model="subject"
								/>
							</div>
						</div>
						<div class="row mt-4">
							<div class="text-black- col-2 text-4">Message</div>
							<div class="col-10">
								<InputFC
									:type="'textarea'"
									:placeholder="''"
									:error="errors.content"
									v-model="content"
								/>
							</div>
						</div>
						<div class="row mt-5">
							<h6 class="text-center text-black">
								{{ $t('global.insert_piece') }}
							</h6>
							<div class="text-center">
								<input
									type="file"
									multiple
									class="d-none"
									size="50"
									@change="onFileChange"
									id="document_input"
									ref="document_input"
									name="document_input"
								/>
								<i
									class="uil uil-file-import fs-1"
									type="button"
									@click="$refs.document_input.click()"
								></i>
							</div>

							<div class="col-12 offset-2">
								<div v-for="file in files" :key="file.lastModified">
									<file-upload
										:file="file"
										:folder="null"
										@uploaded="uploadCompleted"
									/>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="text-black- col-2 text-4 mt-4"></div>
							<div class="col-10">
								<SpinnerMe :elementId="'spinner'" />
								<input
									type="submit"
									id="submit"
									class="button text-center mt-4"
									:value="$t('global.send')"
								/>
							</div>
						</div>
					</div>
				</form>
			</section>
		</Template>
	</div>
</template>

<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import Helper from '@/helpers/helper';
import InputFC from '@/components/InputFC.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
import FileUpload from '@/components/FileUpload.vue';
export default {
	name: 'CreateTicket',
	components: { Template, Back, InputFC, SpinnerMe, FileUpload },
	data() {
		return {
			object: '',
			subject: '',
			content: '',
			errors: [],
			files: [],
			objects: [],
			attachments: [],
		};
	},
	methods: {
		onFileChange(e) {
			this.files = [...this.files, ...e.target.files];
			console.log(this.files);
		},
		uploadCompleted(data) {
			this.attachments.push(data.id);
		},
		createTicket() {
			if (this.beforeCreateTicket()) {
				this.ok = true;
				this.error_message = '';
				Helper.loading('submit', 'spinner', true);

				let body = {
					object: this.object,
					content: this.content,
					subject: this.subject,
					files: this.attachments,
				};

				fetch(
					Helper.route('admin/ticket/add'),
					Helper.requestOptionsJSON('POST', body)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						return response.json();
					})
					.then((data) => {
						console.log(data);
						Helper.loading('submit', 'spinner', false);

						if (data.problems) {
							this.errors.object = Helper.getError(data.problems, 'object');
							this.errors.content = Helper.getError(data.problems, 'content');
							this.errors.subject = Helper.getError(data.problems, 'subject');
						} else {
							if (!this.ok) {
								this.error_message = data.message;
							} else {
								if (data.status) {
									this.$router.go(-1);
								}
							}
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit', 'spinner', false);
					});
			}
		},
		beforeCreateTicket() {
			if (Helper.required(this.object) === '') {
				delete this.errors.object;
			} else {
				this.errors.object = Helper.required(this.object);
			}
			if (Helper.required(this.subject) === '') {
				delete this.errors.subject;
			} else {
				this.errors.subject = Helper.required(this.subject);
			}
			if (Helper.required(this.content) === '') {
				delete this.errors.content;
			} else {
				this.errors.content = Helper.required(this.content);
			}
			return Helper.objectNumberKey(this.errors) == 0 ? true : false;
		},
	},
	watch: {
		object: function () {
			if (Helper.required(this.object) === '') {
				delete this.errors.object;
			} else {
				this.errors.object = Helper.required(this.object);
			}
		},
		subject: function () {
			if (Helper.required(this.subject) === '') {
				delete this.errors.subject;
			} else {
				this.errors.subject = Helper.required(this.subject);
			}
		},
		content: function () {
			if (Helper.required(this.content) === '') {
				delete this.errors.content;
			} else {
				this.errors.content = Helper.required(this.content);
			}
		},
	},
	created() {
		this.objects = Helper.objects;
	},
};
</script>