/* eslint-disable no-mixed-spaces-and-tabs */

import moment from 'moment';

let exports = {};

exports.convertDateUtc = (date, fullDate = true) => {
	if (!date) {
		return '-';
	}

	let at = 'à';
	if (localStorage.getItem('language') == 'en') {
		at = 'at';
	}
	if (fullDate) {
		return (
			moment.utc(date).format('DD/MM/YYYY ') +
			at +
			moment.utc(date).format(' H:mm')
		);
	}
	return moment.utc(date).format('DD/MM/YYYY ');
};

exports.convertDate = (date, fullDate = true) => {
	if (!date) {
		return '-';
	}

	let at = 'à';
	if (localStorage.getItem('language') == 'en') {
		at = 'at';
	}
	if (fullDate) {
		return (
			moment(date).local().format('DD/MM/YYYY ') +
			at +
			moment(date).local().format(' H:mm')
		);
	}
	return moment(date).local().format('DD/MM/YYYY ');
};

exports.convertDateLocal = (date, fullDate = true) => {
	if (!date) {
		return '-';
	}

	let at = 'à';
	if (localStorage.getItem('language') == 'en') {
		at = 'at';
	}
	if (fullDate) {
		return (
			moment(date).local().format('DD/MM/YYYY ') +
			at +
			moment(date).local().format(' H:mm')
		);
	}
	return moment(date).local().format('DD/MM/YYYY ');
};

exports.convertDateLong = (date) => {
	if (
		moment().local().format('DD/MM/YYYY') ==
		moment(date).local().format('DD/MM/YYYY')
	) {
		return moment(date).local().format('H:mm');
	}
	let at = 'à';
	if (localStorage.getItem('language') == 'en') {
		at = 'at';
	}
	return (
		moment(date).local().format('DD/MM/YYYY ') +
		at +
		moment(date).local().format(' H:mm')
	);
};

exports.convertDateToForm = (date, hour = false) => {
	if (!hour) {
		return moment(date).local().format('YYYY-MM-DD');
	} else {
		return moment(date).local().format('YYYY-MM-DD H:mm');
	}
};

exports.truncate = (text, length, clamp = '...') => {
	return text.length > length ? text.slice(0, length) + clamp : text;
};

exports.numberWithSpaces = (x) => {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

exports.formatAmount = (n, showCurrency = true) => {
	return n
		? showCurrency
			? Intl.NumberFormat('fr-FR', {
					style: 'currency',
					currency: 'XOF',
			  }).format(n)
			: Intl.NumberFormat('fr-FR').format(n)
		: 0;
};

exports.displayDateRange = (dateTimeFrom, dateTimeTo) => {
	const from = new Date(dateTimeFrom);
	const to = new Date(dateTimeTo);

	const local = localStorage.getItem('language') == 'en' ? 'en-UR' : 'fr-FR';

	const isSameDate = from.toDateString() === to.toDateString();
	const dateFormat = new Intl.DateTimeFormat(local, {
		day: 'numeric',
		month: 'long',
		year: 'numeric',
	});
	const timeFormat = new Intl.DateTimeFormat(local, {
		hour: 'numeric',
		minute: 'numeric',
	});

	if (isSameDate) {
		return local == 'fr-FR'
			? `Le ${dateFormat.format(from)} De ${timeFormat.format(
					from
			  )} à ${timeFormat.format(to)}`
			: `The ${dateFormat.format(from)} From ${timeFormat.format(
					from
			  )} to ${timeFormat.format(to)}`;
	} else {
		return local == 'fr-FR'
			? `Du ${dateFormat.format(from)} à ${timeFormat.format(
					from
			  )} Au ${dateFormat.format(to)} à ${timeFormat.format(to)}`
			: `From ${dateFormat.format(from)} at ${timeFormat.format(
					from
			  )} To ${dateFormat.format(to)} at ${timeFormat.format(to)}`;
	}
};

exports.mapFileIcon = (t) => {
	let icon = '';
	switch (t) {
		case 'audio':
			icon = require('@/assets/images/audio.png');
			break;
		case 'image':
			icon = require('@/assets/images/image.png');
			break;
		case 'video':
			icon = require('@/assets/images/video-1.png');
			break;
		case 'word':
			icon = require('@/assets/images/word.png');
			break;
		case 'document':
			icon = require('@/assets/images/doc.png');
			break;
		case 'text':
			icon = require('@/assets/images/txt.png');
			break;
		case 'excel':
			icon = require('@/assets/images/excel.png');
			break;
		case 'csv':
			icon = require('@/assets/images/csv.png');
			break;
		case 'json':
			icon = require('@/assets/images/json.png');
			break;
		case 'web':
			icon = require('@/assets/images/html.png');
			break;
		case 'pdf':
			icon = require('@/assets/images/pdf.png');
			break;
		case 'powerpoint':
			icon = require('@/assets/images/powerpoint.png');
			break;
		case 'zip':
			icon = require('@/assets/images/compress.png');
			break;
		case 'gzip':
			icon = require('@/assets/images/compress.png');
			break;
		case 'tar':
			icon = require('@/assets/images/compress.png');
			break;
		case '7-zip':
			icon = require('@/assets/images/compress.png');
			break;
		case 'rar':
			icon = require('@/assets/images/compress.png');
			break;
		default:
			icon = require('@/assets/images/file.png');
			break;
	}

	return icon;
};

exports.offerStatusFr = (status) => {
	let fr = '';

	switch (status) {
		case 'opened':
			fr = 'En cours';
			break;
		case 'closed':
			fr = 'Clôturé';
			break;
		case 'canceled':
			fr = 'Annulé';
			break;
		case 'suspended':
			fr = 'Suspendu';
			break;
		default:
			fr = 'Supprimé';
			break;
	}

	return fr;
};

exports.decodeUri = function (text) {
	return text
		.replace(/%3C/g, '<')
		.replace(/%3E/g, '>')
		.replace(/%22/g, '"')
		.replace(/%20/g, ' ')
		.replace(/%7B/g, '{')
		.replace(/%7D/g, '}')
		.replace(/%3A/g, ':')
		.replace(/%2C/g, ',')
		.replace(/%28/g, '(')
		.replace(/%29/g, ')')
		.replace(/%2F/g, '/')
		.replace(/%2F/g, '/');
};

exports.viewer = function (images, ctx) {
	ctx.$viewerApi({
		images: images,
	});
};

exports.capitalize = function (str) {
	return str[0].toUpperCase() + str.slice(1);
};

export default exports;
