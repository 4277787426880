<template>
	<div>
		<Template title="null" sidebarMenu="program-managements">
			<!-- Modal -->
			<div
				class="modal fade"
				id="showQrModal"
				data-mdb-keyboard="false"
				tabindex="-1"
				aria-labelledby="showQrModalLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content rounded-0">
						<QrcodeVue
							:render-as="'svg'"
							:margin="2"
							:level="'L'"
							:value="qrcodeValue"
							:size="500"
						></QrcodeVue>
					</div>
				</div>
			</div>

			<section class="row px-4 m-0 g-4 mt-1">
				<Back />

				<div class="col-xl-9">
					<div
						class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 p-1"
					>
						{{$t('global.preview')}}
					</div>
					<LoadingMain :state="loading" />
					<div
						v-if="sale == null && !loading"
						class="p-5 text-red- text-5 text-center"
					>
						{{$t('programs.buying.invoice_no_found')}}
					</div>
					<div class="row mt-1 mb-4 g-4" v-if="sale !== null">
						<div class="hstack">
							<button @click="downloadPayslip(true)" class="button btn-sm ms-auto bg-green- text-white- fw-500 shadow-sm mt-1 mb-2">
								{{ $t('resources.show_file') }}
							</button>
							<button @click="downloadPayslip()" class="button btn-sm ms-2 bg-success- text-white- fw-500 shadow-sm mt-1 mb-2">
								{{ $t('resources.download_file') }}
							</button>
						</div>

						<div class="col-xl-12">
							<div class="border" id="element-to-print">
								<div
									class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
								>
									{{ sale.company.name }}
								</div>
								<div class="col-12 pb-2 mx-auto mt-2 px-4">
									<div class="row">
										<div class=" col-6 text-4">
											{{$t('programs.company.label.rccm')}}: 
											{{ sale.company.rccm }}
										</div>
										<div class=" col-6 text-4">
											{{$t('programs.company.label.ifu')}}: 
											{{ sale.company.ifu }}
										</div>
										<div class=" col-6 text-4">
											{{$t('programs.company.label.email')}}:
											{{ sale.company.email }}
										</div>
										<div class=" col-6 text-4">
											{{$t('programs.company.label.contact')}}:
											{{ sale.company.phoneNumber }}
										</div>
									</div>
								</div>
								<hr class="my-3">
								<!-- <div
									class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
								>
									{{$t('programs.buying.client_detail')}}
								</div> -->
								<div class="row mt-2 px-4">
									
									<div class="col-lg-6">
										<h6 class="text-black-">{{$t('programs.saling.invoice_to')}} :</h6>
										<div class="text-4">
											<div class="" style="white-space: pre-line;" v-html="sale.customer"></div>
										</div>
									</div>
									<div class="col-lg-6 text-end" v-if="sale.recipient">
										<h6 class="text-black-">{{$t('programs.saling.delivred_to')}} :</h6>
										<div class="text-4">
											<div class="" style="white-space: pre-line;" v-html="sale.recipient"></div>
										</div>
									</div>
								</div>
								<div
									class="text-red- mt-2 text-uppercase text-5 bg-light-hight- text-center p-1"
								>
									{{$t('programs.saling.retail_sales')}}
								</div>
								<div class="px-3 mb-4">
									<table
										class="table table mt-4"
										style="width: 100% !important"
									>
										<thead class="bg-primary- table-bordered border">
											<tr>
												<th class="text-white py-2">#</th>
												<th class="text-white py-2">{{$t('programs.saling.item')}}</th>
												<th class="text-white py-2">Qte</th>
												<th class="text-white py-2">PU</th>
												<th class="text-white py-2">HT</th>
												<th class="text-white py-2">TVA</th>
												<th class="text-white py-2">TTC</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="(article, index) in sale.articles"
												:key="article.id"
											>
												<td>{{ index + 1 }}</td>
												<td>{{ article.name }}</td>
												<td>
													{{ $filters.formatAmount(article.quantity, false) }}
												</td>
												<td>
													{{ $filters.formatAmount(article.unitPrice, false) }}
												</td>
												<td>
													{{ $filters.formatAmount(article.priceHT, false) }}
												</td>
												<td>{{ $filters.formatAmount(article.tva, false) }}</td>
												<td>
													{{ $filters.formatAmount(article.priceTTC, false) }}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div
									class="text-red- mt-3 mb-4 text-uppercase text-5 bg-light-hight- text-center p-1"
								>
									{{$t('programs.saling.ventillation')}}
								</div>
								<div class="row px-3 mb-4">
									<div class="col-6">
										<table class="table" style="width: 100% !important">
											<tr>
												<td class="bg-dark px-3 border text-white- text-center">
													{{$t('programs.amount_excluding_tax')}}
												</td>
												<td
													class="border text-black- bg-white text-center px-4"
												>
													{{ $filters.formatAmount(sale.amountHT) }}
												</td>
											</tr>
											<tr>
												<td class="bg-dark px-3 border text-white- text-center">
													TVA
												</td>
												<td
													class="border text-black- bg-white text-center px-4"
												>
													{{ $filters.formatAmount(sale.tva) }}
												</td>
											</tr>
										</table>
									</div>
									<div class="col-6">
										<table class="table" style="width: 100% !important">
											<tr>
												<td class="bg-dark px-3 border text-white- text-center">
													{{$t('programs.amount_including_tax')}}
												</td>
												<td
													class="border text-black- bg-white text-center px-4"
												>
													{{ $filters.formatAmount(sale.amountTTC) }}
												</td>
											</tr>
											<tr>
												<td class="bg-alt- px-3 border text-white- text-center">
													{{$t('programs.buying.payment_method')}}
												</td>
												<td
													class="border text-black- bg-white text-center px-4"
												>
													{{ sale.paymentMean }}
												</td>
											</tr>
										</table>
									</div>
								</div>
								<div
									class="text-red- mt-3 mb-0 text-uppercase text-5 bg-light-hight- text-center p-1"
								>
									{{$t('programs.saling.certification')}}
								</div>
								<div class="hstack px-3 py-2">
									<div class="col-6">
										<p class="mt-3">
											Facture :
											<b class="text-black- text-5">{{ sale.number }}</b>
										</p>
										<p class="mt-2">
											Date :
											<b class="text-black- text-5">{{
												$filters.convertDate(sale.date)
											}}</b>
										</p>
									</div>
									<div
										role="button"
										class="ms-auto"
										data-mdb-toggle="modal"
										data-mdb-target="#showQrModal"
									>
										<QrcodeVue
											:render-as="'svg'"
											:margin="2"
											:level="'L'"
											:value="qrcodeValue"
										></QrcodeVue>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xl-3">
					<div class="rounded bg-second- text-white text-4 text-center p-1">
						{{$t('global.advice')}}
					</div>
					<div class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2" v-html="$t('programs.saling.show_advice_text')">
					
					</div>
					
					
					<router-link to="/documents/create"
						><button class="button bg-alt- btn-sm w-100 mt-1 p-1">
							{{ $t('global.import_now') }}
						</button>
					</router-link>
				</div>
				
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import Helper from '@/helpers/helper';
import LoadingMain from '@/components/LoadingMain.vue';
import QrcodeVue from 'qrcode.vue';
import html2pdf from "html2pdf.js";


export default {
	name: 'ShowSectionPage',
	components: { Template, Back, LoadingMain, QrcodeVue },
	data() {
		return {
			id: '',
			loading: false,
			ok: true,
			sale: null,
			qrcodeValue: '',
		};
	},
	methods: {
		formatInvoiceInfo(data) {
			const formattedDate = (timestamp) => new Date(timestamp).toLocaleString();

			const formatArticle = (article) => `
        Name: ${article.name}
        Quantity: ${article.quantity}
        Unit Price: ${article.unitPrice}
        Price (HT): ${article.priceHT}
        Price (TTC): ${article.priceTTC}
        TVA: ${article.tva}
      `;

			const articlesInfo = data.articles.map(formatArticle).join('\n');

			return `
        Invoice Information:
        -------------------

        Invoice Number: ${data.number}
        Payment Method: ${data.paymentMean}
        Creation Date: ${formattedDate(data.createdAt)}

        Customer Information:
        ---------------------
        ${data.customer}

        Recipient Information:
        ----------------------
        ${data.customer.recipient}

        Articles Information:
        --------------------
        ${articlesInfo}

        Total
        ----------------------

        Total Amount (HT): ${data.amountHT}
        Total Amount (TTC): ${data.amountTTC}
        `;
		},
		formatInvoiceInfoFr(data) {
			const formattedDate = (timestamp) => new Date(timestamp).toLocaleString();

			const formatArticle = (article) => `
        Nom: ${article.name}
        Quantité: ${article.quantity}
        Prix unitaire: ${article.unitPrice}
        Prix (HT): ${article.priceHT}
        Prix (TTC): ${article.priceTTC}
        TVA: ${article.tva}
    `;

			const articlesInfo = data.articles.map(formatArticle).join('\n');

			return `
        Informations de la Facture :
        ---------------------------

        Numéro de Facture : ${data.number}
        Moyen de Paiement : ${data.paymentMean}
        Date de Création : ${formattedDate(data.createdAt)}

        Informations Client :
        ---------------------
        ${data.customer}

        Informations du Destinataire :
        ------------------------------
        ${data.recipient}

        Informations des Articles :
        ---------------------------
        ${articlesInfo}

        Total
        ----------------------

        Montant Total (HT) : ${data.amountHT}
        Montant Total (TTC) : ${data.amountTTC}
    `;
		},
		getSale() {
			this.loading = true;
			fetch(Helper.route('admin/sale/' + this.id), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					console.log(data);
					if (data.sale) {
						this.sale = data.sale;

						const local = localStorage.getItem('language');

						this.qrcodeValue =
							local === 'fr'
								? this.formatInvoiceInfoFr(this.sale)
								: this.formatInvoiceInfo(this.sale);
					}
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},

		
		downloadPayslip(show = false) {
			this.loading = true
			let route = 'admin/sale/receipt?id='+this.sale.id;

			fetch(
					Helper.route(route),
					Helper.requestOptionsJSON()
				)
					.then((response) => {
						Helper.tokenChecker(response.status);						
						return response.blob();
					})
					.then(blob => {
						this.loading = false

						if (show) {
							const url = URL.createObjectURL(blob);

							window.open(url, '_blank');
						} else {
							const url = URL.createObjectURL(blob);

							const a = document.createElement('a');
							a.href = url;
							a.download = 'Facture '+this.id+'.pdf'; 
							document.body.appendChild(a);
							a.click();

							URL.revokeObjectURL(url);
							a.remove();	
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit', 'spinner', false);
					});
		},
		exportToPDF() {
		
		html2pdf(document.getElementById("element-to-print"), {
				margin: 1,
				filename: "i-was-html.pdf",
			});
		},
	},

	mounted(){
		
		// console.log(user) 
		this.exportToPDF();
		// element-to-print
	},

	created() {
		this.id = this.$route.params.id;
		this.getSale();
	},
};
</script>
