<template>
	<div>
		<Template :title="$t('global.notification')">
			<div class="row px-4 m-0">
				<LoadingMain :state="loading" />
			</div>
			<div
				class="row px-5 m-0 my-5 mt-4"
				v-if="!loading && notifications.length != 0"
			>
				<div class="row py-2 bg-primary- text-5 text-white">
					<div class="col-2 fw-800 text-white-">{{ $t('global.title') }}</div>
					<div class="col-8 fw-800 text-white-">{{ $t('global.content') }}</div>
					<div class="col-2 fw-800 text-white- text-center">
						{{ $t('global.date') }}
					</div>
				</div>

				<div class="row g-0" v-if="!loading && notifications.length != 0">
					<div
						class="row border py-2 text-5 text-white"
						v-for="(notification, index) in notifications"
						:key="notification.id"
						v-show="show(index)"
					>
						<div class="col-2 text-black- fw-700">{{ notification.title }}</div>
						<div class="col-8 text-justify px-2 lh-base">
							{{ notification.content }}
						</div>
						<div class="col-2 text-alt- text-center">
							{{
								$filters.convertDate(new Date(notification.createdAt).toJSON())
							}}
						</div>
					</div>

					<nav
						aria-label="Page navigation"
						class="mt-3"
						v-if="notifications.length > perPage"
					>
						<ul class="pagination justify-content-center">
							<li class="page-item" @click="previous">
								<a class="page-link" href="#" aria-label="Previous">
									<span aria-hidden="true">&laquo;</span>
								</a>
							</li>

							<li class="page-item" @click="previous">
								<a class="page-link" href="#">{{$t('global.previous')}}</a>
							</li>
							<small class="mx-3 mt-2"
								>{{
									Math.ceil(notifications.length / perPage) > 0 ? page : 0
								}}
								/ {{ Math.ceil(notifications.length / perPage) }}</small
							>
							<li class="page-item" @click="next">
								<a class="page-link" href="#">{{$t('global.next')}}</a>
							</li>

							<li class="page-item" @click="next">
								<a class="page-link" href="#" aria-label="Next">
									<span aria-hidden="true">&raquo;</span>
								</a>
							</li>
						</ul>
					</nav>

					<!-- <div class="row">
						<div class="col-4">
							<i
								class="uil uil-arrow-circle-left fs-5"
								type="button"
								@click="previous"
							></i>
							<span>Précédent</span>
						</div>
						<small class="col-4 ms-3"
							>{{ Math.ceil(notifications.length / 25) > 0 ? page : 0 }} /
							{{ Math.ceil(notifications.length / 25) }}</small
						>
						<div class="col-4">
							<i
								class="uil uil-arrow-circle-right fs-5 ms-3"
								type="button"
								@click="next"
							></i
							><span>Suivant</span>
						</div>
					</div> -->
				</div>
				<div v-if="!loading && notifications.length == 0">
					<p colspan="5" class="text-center">{{ $t('global.none_element') }}</p>
				</div>
			</div>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Helper from '@/helpers/helper';
import LoadingMain from '@/components/LoadingMain.vue';
import moment from 'moment';

export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: 'Notification',
	components: { Template, LoadingMain },
	data() {
		return {
			loading: true,
			notifications: [],
			page: 1,
			perPage: 10,
		};
	},
	created() {
		this.loadList();
		this.markAsRead();
	},
	methods: {
		show(index) {
			return (
				index < this.page * this.perPage &&
				index + 1 > (this.page - 1) * this.perPage
			);
		},
		next() {
			if (this.page * this.perPage < this.notifications.length) {
				this.page++;
			}
		},
		previous() {
			if (this.page >= 2) {
				this.page--;
			}
		},
		loadList() {
			this.loading = true;
			fetch(Helper.route('admin/notification/all'), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					console.log(data);
					this.notifications = data.data;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
		markAsRead() {
			this.loading = true;
			fetch(
				Helper.route('admin/notification/mark-as-read'),
				Helper.requestOptions('POST')
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then(() => {
					this.loading = false;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
		convertDate: function (date) {
			if (
				moment().format('DD/MM/YYYY') == moment.utc(date).format('DD/MM/YYYY')
			) {
				return moment.utc(date).format('H:mm');
			}
			return moment.utc(date).format('DD/MM/YYYY à H:mm');
		},
	},
};
</script>
<style></style>
