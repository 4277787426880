<template>
	<div>
		<Template :title="$t('mails.save_mail')" sidebarMenu="mails">
			
			<section class="p-5 py-4">
				<Back />

				<div class="row">
					<div class="col-xl-9">
						<div
							class="bg-second- fw-1000 shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 py-1 mt-4"
						>
							{{ $t('mails.save_mail') }}
						</div>

						<div class="bg-light-hight- rounded py-5 mt-3">
							<form
								@submit.prevent="addMail"
								class="col-lg-10 col-md-11 mx-auto"
							>
								<hr />
								<InputFC
									:type="'text'"
									class="mb-4"
									v-model="reference"
									:error="errors.steps"
									:placeholder="$t('global.reference')"
								/>

								<InputFC
									:type="'text'"
									class="mb-4"
									v-model="subject"
									:error="errors.subject"
									:placeholder="$t('global.object')"
								/>

								<div class="row m-0 border border-default- pt-2 mb-4">
									<div class="text-center mb-2">{{ $t('mails.issuer') }}</div>
									<hr />

									<InputFC
										:type="'text'"
										class="col-6 mb-4"
										v-model="entity"
										:error="errors.entity"
										:placeholder="$t('mails.institution')"
									/>

									<InputFC
										:type="'text'"
										class="col-6 mb-4"
										v-model="name"
										:error="errors.name"
										:placeholder="$t('mails.fullname')"
									/>

									<InputFC
										:type="'email'"
										class="col-6 mb-4"
										v-model="email"
										:error="errors.email"
										:placeholder="$t('global.email')"
									/>

									<InputFC
										:type="'tel'"
										class="col-6 mb-4"
										v-model="phone"
										:error="errors.phone"
										:placeholder="$t('global.phone')"
									/>
								</div>

								<InputFC
									:type="'textarea'"
									class="mb-4"
									v-model="description"
									:error="errors.description"
									:placeholder="$t('mails.description')"
								/>

								<div class="row mt-5">
									<h6 class="text-center text-black">
										{{ $t('global.insert_piece') }}
									</h6>
									<div class="text-center">
										<input
											type="file"
											multiple
											class="d-none"
											size="50"
											@change="onFileChange"
											id="document_input"
											ref="document_input"
											name="document_input"
										/>
										<i
											class="uil uil-file-import fs-1"
											type="button"
											@click="$refs.document_input.click()"
										></i>
									</div>
		
									<div class="col-12 offset-2">
										<div v-for="file in files" :key="file.lastModified">
											<file-upload
												:file="file"
												:folder="null"
												@uploaded="uploadCompleted"
											/>
										</div>
									</div>
								</div>

								<InputFC
									:type="'select'"
									class="mb-4"
									:options="priority_list"
									v-model="priority"
									:error="errors.priority"
									:placeholder="$t('mails.priority')"
								/>

								<InputFC
									:type="'select'"
									class="mb-4"
									:options="mail_processing_status"
									v-model="status"
									:error="errors.status"
									:placeholder="$t('global.status')"
								/>

								<div
									v-if="status === 'saved'"
									class="row border border-default- pt-2 mb-4"
								>
									<div class="hstack text-center mb-2">
										{{ $t('mails.processing_steps') }}
										<div role="button" class="ms-auto" title="Ajouter">
											<button
												class="btn button-default"
												@click.prevent="addStep"
											>
												<span class="uil uil-plus text-6"></span>
											</button>
										</div>
									</div>
									<span
										v-show="errors.steps != ''"
										v-text="errors.steps"
										class="error"
									></span>
									<hr />

									<div v-for="(step, i) of processing_steps" :key="i">
										<div class="hstack text-red- text-italic- text-4">
											{{ $t('mails.step') }} n° {{ i + 1 }}

											<div role="button" class="ms-auto" @click="removeStep(i)">
												<span class="uil uil-trash text-red-"></span>
											</div>
										</div>
										<div class="mt-1">
											<label for="">{{ $t('mails.assigned_to') }}</label>
											<v-select
												class="bg-white mt-1"
												v-model="step.admin"
												:options="coadmins"
												:reduce="(coadmin) => coadmin.id"
												label="name"
												required
											></v-select>
										</div>

										<InputFC
											:type="'textarea'"
											class="mb-4 mt-1"
											v-model="step.instruction"
											:placeholder="$t('mails.instructions')"
											required
										/>

										<div v-if="i !== processing_steps.length - 1"><hr /></div>
									</div>
								</div>

								<div class="hstack">
									<div class="">
										<SpinnerMe :elementId="'spinner'" />
										<button id="submit" class="button px-4 py-1 mt-4">
											{{ $t('global.send') }}
										</button>
									</div>
									<div class="ms-3">
										<button
											class="button-default bg-light- text-color- fw-500 shadow-sm px-4 py-1 mt-4"
											@click="$router.go(-1)"
										>
											{{ $t('global.cancel') }}
										</button>
									</div>
								</div>
							</form>
						</div>

						<div class="col-xl-3"></div>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
import Helper from '@/helpers/helper';
import InputFC from '@/components/InputFC.vue';
import { useUserStore } from '@/store/user';
import FileUpload from '@/components/FileUpload.vue';

export default {
	name: 'CreateDocument',
	components: { Template, Back, InputFC, SpinnerMe, FileUpload },
	setup() {
		const store = useUserStore();
		return {
			store,
		};
	},
	data() {
		return {
			user: this.store.user,
			message: '',
			reference: '',
			entity: '',
			email: '',
			name: '',
			phone: '',
			subject: '',
			description: '',
			priority: '',
			status: '',
			article: {},
			articles: [],
			amountHT: 0,
			tva: 0,
			amountTTC: 0,
			paymentMean: '',
			has_tva: false,
			date: '',
			priority_list: [],
			mail_processing_status: [],
			coadmins: [],
			errors: [],
			files: [],
			step: {
				admin: 1,
				instruction: '',
			},
			processing_steps: [
				{
					admin: null,
					instruction: '',
				},
			],
		};
	},
	methods: {
		onFileChange(e) {
			this.files = [...this.files, ...e.target.files];
			console.log(this.files);
		},
		uploadCompleted(data) {
			this.attachments.push(data.id);
		},
		addMail() {
			this.ok = true;
			this.error_message = '';
			Helper.loading('submit', 'spinner', true);

			let body = {
				reference: this.reference,
				subject: this.subject,
				description: this.description,
				sender: JSON.stringify({
					entity: this.entity,
					name: this.name,
					email: this.email,
					phone: this.phone,
				}),
				priority: this.priority,
				status: this.status,
				steps: JSON.stringify(this.status === 'saved' ? this.processing_steps : []),
			};

			fetch(
				Helper.route('admin/mail/add'),
				Helper.requestOptionsJSON('POST', body)
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					console.log(data);
					Helper.loading('submit', 'spinner', false);

					if (data.problems) {
						this.errors.reference = Helper.getError(data.problems, 'reference');
						this.errors.subject = Helper.getError(data.problems, 'subject');
						this.errors.description = Helper.getError(
							data.problems,
							'description'
						);
						this.errors.sender = Helper.getError(data.problems, 'sender');
						this.errors.priority = Helper.getError(data.problems, 'priority');
						this.errors.status = Helper.getError(data.problems, 'status');
						this.errors.steps = Helper.getError(data.problems, 'steps');

						Helper.notification('error', data.message);
					} else {
						if (!this.ok) {
							this.error_message = data.message;
							Helper.notification('error', data.message);
						} else {
							if (data.status) {
								Helper.notification('success', data.message);
								this.$router.go(-1);
							} else {
								Helper.notification('error', data.message);
							}
						}
					}
				})
				.catch((error) => {
					console.log(error);
					Helper.loading('submit', 'spinner', false);
				});
		},
		addStep() {
			this.processing_steps.push({
				admin: null,
				instruction: '',
			});
		},
		removeStep(i) {
			this.processing_steps.splice(i, 1);
		},
		getCoadmins() {
			this.coadmins.push({
				id: this.store.user.id,
				name: 'Moi même',
			});

			fetch(
				Helper.route('admin/management/coadmin/all'),
				Helper.requestOptions()
			)
				.then((response) => response.json())
				.then((data) => {
					console.log(data);
					if (data.data) {
						data.data.forEach((coadmin) => {
							console.log(
								'🚀 ~ file: Create.vue:276 ~ data.data.forEach ~ coadmin:',
								coadmin
							);
							this.coadmins.push({
								id: coadmin.id,
								name: coadmin.lastname + ' ' + coadmin.firstname,
							});
						});
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
	
	created() {
		this.getCoadmins();
		this.payment_methods = Helper.payment_methods;
		this.priority_list = Helper.priority_list;
		this.mail_processing_status = Helper.mail_processing_status();
	},
	mounted(){
		console.log(this.mail_processing_status)
		console.log(this.priority_list)
	}
};
</script>
<style></style>
