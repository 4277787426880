<template>
	<div>
		<Template title="null" sidebarMenu="tools">
			<section class="row px-4 m-0 g-4 mt-1 mb-2">
				<Back />

				<div
					class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 p-1"
				>
					{{$t('global.preview')}}
				</div>

				<div class="col-lg-6">
					<LoadingMain :state="loading" />
					<div
						v-if="task == null && !loading"
						class="p-5 text-red- text-5 text-center"
					>
						{{$t('training.training_no_found')}}
					</div>
					<div class="row g-4" v-if="task !== null">
						<div class="col-12">
							<div class="border rounded mb-2">
								<div
									class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
								>
									{{"Tâche"}}
								</div>

								<div class="text-center m-2">
									<h3 class="text-primary- fs-2">{{ task.name }}</h3>
									
									<p class="text-5 text-second- mt-2 mb-1">
										{{$t('training.participants')}} : {{ employees(task.employees) }}
									</p>
								</div>

								<div
									class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
								>
									{{$t('training.periods')}}
								</div>

								<div class="text-center m-2">
									<p class="text-5 text-second- mt-2 mb-1">
										{{
											$filters.displayDateRange(
												task.startDate,
												task.endDate
											)
										}}
									</p>
								</div>

								<div
									class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
								>
									{{$t('training.characters')}}
								</div>

								<div class="text-center m-2">
									<p class="text-5 text-second- mt-2 mb-1">
										<span class="text-red-">{{
											task.nature == 1 ? $t('training.online') : $t('training.in_person')
										}}</span>
										: {{ task.linkOrPlace }}
									</p>
								</div>

								<div
									class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
								>
									{{$t('global.description')}}
								</div>

								<div class="text-center m-2">
									<p class="text-5 text-second- mt-2 mb-1">
										{{ task.description }}
									</p>
								</div>

								<div
									class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
								>
									{{$t('training.relevance')}}
								</div>

								<div class="text-center m-2">
									<p class="text-5 text-second- mt-2 mb-1">N/A</p>
								</div>
							</div>

							<div class="mb-4">
								<router-link
									:to="{
										name: 'task-edit',
										params: { id: task.id, slug: task.slug },
									}"
								>
									<span class="button bg-alt- p-1 text-6 px-3" role="button"
										>{{"Modifier cette tâche"}}</span
									>
								</router-link> 
								
								<span
									class="button bg-red- ms-2 p-1 text-6 px-3"
									role="button"
									@click="deletes()"
									>{{"Supprimer cette tâche"}}
								</span>
							</div>

							<div class="mb-4"></div>
						</div>
					</div>
				</div>
				<div class="col-xl-6" v-if="task !== null">
					<!-- <div class="col-12">
						<div
							class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
						>
							{{$t('training.sections_concerned')}}
						</div>
						<div
							class="table-responsive mt-0"
							v-if="task.sections && task.sections.length != 0"
						>
							<table
								class="table sections align-middle table-hover table-striped mb-0"
								style="width: 100% !important"
							>
								<thead class="bg-primary-">
									<tr class="text-6 text-white">
										<th>#</th>
										<th>Section</th>
										<th>Code</th>
									</tr>
								</thead>
								<tbody class="bg-alt-">
									<tr
										v-for="(section, index) in task.sections"
										:key="section.id"
										class="text-6 text-black"
									>
										<td>{{ index + 1 }}</td>
										<td>
											<router-link
												:to="{
													name: 'sections-show',
													params: { id: section.id, slug: section.slug },
												}"
											>
												{{ section.name }}
											</router-link>
										</td>
										<td>{{ section.code }}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div
							class="py-4 text-center text-black-"
							v-if="task.sections && task.sections.length == 0"
						>
							{{$t('training.no_sections_found')}}
						</div>
					</div> -->

					<div class="col-12">
						<div class="border">
							<div
								class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
							>
								{{$t('training.employees_concerned')}}
							</div>
							<div
								class="table-responsive mt-0"
								v-if="task.employees && task.employees.length > 0"
							>
								<table
									class="table employees align-middle table-hover table-striped mb-0"
									style="width: 100% !important"
								>
									<thead class="bg-primary-">
										<tr class="text-6 text-white">
											<th>#</th>
											<th>{{$t('training.fullname')}}</th>
											<th>{{$t('resources.registration_number')}}</th>
											<th>{{$t('resources.garde')}}</th>
											<th>{{$t('global.state')}}</th>
										</tr>
									</thead>
									<tbody class="bg-alt-">
										<tr
											v-for="(employee, i) in task.employees"
											:key="employee.id"
											class="text-6 text-black"
										>
											<td>{{ i + 1 }}</td>
											<td>
												<router-link
													:to="{
														name: 'employee',
														params: { id: employee.id, slug: employee.slug },
													}"
												>
													{{ employee.lastname + ' ' + employee.firstname }}
												</router-link>
											</td>
											<td>{{ employee.matricule }}</td>
											<td>{{ employee.grade }}</td>
											<td>{{ employee.state }}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div v-else class="p-3 text-black- text-5 text-center">
								{{$t('resources.no_employee_found')}}
							</div>
						</div>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import Helper from '@/helpers/helper';
import LoadingMain from '@/components/LoadingMain.vue';
export default {
	name: 'ShowTaskPage',
	components: { Template, Back, LoadingMain },
	data() {
		return {
			showInfo: false,
			id: null,
			loading: true,
			task: null,
		};
	},
	methods: {
		sections(list) {
			return Helper.sections(list);
		},
		employees(list) {
			return Helper.employees(list);
		},
		getTask() {
			this.loading = true;
			fetch(Helper.route('admin/task/' + this.id), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					if (data.data) {
						this.task = data.data;
						console.log(this.task);
					}
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
		deletes() {
			this.loading = true;
			// this.$isLoading(true);
			let body = {
				ids: [this.id],
			};
			fetch(
				Helper.route('admin/task/delete'),
				Helper.requestOptionsJSON('DELETE', body)
			)
				.then((response) => response.json())
				.then((data) => {
					this.loading = false;
					console.log(data);
					Helper.notification("success", data.message);
					this.$router.go(-1);
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
	},
	created() {
		this.id = this.$route.params.id;
		// console.log(this.id);
		this.getTask();
	},
};
</script>
